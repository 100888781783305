import React, { useState } from "react";
import { useEffect } from "react";
import Layout from "../../components/Layout";
import NewSubscriptionForm from "../../components/subscriptions/NewSubscriptionForm";
import NewSubscriptionSpeiOxxoForm from "../../components/subscriptions/NewSubscriptionSpeiOxxoForm";
import NewSubscriptionPayPalForm from "../../components/subscriptions/NewSubscriptionPayPalForm";

import { fetchUserProfile } from "../../api/Auth";
import { isUserVerified } from "./../../api/User";
import "./NewSubscriptionPage.css";
import cardIcons from "../../images/cardicons.svg";
import oxxopayicon from "../../images/oxxopay.png";
import speiIcon from "../../images/spei.png";
import paypalIcon from "../../images/paypal.png";
import { useTranslation } from "react-i18next";

const NewSubscriptionPage = ({ location }) => {
  const { t } = useTranslation();

  // Variable for getting licenses
  const [userEmail, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [verified, setVerified] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pm, setPm] = useState(1);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchUserProfile()
      .then((usr_data) => {
        setEmail(usr_data.email);
        setUserName(usr_data.name);

        isUserVerified(usr_data.email).then((res) => {
          if (res.emailVerified) {
            setVerified(true);
          } else {
            setVerified(false);
          }
        });
        //emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (!verified) {
    return (
      <Layout title="Confirma tu correo">
        <div className="py-8 wrapper">
          <h2>{t("subsPage.noSubs")}</h2>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout title="Nueva suscripción">
        <div className="wmobil-install-background"></div>
        <div className="introduction-main-container">
          <div className="introduction-card">
            <h2 className="text-2xl text-gray-800 font-bold">
              {t("subsPage.subtitle")}
            </h2>
            <div className="payment-methods-container-new-subscription">
              <button
                className={pm == 1 ? "active" : null}
                onClick={() => setPm(1)}
              >
                <img src={cardIcons} />
                {t("dashboard.dropdownOps.card")}
              </button>
              <button
                className={pm == 2 ? "active" : null}
                onClick={() => setPm(2)}
              >
                <img src={speiIcon} />
                SPEI
              </button>
              <button
                className={pm == 3 ? "active" : null}
                onClick={() => setPm(3)}
              >
                <img src={oxxopayicon} />
                OXXO
              </button>
              <button
                className={pm == 4 ? "active" : null}
                onClick={() => setPm(4)}
              >
                <img src={paypalIcon} />
                PayPal
              </button>
            </div>
            {pm == 1 && (
              <NewSubscriptionForm
                id={location.search?.split("id=")[1]}
                pm={pm}
              ></NewSubscriptionForm>
            )}

            {pm !== 1 && pm !== 4 && (
              <NewSubscriptionSpeiOxxoForm
                id={location.search?.split("id=")[1]}
                pm={pm}
              ></NewSubscriptionSpeiOxxoForm>
            )}

            {pm == 4 && (
              <NewSubscriptionPayPalForm
                id={location.search?.split("id=")[1]}
                pm={pm}
              ></NewSubscriptionPayPalForm>
            )}
          </div>
        </div>
      </Layout>
    );
  }
};

export default NewSubscriptionPage;

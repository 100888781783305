import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { HOME_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="mt-12 px-4 py-4" title="Página no encontrada">
      <p className="md:text-6xl text-4xl text-center font-extrabold text-gray-800">
        404
      </p>
      <p className="md:text-xl text-lg text-center text-gray-700">
        {t("notFound.text1")}
      </p>
      <p className="md:text-base text-sm text-center text-gray-700 mt-4 font-light">
        {t("notFound.text2")}
      </p>
      <Link
        to={HOME_PATH}
        className="main-button w-full md:text-xl text-sm text-center text-blue-500 mt-1 font-light block"
      >
        {t("notFound.text3")}
      </Link>
    </Layout>
  );
};

export default NotFoundPage;

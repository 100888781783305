import React, { createContext, useContext, useEffect, useState } from "react";

const ProfilePicContext = createContext();

export const ProfilePicProvider = ({ children }) => {
  const [pictureKey, setPictureKey] = useState(() => {
    const storedPicKey = localStorage.getItem("pictureKey");
    return storedPicKey !== null ? storedPicKey : "";
  });

  const updatePictureKey = (newKey) => {
    setPictureKey(newKey);
  };

  useEffect(() => {
    localStorage.setItem("pictureKey", pictureKey.toString());
  }, [pictureKey]);

  return (
    <ProfilePicContext.Provider value={{ pictureKey, updatePictureKey }}>
      {children}
    </ProfilePicContext.Provider>
  );
};

export const useProfilePic = () => {
  return useContext(ProfilePicContext);
};

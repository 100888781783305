import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const WInput = forwardRef(
  (
    {
      mandatory,
      label,
      name,
      maxDate,
      minDate,
      defaultValue,
      type,
      disabled,
      className,
      onChange,
      readOnly,
      value,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const parsedDefaultValue =
      type === "currency" ? parseFloat(defaultValue).toFixed(2) : defaultValue;
    const parsedValue =
      type === "currency" ? parseFloat(value).toFixed(2) : value;

    const greenFill = type === "currency-landing" || type === "landing-input";

    const inputStyle =
      type === "search"
        ? "wfacturas-search-input"
        : type === "text-big"
          ? "wfacturas-input-big"
          : "wfacturas-input removed-afcolor";

    const currencyStyle =
      type === "currency"
        ? "wfacturas-currency-input"
        : type === "currency-landing" || type === "landing-input"
          ? "landing-input-green"
          : inputStyle;

    const handleKeyPress = (event) => {
      if (type === "digits") {
        const keyValue = event.key;
        const isNumeric = /^\d+$/.test(keyValue);
        if (!isNumeric) {
          event.preventDefault();
        }
      }
    };

    const inputProps = {
      onKeyPress: handleKeyPress,
      className: className ? className : currencyStyle,
      type: type === "currency" || type === "search" ? "text" : type,
      name,
      ...(disabled && { disabled }),
      ...(ref && { ref }),
      ...(maxDate && { max: maxDate }),
      ...(minDate && { min: minDate }),
      ...(onChange && { onChange }),
      ...(readOnly && { readOnly }),
      ...(value && { value: parsedValue }),
      defaultValue: parsedDefaultValue,
    };

    return (
      <div className="relative ">
        {type === "text-big" ? (
          <textarea {...inputProps} />
        ) : (
          <input {...inputProps} />
        )}
        {(type === "currency" || type === "currency-landing") && (
          <div className="wfacturas-currency-icon">$</div>
        )}
        {type === "search" && (
          <div className="wfacturas-search-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="m17.545 15.467l-3.779-3.779a6.15 6.15 0 0 0 .898-3.21c0-3.417-2.961-6.377-6.378-6.377A6.185 6.185 0 0 0 2.1 8.287c0 3.416 2.961 6.377 6.377 6.377a6.15 6.15 0 0 0 3.115-.844l3.799 3.801a.953.953 0 0 0 1.346 0l.943-.943c.371-.371.236-.84-.135-1.211M4.004 8.287a4.282 4.282 0 0 1 4.282-4.283c2.366 0 4.474 2.107 4.474 4.474a4.284 4.284 0 0 1-4.283 4.283c-2.366-.001-4.473-2.109-4.473-4.474"
              />
            </svg>
          </div>
        )}
        <label
          className={`${greenFill ? "wfacturas-input-label-green" : "wfacturas-input-label"}`}
        >
          {label}
          {mandatory && <span className="mandatory">*</span>}
        </label>
      </div>
    );
  }
);

export default WInput;

import React from "react";

import { ResponsiveBar } from "@nivo/bar";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const BarChart = ({ title, data, customProperties, className }) => {
  return (
    <div
      className={`w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ${className}`}
    >
      <div className="text-sm text-gray-800 font-medium mb-2">{title}</div>
      <div style={{ height: "300px" }}>
        <ResponsiveBar
          data={data.filter(({ value }) => value > 0)}
          {...customProperties}
          tooltip={({ data, value }) => {
            return (
              <div>
                <span className="text-xs uppercase font-bold">{data.id}: </span>
                <span className="text-sm">{formatter.format(value)}</span>
              </div>
            );
          }}
          label={(_label) => `${_label.data.label}`}
          indexBy="id"
        ></ResponsiveBar>
      </div>
    </div>
  );
};

export default BarChart;

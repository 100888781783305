import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ErrorComponent from "../ErrorComponent";
import LoadingIndicator from "../LoadingIndicator";
import ClipboardCopy from "../ClipboardCopy";
import { DOMAIN_PATH, NEW_SUBSCRIPTION_PATH } from "../../constants/paths";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
import MdCopy from "react-ionicons/lib/MdCopy";

// Import modal component.
import Modal from "react-modal";
import { changePassword } from "../../api/Restaurant";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const schema = yup.object().shape({
  password: yup.string().required("Debes ingresar tu contraseña"),
});

const SubscriptionPasswordForm = ({ closeModal, modalIsOpen, title, id }) => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    changePassword(id, data.password)
      .then(() => {
        setSuccess(true);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        setError(error);
      });
  };

  if (success)
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-medium text-gray-900 text-xl mb-1 leading-tight">
            Éxito creando la contraseña
          </h3>
          <p className="text-sm text-gray-700">
            Comparte la contraseña y el enlace de registro con tu socio.
          </p>
          <ClipboardCopy
            value={`Registra el restaurante ${title} a través de este enlace: ${DOMAIN_PATH}${NEW_SUBSCRIPTION_PATH}?id=${id}`}
          >
            <p
              className="flex items-center text-gray-800 text-sm mt-6"
              style={{ width: "fit-content" }}
            >
              <MdCopy className="mr-1 fill-current"></MdCopy>
              Compartir enlace de registro.
            </p>
          </ClipboardCopy>
        </div>
      </Modal>
    );
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={closeModal}
        ></MdCloseCircle>
        <h3 className="font-medium text-gray-900 text-xl mb-1 leading-tight">
          Crea una contraseña
        </h3>
        <p className="text-sm text-gray-700">
          Por tu seguridad, crea una contraseña única para el restaurante y
          compártela con tu socio.
        </p>
        {loading ? (
          <LoadingIndicator></LoadingIndicator>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Contraseña
              </label>
              <input
                type="password"
                name="password"
                ref={register}
                className="input"
              />
              <ErrorComponent error={errors.password}></ErrorComponent>
            </div>
            <button type="submit" className="button-main">
              Crear contraseña
            </button>
            <ErrorComponent error={null}></ErrorComponent>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default SubscriptionPasswordForm;

import React from "react";

// Import report generation forms.
import CancellationsReportForm from "./reports/CancellationsReportForm";
import DiscountsReportForm from "./reports/DiscountsReportForm";
import SalesReportForm from "./reports/SalesReportForm";
import SalesReportFormMovs from "./reports/SalesReportFormMovs";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import modal component.
import Modal from "react-modal";
import AssistanceReportForm from "./reports/AssistanceReportForm";
import ProductsInProductionForm from "./reports/ProductsInProductionForm";
import { useTranslation } from "react-i18next";
import InvoicesReportForm from "./reports/InvoicesReportForm";
import CanceledInvoicesReportForm from "./reports/CanceledInvoicesReportForm";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const ReportGenerationModal = ({
  modalIsOpen,
  closeModal,
  reportType,
  restaurantId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      className="py-8 wrapper relative tab-rep-resp"
    >
      {reportType === "sales" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.salesRep")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle")}
          </p>
          <SalesReportForm
            restaurantId={restaurantId}
            type={"corte"}
          ></SalesReportForm>
        </div>
      ) : (
        <></>
      )}
      {reportType === "sales-group" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.salesRepGroup")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle")}
          </p>
          <SalesReportForm
            restaurantId={restaurantId}
            type={"grupo"}
          ></SalesReportForm>
        </div>
      ) : (
        <></>
      )}
      {reportType === "movements" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.movements")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle2")}
          </p>
          <SalesReportFormMovs
            restaurantId={restaurantId}
          ></SalesReportFormMovs>
        </div>
      ) : (
        <></>
      )}
      {reportType === "discounts" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.discountRep")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle3")}
          </p>
          <DiscountsReportForm
            restaurantId={restaurantId}
          ></DiscountsReportForm>
        </div>
      ) : (
        <></>
      )}
      {reportType === "cancellations" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.cancellationRep")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle3")}
          </p>
          <CancellationsReportForm
            restaurantId={restaurantId}
          ></CancellationsReportForm>
        </div>
      ) : (
        <></>
      )}
      {reportType === "assistance" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            {t("restPage.attendanceRep")}
          </h3>
          <p className="text-sm text-gray-700">
            {t("restModals.salesRepModalSubtitle4")}
          </p>
          <AssistanceReportForm
            restaurantId={restaurantId}
          ></AssistanceReportForm>
        </div>
      ) : (
        <></>
      )}
      {/*reportType === "products-in-production" ? (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          ></MdCloseCircle>
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            Reporte de productos en producción
          </h3>
          <p className="text-sm text-gray-700">
            Se generará un archivo PDF con los datos que correspondan a la fecha
            seleccionada.
          </p>
          <ProductsInProductionForm
            restaurantId={restaurantId}
          ></ProductsInProductionForm>
        </div>
      ) : (
        <></>
      )*/}
      {reportType === "invoices" && (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          />
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            Reporte de facturas emitidas por la empresa
          </h3>
          <p className="text-sm text-gray-700">
            Se generará un archivo PDF con los datos que comienzan desde la
            fecha de inicio seleccionada hasta la fecha de fin.
          </p>
          <InvoicesReportForm restaurantId={restaurantId} />
        </div>
      )}
      {reportType === "inv-cancelations" && (
        <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
          <MdCloseCircle
            className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
            onClick={closeModal}
          />
          <h3 className="font-bold text-gray-900 text-xl mb-1">
            Reporte de facturas canceladas por la empresa
          </h3>
          <p className="text-sm text-gray-700">
            Se generará un archivo PDF con los datos que comienzan desde la
            fecha de inicio seleccionada hasta la fecha de fin.
          </p>
          <CanceledInvoicesReportForm restaurantId={restaurantId} />
        </div>
      )}
    </Modal>
  );
};

export default ReportGenerationModal;

import axios from "axios";
import { API_PATH } from "../constants/api";
import jsPDF from "jspdf";
import { formatter } from "./Restaurant";

export const searchFolioLanding = (data) => {
  const { restId, folio, fecha } = data;

  return axios
    .get(`${API_PATH}/facturacion/restaurant/${restId}/folio/${folio}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFacturaPdf = async (restId, invoiceId, format, folio) => {
  try {
    const token = localStorage.getItem("token");

    return axios
      .post(
        `${API_PATH}/facturacion/download`,
        {
          rest_id: restId,
          invoice_id: invoiceId,
          format: format,
          folio: folio,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to fetch PDF");
        }
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `F${folio}.${format}`;

        if (format === "pdf") {
          window.open(url, "_blank");
          return response;
        }

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.error("Error downloading factura:", error);
    throw error;
  }
};

export const cancelFactura = async (id, motive, rest_id, uuid, newInvId) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/facturacion/${rest_id}/cancel/${id}/motive/${motive}?uuid=${uuid}&new_id=${newInvId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendFacturaByEmail = async (restId, invoiceId, email) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/facturacion/send_to_email`,
      {
        rest_id: restId,
        invoice_id: invoiceId,
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.success;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendFacturaByEmailLanding = async (restId, folio, email) => {
  return axios
    .post(`${API_PATH}/facturacion/send_to_email_landing`, {
      rest_id: restId,
      folio: folio,
      email: email,
    })
    .then((response) => {
      return response.success;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFoliosFromRest = async (rest_id, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/facturacion/${rest_id}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.cheque;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateInvoicesPDFReport = (
  invoices,
  startDate,
  endDate,
  totalCanceled,
  totalValid,
  totalInvoiced,
  canceledInvoices,
  validInvoices,
  notInvoicedInWf,
  cheqsTotalSum,
  invoicedCountGeneral,
  totalInvoicedGeneral
) => {
  const doc = new jsPDF("l", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  try {
    doc.addSvgAsImage(img, 480, 40, 60, 60);
  } catch (error) {}

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text("Reporte de facturas", 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    `Reporte de facturas realizadas del ${startDate} al ${endDate}`,
    40,
    90
  );

  doc.setFont("Arial", "normal");

  doc.autoTable({
    headStyles: { fillColor: "#164E2A", fontSize: "7" },
    bodyStyles: { fontSize: "9" },
    startY: 115,
    head: [["Folio", "Fecha", "Razón social", "RFC", "Estatus", "Monto"]],
    body: invoices.map((invoice) => [
      `F${invoice.folio_number}`,
      `${new Date(invoice.created_at)
        .toISOString()
        .split(".")[0]
        .replace("T", " ")}`,
      `${invoice.customer.legal_name}`,
      `${invoice.customer.tax_id}`,
      `${invoice.status === "valid" ? "Válida" : invoice.status === "canceled" ? "Cancelada" : "Pendiente"}`,
      `${formatter.format(
        isNaN(0.0) ? parseFloat(invoice.total).toFixed(2) : invoice.total
      )}`,
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fontSize: "9" },
    headStyles: { fillColor: "#164E2A", fontSize: "8" },
    startY: finalY_1 + 25,
    head: [
      [
        "Facturas",
        "Canceladas",
        "Válidas",
        "Total cancelaciones ",
        "Total válidas ",
        // "Total facturado ",
      ],
    ],
    body: [
      [
        `${canceledInvoices + validInvoices}`,
        `${canceledInvoices}`,
        `${validInvoices}`,
        `${formatter.format(
          isNaN(0.0) ? parseFloat(totalCanceled).toFixed(2) : totalCanceled
        )}`,
        `${formatter.format(
          isNaN(0.0) ? parseFloat(totalInvoiced).toFixed(2) : totalInvoiced
        )}`,
        // `${formatter.format(
        //   isNaN(0.0) ? parseFloat(totalInvoiced).toFixed(2) : totalInvoiced
        // )}`,
      ],
    ],
  });
  //msql
  let finalY_2 = doc.previousAutoTable.finalY;
  doc.autoTable({
    bodyStyles: { fontSize: "9" },
    headStyles: { fillColor: "#0C712E", fontSize: "8" },
    startY: finalY_2 + 25,
    head: [
      [
        "Venta total al periodo",
        "Total facturado",
        // "Facturado con WFacturas",
        "Facturado sin Wfacturas",
        "Pendiente por facturar",
      ],
    ],
    body: [
      [
        `${formatter.format(
          isNaN(0.0) ? parseFloat(cheqsTotalSum).toFixed(2) : cheqsTotalSum
        )}`,
        `${formatter.format(
          isNaN(0.0) ? parseFloat(totalInvoiced).toFixed(2) : totalInvoiced
        )}`,
        // `${formatter.format(
        //   isNaN(0.0) ? parseFloat(totalValid).toFixed(2) : totalValid
        // )}`,
        `${formatter.format(
          isNaN(0.0) ? parseFloat(notInvoicedInWf).toFixed(2) : notInvoicedInWf
        )}`,
        `${formatter.format(
          isNaN(0.0)
            ? parseFloat(cheqsTotalSum - totalValid).toFixed(2)
            : cheqsTotalSum - totalValid
        )}`,
      ],
    ],
  });

  let finalY_3 = doc.previousAutoTable.finalY;
  doc.autoTable({
    bodyStyles: { fontSize: "9" },
    headStyles: { fillColor: "#164E2A", fontSize: "8" },
    startY: finalY_3 + 25,
    head: [
      ["Facturas a público en general", "Total a público en general", "Total"],
    ],
    body: [
      [
        `${invoicedCountGeneral}`,
        `${formatter.format(
          isNaN(0.0)
            ? parseFloat(totalInvoicedGeneral).toFixed(2)
            : totalInvoicedGeneral
        )}`,
        `${formatter.format(
          isNaN(0.0)
            ? parseFloat(totalInvoiced + totalInvoicedGeneral).toFixed(2)
            : totalInvoiced + totalInvoicedGeneral
        )}`,
      ],
    ],
  });

  doc.setProperties({
    title: `Reporte de facturas.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();
};

export const getTimbresData = async (rest_id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/timbres/${rest_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const timbresData = response.data.timbres ? response.data.timbres : 0;
    const planActive = response.data.active ? response.data.plan : false;
    const planType = planActive === 99999 ? "UNL" : planActive;
    const periodicity = response.data.periodicity;

    localStorage.setItem("timbresCount", timbresData.toString());
    localStorage.setItem("timbresPlanActive", planType.toString());
    if (periodicity)
      localStorage.setItem("planInterval", periodicity.toString());

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFolioSettings = async (rest_id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${API_PATH}/restaurants/folio_settings/${rest_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllClientsFromRest = async (rest_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/clients/${rest_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.clients;
  } catch (error) {
    throw error;
  }
};

export const getTimbresLanding = async (rest_id) => {
  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/timbres-landing/${rest_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInvoicesWithPartialPayment = async (rest_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/ppayments/${rest_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.invoices;
  } catch (error) {
    throw error;
  }
};

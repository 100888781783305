import React, { useState, useContext, useEffect } from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import PieChart from "../../../components/restaurant/PieChart";
import { DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH } from "../../../constants/distributorPaths";
import { Link } from "react-router-dom";
import "./DistributorDashboardPage.scss";
import { getDistributorData } from "../../../api/Distributor";
import DistributorDashboardModal from "./DistributorDashboardModal/DistributorDashboardModal";
import { date } from "yup";

export const DistributorDashboardPage = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectDistributor, setSelectDistributor] = useState(null);
  const [dates, setDates] = useState([]);
  const [distributor, setdistributor] = useState();
  const accountDateOrigin =
    distributor && distributor.created_at ? distributor.created_at : "";
  const url = distributor && distributor.id ? distributor.id : "";

  useEffect(() => {
    setdistributor(getDistributorData());
  }, []);

  useEffect(() => {
    dateSelection();
  }, [accountDateOrigin]);

  if (distributor === undefined) {
    return <></>;
  }

  let numToBeConverted = distributor.clabe;
  function convert(num) {
    let arr = Object.values(num);
    return (
      arr
        .splice(0, arr.length - 4)
        .fill("")
        .join("") + arr.splice(-4).join("")
    );
  }

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  function getDaysOfWeek() {
    const separator = " de ";

    let newDate = new Date();
    const dia = newDate.getDay();
    let date = newDate.getDate() - dia + (dia === 0 ? -6 : 1);
    let monthF = newDate.getMonth();
    let ultimoDia = new Date(newDate.setDate(date + 6));
    let dateL = ultimoDia.getDate();
    let monthL = ultimoDia.getMonth();

    const dates = {
      firts: `${date} ${separator} ${monthNames[monthF]}`,
      last: `${dateL} ${separator} ${monthNames[monthF]}`,
    };
    return dates.firts + " al " + dates.last;
  }
  const miniMonthNames = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];
  function miniGetDaysofWeek() {
    const separator = "-";

    let miniNewDate = new Date();
    const minidia = miniNewDate.getDay();
    let minidate = miniNewDate.getDate() - minidia + (minidia === 0 ? -6 : 1);
    let minimonthF = miniNewDate.getMonth();
    let minidiapago = new Date(miniNewDate.setDate(minidate + 7));
    let minipayday = minidiapago.getDate();
    let miniyear = minidiapago.getFullYear();

    const minidates = {
      payday: `${minipayday}${separator}${miniMonthNames[minimonthF]}${separator}${miniyear}`,
    };
    return minidates.payday;
  }

  function dateSelection() {
    const separator = "/";
    const dates = [];

    let limitorigin = new Date(accountDateOrigin);
    let current = new Date();
    current.setDate(current.getDate() + 3);
    let count = 0;

    while (limitorigin <= current) {
      const origindia = limitorigin.getDay();
      let originMOdweek =
        limitorigin.getDate() - origindia + (origindia === 0 ? -6 : 1);
      let originTUdweek = originMOdweek + 1;
      let originWEdweek = originMOdweek + 2;
      let originTHdweek = originMOdweek + 3;
      let originFRdweek = originMOdweek + 4;
      let originSAdweek = originMOdweek + 5;
      let originSUdweek = originMOdweek + 6;
      let originmonth = limitorigin.getMonth() + 1;
      let originyear = limitorigin.getFullYear() - 2000;
      const dateFecha = {
        id: count++,
        datesF: `${originMOdweek}${separator}${originmonth}${separator}${originyear}`,
        datesL: `${originSUdweek}${separator}${originmonth}${separator}${originyear}`,
        datesMO: `${originMOdweek}${separator}${originmonth}${separator}${originyear}`,
        datesTU: `${originTUdweek}${separator}${originmonth}${separator}${originyear}`,
        datesWE: `${originWEdweek}${separator}${originmonth}${separator}${originyear}`,
        datesTH: `${originTHdweek}${separator}${originmonth}${separator}${originyear}`,
        datesFR: `${originFRdweek}${separator}${originmonth}${separator}${originyear}`,
        datesSA: `${originSAdweek}${separator}${originmonth}${separator}${originyear}`,
      };
      dates.push(dateFecha);
      limitorigin.setDate(limitorigin.getDate() + 7);
    }

    setDates(dates, count);
  }

  let comisionWmobil = 100 - distributor.porcentajeIngresos;
  let totalVendido = 10000;
  comisionWmobil = (totalVendido * comisionWmobil) / 100;
  let total = totalVendido - comisionWmobil;

  const formatoComas = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";
    let arr = number.toString().split(".");
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join(".") : arr[0];
  };

  return (
    <LayoutV2 distributor={true}>
      <DistributorDashboardModal open={modalShow} setOpen={setModalShow} />
      <div className="distributor-dashboard-page-main-container">
        <div className="distributor-dashboard-card-component referred-card">
          <div className="header">
            <h2>Referidos</h2>
          </div>
          <div className="content">
            <div
              className="referred-link-container"
              onClick={() => {
                setShowMessage(true);
                navigator.clipboard.writeText(
                  "https://wmobil.mx/registro?distribuidor=" + url
                );
                setTimeout(() => {
                  setShowMessage(false);
                }, 2000);
              }}
            >
              <p>
                Enlace de registro:{" "}
                <b>https://wmobil.mx/registro?distribuidor={distributor.id}</b>
              </p>
              <img
                src={require("../../../images/copy-icon.svg")}
                alt="icono de copiar"
              />
              {showMessage && (
                <p className="referred-link-message">
                  Copiado al portapapeles!
                </p>
              )}
            </div>
            <div className="download-button-container">
              <button
                data-micromodal-trigger="modal-1"
                onClick={() => {
                  setModalShow(true);
                }}
              >
                Descargar Ejecutable de Servidor{" "}
                <img
                  src={require("../../../images/download-green-icon.svg")}
                  alt="icono de descarga"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="distributor-dashboard-card-component billing-card">
          <div className="header">
            <h2>Facturación</h2>
            <p>
              Para la semana del <span>{getDaysOfWeek()}</span>
            </p>
          </div>
          <div className="content">
            <div className="container billing-info-container">
              <div className="row">
                <div className="column description">
                  <p>Total de los pagos:</p>
                </div>
                <div className="column payment">
                  <p>
                    $<span>{formatoComas(total.toFixed(2))}</span>MXN
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="column description">
                  <p>Fecha de pago:</p>
                </div>
                <div className="column right-text">
                  <p>{miniGetDaysofWeek()}</p>
                  {numToBeConverted === null ? (
                    <p>No tienes una cuenta registrada</p>
                  ) : (
                    <p>A la cuenta terminada en: {convert(numToBeConverted)}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="column description">
                  <p>Suscripciones realizadas:</p>
                </div>
                <div className="column right-text">
                  <p>14 Suscripciones</p>
                </div>
              </div>
            </div>
            <div className="container balance-info-main-container">
              <div className="balance-container">
                <div className="header">
                  <p>Detalles de ganancias:</p>
                </div>
                <div className="balance-row">
                  <div className="description-container">
                    <p className="description">Total Vendido</p>
                  </div>
                  <div className="amount-container total">
                    <p className="amount">
                      $ {formatoComas(totalVendido.toFixed(2))}
                    </p>
                  </div>
                </div>
                <div className="balance-row">
                  <div className="description-container">
                    <p className="description">Impuestos y Comisiones</p>
                  </div>
                  <div className="amount-container comision">
                    <p className="amount">
                      - $ {formatoComas(comisionWmobil.toFixed(2))}
                    </p>
                  </div>
                </div>
                <div className="balance-row total">
                  <div className="description-container">
                    <p className="description">Total</p>
                  </div>
                  <div className="amount-container total">
                    <p className="amount">$ {formatoComas(total.toFixed(2))}</p>
                  </div>
                </div>
              </div>
              <div className="chart">
                <PieChart
                  customProperties={{
                    animate: true,
                    margin: { top: 30, right: 30, bottom: 30, left: 30 },
                  }}
                  colors={["#5A626F", "#5BAC43"]}
                  className={"distributor-dashboard-chart"}
                  data={[
                    {
                      id: "Comisión Wmobil",
                      label: "Comisión Wmobil",
                      value: parseFloat(comisionWmobil),
                    },
                    {
                      id: "Total",
                      label: "Total",
                      value: parseFloat(total),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="distributor-dashboard-card-component payments-card">
          <div className="header">
            <h2>Pagos</h2>
            <p>Semana a inspeccionar</p>
          </div>
          <div className="distributor-dashboard-Select-fecha">
            {}
            <select
              onChange={(e) => {
                setSelectDistributor(e.target.value);
              }}
              options={(e) => {
                setSelectDistributor(e.options);
              }}
              defaultValue={(e) => {
                setSelectDistributor(e.target.value);
              }}
              placeholder={(e) => {
                setSelectDistributor(e.placeholder);
              }}
            >
              {dates &&
                dates.length > 0 &&
                dates
                  .slice(0)
                  .reverse()
                  .map((item) => {
                    return (
                      <option
                        value={item.datesF + " - " + item.datesL}
                        key={item.id}
                        selected={item.id == 1000}
                      >
                        {item.datesF + " - " + item.datesL}
                      </option>
                    );
                  })}
            </select>
          </div>
          <div className="distributor-dashboard-table">
            <table className="distributor-dashboard-table-content">
              <tr className="distributor-dashboard-table-header">
                <td>Fecha</td>
                <td>Total de Suscripciones</td>
                <td>Ganancias Totales</td>
                <td>Detalles</td>
              </tr>
              {selectDistributor != "null" && selectDistributor != null
                ? dates.map((item) => {
                    if (
                      item.datesF + " - " + item.datesL ==
                      selectDistributor
                    ) {
                      return selectDistributor ==
                        item.datesF + " - " + item.datesL ? (
                        <>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesMO}</td>
                            <td>3 Suscripciones</td>
                            <td>$1,500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesTU}</td>
                            <td>2 Suscripciones</td>
                            <td>$900.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesWE}</td>
                            <td>2 Suscripciones</td>
                            <td>$900.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesTH}</td>
                            <td>4 Suscripciones</td>
                            <td>$2,200.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesFR}</td>
                            <td>0 Suscripciones</td>
                            <td>$0.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesSA}</td>
                            <td>1 Suscripcion</td>
                            <td>$500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesL}</td>
                            <td>2 Suscripciones</td>
                            <td>$1,500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr className="distributor-dashboard-table-body">
                          <td>HALO REACH!!!</td>
                        </tr>
                      );
                    }
                  })
                : dates.map((item) => {
                    let lastElement = dates[dates.length - 1];
                    if (item == lastElement) {
                      return (
                        <>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesMO}</td>
                            <td>3 Suscripciones</td>
                            <td>$1,500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesTU}</td>
                            <td>2 Suscripciones</td>
                            <td>$900.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesWE}</td>
                            <td>2 Suscripciones</td>
                            <td>$900.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesTH}</td>
                            <td>4 Suscripciones</td>
                            <td>$2,200.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesFR}</td>
                            <td>0 Suscripciones</td>
                            <td>$0.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesSA}</td>
                            <td>1 Suscripcion</td>
                            <td>$500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                          <tr className="distributor-dashboard-table-body">
                            <td>{item.datesL}</td>
                            <td>2 Suscripciones</td>
                            <td>$1,500.00 MXN</td>
                            <td>
                              <Link to={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                                <button className="distributor-dashboard-table-button">
                                  <p className="distributor-dashboard-table-button-content">
                                    Inspeccionar
                                  </p>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    }
                  })}
            </table>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
};

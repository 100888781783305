import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../ErrorComponent";
import StatesSelect from "./StatesSelect";
import { getUserData, updateData } from "../../api/User";

import LoadingIndicator from "../LoadingIndicator";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  name: yup.string().optional(),
  lastName: yup.string().optional(),
  address: yup.string().optional(),
  state: yup.string().optional(),
  city: yup.string().optional(),
  phone: yup.string().optional(),
  franchise_total: yup.number().optional().min(1),
  commercial_name: yup.string().optional(),
  contact_name: yup.string().optional(),
});

const MyAccountForm = () => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [userData, setUserData] = useState({});

  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: userData,
  });

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    if (success) setUserData(getUserData());
  }, [success]);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  const onSubmit = (values) => {
    setLoading(true);
    setSuccess(false);
    updateData(values)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setSuccess(false);
      });
  };

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <label className="text-lg font-bold text-gray-900 leading-4 mt-4 block">
        {t("dashboard.general")}
      </label>
      <div className="flex">
        <div className="input-wrapper mt-4 flex-1 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.name")}
          </label>
          <input
            type="text"
            ref={register}
            className="input"
            name="name"
            defaultValue={userData.name}
          ></input>
        </div>
        <div className="input-wrapper mt-4 flex-1">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.lName")}
          </label>
          <input
            type="text"
            ref={register}
            className="input"
            name="lastName"
            defaultValue={userData.lastName}
          ></input>
        </div>
      </div>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signInForm.email")}
        </label>
        <input
          type="email"
          disabled
          className="input"
          name="email"
          defaultValue={userData.email}
        ></input>
      </div>
      <div className="flex">
        <div className="input-wrapper mt-4 flex-1 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.city")}
          </label>
          <input
            type="text"
            className="input"
            name="city"
            ref={register}
          ></input>
          <ErrorComponent error={errors.city}></ErrorComponent>
        </div>
        <div className="input-wrapper mt-4 flex-1">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.state")}
          </label>
          <StatesSelect
            name="state"
            defaultValue={userData.state}
            register={register}
          ></StatesSelect>
          <ErrorComponent error={errors.state}></ErrorComponent>
        </div>
      </div>
      <div className="flex">
        <div className="input-wrapper mt-4 flex-1 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.branch")}
          </label>
          <input
            type="number"
            className="input"
            name="franchise_total"
            ref={register}
          ></input>
          <ErrorComponent error={errors.franchise_total}></ErrorComponent>
        </div>
        <div className="input-wrapper mt-4 flex-1">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.address")}
          </label>
          <input
            type="text"
            className="input"
            ref={register}
            name="address"
          ></input>
          <ErrorComponent error={errors.address}></ErrorComponent>
        </div>
      </div>
      <label className="text-lg font-bold text-gray-900 leading-4 mt-8 block">
        {t("dashboard.contactInfo")}
      </label>
      <div className="flex">
        <div className="input-wrapper mt-4 flex-1 mr-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.comName")}
          </label>
          <input
            type="text"
            className="input"
            name="commercial_name"
            ref={register}
          ></input>
          <ErrorComponent error={errors.commercial_name}></ErrorComponent>
        </div>
        <div className="input-wrapper mt-4 flex-1">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signUpForm.contName")}
          </label>
          <input
            name="contact_name"
            type="text"
            className="input"
            ref={register}
          ></input>
          <ErrorComponent error={errors.contact_name}></ErrorComponent>
        </div>
      </div>
      <div className="input-wrapper mt-4 flex-1">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("installPage.phoneField")}
        </label>
        <input type="tel" className="input" ref={register} name="phone"></input>
        <ErrorComponent error={errors.phone}></ErrorComponent>
      </div>
      <button className="button-main" type="submit">
        {t("signUpForm.saveData")}
      </button>
    </form>
  );
};

export default MyAccountForm;

import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

const WToggle = forwardRef(({ value, onChange, disabled }, ref) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(value);

  const spanClass = `
    w-1/2
    h-full
    ${checked ? "ml-6" : ""}
    wbg-green-logo
    box-border
    rounded-lg 
    transition-all 
    duration-500
  `;

  const handleChange = () => {
    if (!disabled) {
      setChecked((prevChecked) => !prevChecked);
      onChange(checked);
    }
  };

  return (
    <div className="flex">
      <div
        ref={ref}
        onClick={handleChange}
        className={`flex items-center justify-start p-1 w-16 h-10 border-2 transition-all duration-500 ${!disabled ? "cursor-pointer" : ""} mx-3 wborder-green-logo rounded-lg`}
      >
        <span className={spanClass} />
      </div>
    </div>
  );
});

export default WToggle;

import React from "react";

import SEO from "react-seo-component";
import WhatsappButton from "../WhatsappButton";
import LanguageButton from "../LanguageButton";
import { NavbarV2 } from "../NavbarV2/NavbarV2";
import Navbar from "../navigation/Navbar";
import "./LayoutV2Styles.scss";
import { Footer } from "../footer/Footer";

const LayoutV2 = ({
  children,
  className,
  title,
  description,
  image,
  fullWidth,
  admin,
  distributor,
  restaurant,
  location,
  type,
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavbarV2
        location={location}
        admin={admin}
        distributor={distributor}
        restaurant={restaurant}
      />

      {children}
      <Footer />

      <LanguageButton />
      <WhatsappButton />
    </div>
  );
};

export default LayoutV2;

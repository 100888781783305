import React from "react";

import ClipboardCopy from "../ClipboardCopy";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";
import MdCopy from "react-ionicons/lib/MdCopy";

// Import modal component.
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const NewSubscriptionModal = ({
  closeModal,
  modalIsOpen,
  restaurant,
  license,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={closeModal}
        ></MdCloseCircle>
        <h3 className="font-medium text-gray-900 text-xl mb-1 leading-tight">
          {t("subsPage.newSubModalTitle")} ({restaurant?.text})
        </h3>
        <p className="text-sm font-normal text-gray-700">
          {t("subsPage.newSubModalSubtitle")}{" "}
        </p>
        <ClipboardCopy value={license}>
          <span className="font-medium text-gray-800 text-normal py-2">
            {license}
          </span>
        </ClipboardCopy>
        <a
          className="button-main w-full block text-center mt-4"
          href="https://www.dropbox.com/s/jwpry57u6928849/Wmobil.exe?dl=1"
          download
        >
          {t("subsPage.newSubModalDownload")}
        </a>
      </div>
    </Modal>
  );
};

export default NewSubscriptionModal;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SortFoliosTable from "../../SortFoliosTable";
import WInput from "../../WInput";

const ClientsTable = ({ clients, restId }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [currPage, setCurrPage] = useState(0);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const columns = [
    {
      Header: "RFC",
      accessor: "tax_id",
    },
    { Header: "Razón social", accessor: "legal_name" },
    {
      Header: "Régimen fiscal",
      accessor: "tax_system",
    },
    {
      Header: "C.P.",
      accessor: "address.zip",
    },
    {
      Header: "Correo electrónico",
      accessor: "email",
    },
  ];

  const filteredCheques = clients.filter((cheque) => {
    const includesSearch = ["legal_name", "tax_id", "tax_system", "email"].some(
      (key) => {
        const value = String(cheque[key]).toLowerCase();
        return value.includes(searchValue.toLowerCase());
      }
    );

    return includesSearch;
  });

  return (
    <div>
      <div className="flex items-center justify-end mb-4">
        <div className="flex flex-col justify-between">
          <WInput
            type="search"
            label="Buscar"
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <SortFoliosTable
        actualPage={currPage}
        setCurrPage={setCurrPage}
        columns={columns}
        data={filteredCheques}
      />
    </div>
  );
};

export default ClientsTable;

import React from "react";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DetailedCard = ({
  total,
  cuentasabiertas,
  cuentascanceladas,
  articulos,
  comensales,
  prediccion,
}) => {
  const { t } = useTranslation();
  return (
    <div className="block px-4 py-2 rounded border mt-2 shadow-sm text-gray-700 border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <h4 className="text-sm text-gray-800 font-medium">Total</h4>
          <span className="flex items-baseline">
            <span className="text-brand-700 font-bold text-4xl">
              {formatter.format(
                typeof total === "number" ? total : parseFloat(total)
              )}
            </span>
            <span className="text-xs ml-1 uppercase">MXN</span>
          </span>
          <span className="text-gray-700 font-light text-sm">
            {t("restPage.prediction")}{" "}
            <span className="font-medium">
              {formatter.format(parseFloat(prediccion))}
            </span>
            <span className="text-xs ml-1 uppercase font-light">MXN</span>
          </span>
        </div>
        <div className="text-right text-sm font-light">
          {/*  <p className="transition duration-500 ease-in-out 
          hover:text-brand-700 transform hover:-translate-y-1 ">
            Cuentas que se cancelaron:{" "}
            <span className="text-lg font-medium text-gray-800">
              {cuentascanceladas}
            </span>
          </p> */}
          <p
            className="transition duration-500 ease-in-out 
          hover:text-brand-700 transform hover:-translate-y-1 "
          >
            {t("restPage.soldProds")}{" "}
            <span className="text-lg font-medium text-gray-800">
              {articulos}
            </span>
          </p>
          <p
            className="transition duration-500 ease-in-out 
          hover:text-brand-700 transform hover:-translate-y-1"
          >
            {t("restPage.diners")}{" "}
            <span className="text-lg font-medium text-gray-800">
              {comensales}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailedCard;

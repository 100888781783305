import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../ErrorComponent";
import { createGroup, getAllRestaurants } from "../../api/Groups";
import { Redirect } from "react-router-dom";
import { APP_HOME_PATH } from "../../constants/paths";
import LoadingIndicator from "../LoadingIndicator";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del grupo."),
  restaurants: yup
    .array()
    .test(
      "at-least-one",
      "Debes seleccionar al menos un restaurante",
      (restaurants) => {
        return restaurants.some((selected) => selected);
      }
    ),
});

const NewGroupForm = () => {
  const { t } = useTranslation();

  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Variable for holding all restaurants from user
  const [restaurants, setRestaurants] = useState([]);

  // Instantiate form
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setLoading(true);

    getAllRestaurants()
      .then((rests) => {
        setLoading(false);
        setError(null);
        setRestaurants(rests);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setRestaurants([]);
      });
  }, []);

  const onSubmit = (values) => {
    setLoading(true);

    const selectedRestaurants = values.restaurants
      .map((selected, index) => {
        return { ...restaurants[index], selected };
      })
      .filter((restaurant) => {
        return restaurant.selected;
      });

    createGroup({
      restaurants: selectedRestaurants,
      title: values.title,
    })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        setError(error);
      });
  };

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (success) return <Redirect to={APP_HOME_PATH}></Redirect>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("dashboard.groupName")}
        </label>
        <input type="text" name="title" ref={register} className="input" />
        <ErrorComponent error={errors.title}></ErrorComponent>
      </div>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("navBar.rest")}
        </label>
        <div className="mt-4">
          {restaurants.map((restaurant, index) => (
            <div className="w-full border rounded px-3 py-2 text-gray-700 flex items-center mb-2">
              <input
                type="checkbox"
                name={`restaurants[${index}]`}
                ref={register}
              />
              <span className="ml-2">{restaurant.text}</span>
            </div>
          ))}
          <ErrorComponent error={errors.restaurants}></ErrorComponent>
        </div>
      </div>
      <button type="submit" className="button-main">
        {t("dashboard.createGroup")}
      </button>
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default NewGroupForm;

import React, { useEffect, useState } from "react";
import "./MyAccountPage.css";
import { useTranslation } from "react-i18next";
import SearchFacturaForm from "../../components/account/SearchFacturaForm";
import WFacturasLayout from "../../components/WFacturasLayout";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import WFacturasLogo from "../../images/wmobilfacturacion.svg";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";

const SearchFacturaPage = () => {
  const { t } = useTranslation();
  const _ = useRestsDropdown();
  const [clickedFromNav, setClickedFromNav] = useState(
    localStorage.getItem("clicikedFromNavbar") === "true"
  );

  useEffect(() => {
    if (clickedFromNav) {
      return;
    }

    const loaderTimeout = setTimeout(() => {
      localStorage.setItem("clicikedFromNavbar", "true");
      setClickedFromNav(true);
    }, 1500);

    return () => clearTimeout(loaderTimeout);
  }, [clickedFromNav]);

  return (
    <WFacturasLayout title="Mi cuenta" fullWidth>
      {!clickedFromNav ? (
        <div className="wfacturas-screen">
          <img
            src={WFacturasLogo}
            alt="WFacturas Logo"
            className="wfacturas-logo-screen"
          />
        </div>
      ) : (
        <div className="flex myaccount-main-container">
          <InvoicesSidebar />
          <SearchFacturaForm />
        </div>
      )}
    </WFacturasLayout>
  );
};

export default SearchFacturaPage;

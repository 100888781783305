import React from "react";
import Layout from "../../components/Layout";
import NewPaymentMethodForm from "../../components/methods/NewPaymentMethodForm";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { STRIPE_PUBLISHABLE_KEY } from "../../constants/secrets";

// const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const NewPaymentMethodPage = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const redirect = urlParams.get("redirect") || "";

  return (
    <Layout title="Nuevo método de pago">
      <div className="py-8 wrapper">
        <h2 className="text-2xl text-gray-800 font-bold">
          Editar método de pago
        </h2>
        {/* <Elements stripe={stripePromise}>
          <NewPaymentMethodForm redirect={redirect}></NewPaymentMethodForm>
        </Elements> */}
        <NewPaymentMethodForm redirect={redirect}></NewPaymentMethodForm>
      </div>
    </Layout>
  );
};

export default NewPaymentMethodPage;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BarLoader } from "react-spinners";
import LegalDataInputForm from "./account/LegalDataInputForm";
import { getInvoiceData } from "../api/User";
import TicketDataInputForm from "./account/TicketDataInputForm";
import ItemsPreview from "./account/ItemsPreview";
import WSelect from "./WSelect";

const customStyles = {
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const schema = yup.object().shape({
  legal_name: yup.string().required(),
  support_email: yup.string().optional(),
  street: yup.string().optional(),
  exterior: yup.string().optional(),
  zip: yup.string().required(),
  city: yup.string().optional(),
  state: yup.string().optional(),
  tax_id: yup.string().required().min(12).max(13),
  phone: yup.string().optional(),
  tax_system: yup
    .string()
    .required()
    .test(function (reg) {
      return reg !== "no";
    }),
  folio: yup.string().optional(),
  amount: yup.string().optional(),
  date: yup.string().required(),
  use: yup
    .string()
    .required()
    .test(function (use) {
      return use !== "no";
    }),
  payment_form: yup
    .string()
    .optional()
    .test(function (pay) {
      return pay !== "no";
    }),
  payment_method: yup.string().optional(),
  pdf_custom_section: yup.string().optional(),
  type: yup.string().optional(),
});

const WCancelAndInvoice = ({
  restId,
  modalData,
  motive,
  series,
  openCancelWithMotive,
  handleClose,
  loading,
  handleCancel,
  handleCreateInvoice,
  setUserEmail,
}) => {
  const [prevInvoiceData, setPrevInvoiceData] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState("Generando factura");
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const invoiceId = modalData.id;
  const typeRebill = modalData.type === "rebill";
  const fetchInvoiceData = async () => {
    const invoiceData = await getInvoiceData(invoiceId, restId);
    setPrevInvoiceData(invoiceData);
    setUserEmail(invoiceData.customer.email);
  };

  const onSubmit = async (values) => {
    try {
      const today = new Date();
      today.setHours(today.getHours() - 1);
      const todayHours = today.toISOString().split("T")[1];
      const dateString = `${values.date}T${todayHours}`;

      values.payment_method = values.payment_form === "99" ? "PPD" : "PUE";
      values.items = prevInvoiceData.items;
      values.date = dateString;
      values.rest_id = restId;
      values.folio = prevInvoiceData.folio;

      const serieFolioStart = series?.find((serie) => {
        return serie.serie_id === values.serie_id;
      });

      if (serieFolioStart) {
        values.serie_initial_folio = serieFolioStart.folio_start;
      }
      const inv = await handleCreateInvoice(values);

      if (!typeRebill) {
        setLoaderMessage("Cancelando factura con errores");
        const uuid = inv.invoiceData.uuid;
        const newInvId = inv.invoiceData.id;
        const data = { invoiceId, uuid, motive, newInvId };
        await handleCancel(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    reset({
      type: prevInvoiceData ? prevInvoiceData.type : "",
      legal_name: prevInvoiceData ? prevInvoiceData.customer.legal_name : "",
      tax_id: prevInvoiceData ? prevInvoiceData.customer.tax_id : "",
      tax_system: prevInvoiceData ? prevInvoiceData.customer.tax_system : "",
      zip: prevInvoiceData ? prevInvoiceData.customer.address.zip : "",
      state: prevInvoiceData ? prevInvoiceData.customer.address.state : "",
      street: prevInvoiceData ? prevInvoiceData.customer.address.street : "",
      exterior: prevInvoiceData
        ? prevInvoiceData.customer.address.exterior
        : "",
      city: prevInvoiceData ? prevInvoiceData.customer.address.city : "",
      phone: prevInvoiceData ? prevInvoiceData.customer.phone : "",
      support_email: prevInvoiceData ? prevInvoiceData.customer.email : "",
      payment_form: prevInvoiceData ? prevInvoiceData.payment_form : "",
      amount: prevInvoiceData ? prevInvoiceData.total : "",
      use: prevInvoiceData ? prevInvoiceData.use : "",
    });
  }, [prevInvoiceData, reset]);

  useEffect(() => {
    if (invoiceId) fetchInvoiceData();
  }, [invoiceId]);

  useEffect(() => {
    const paymentMethodValue = watch("payment_form");
    const value = paymentMethodValue === "99" ? "PPD" : "PUE";
    setValue("payment_method", value);
  }, [watch]);

  return (
    <Modal
      isOpen={openCancelWithMotive}
      style={customStyles}
      onRequestClose={handleClose}
      className="wfacturas-modal-invoice tab-rep-resp"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between w-title-container">
          <h3 className="text-2xl font-semibold">
            {typeRebill ? "Re-facturar" : "Cancelar factura"}
          </h3>
          <div className="cursor-pointer" onClick={handleClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 15L15 5M5 5L15 15"
                stroke="#3F2E04"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="w-body-container">
          {typeRebill ? (
            <p className="mb-4">
              Verifique los datos para volver a generar la factura
            </p>
          ) : (
            <p className="mb-4">
              Complete los datos para generar una nueva factura y cancelar la
              factura de errores con relación
            </p>
          )}
          {prevInvoiceData ? (
            <>
              <label className="text-xl mb-4 font-semibold wfacturas-table-text mt-5 block">
                Datos fiscales
              </label>
              <div className="pb-4 w-32">
                <WSelect
                  type="digits"
                  name="serie_id"
                  className=" wfacturas-input appearance-none"
                  ref={register}
                  label="Serie"
                >
                  <option value={"no"}>F</option>
                  {series?.map((serie) => (
                    <option value={serie.serie_id}>{serie.serie_id}</option>
                  ))}
                </WSelect>
              </div>
              <LegalDataInputForm
                type="cancel"
                register={register}
                filteredLegalName={[]}
                selectedClient={prevInvoiceData}
                errors={errors}
                setSelectedClient={(val) => setPrevInvoiceData(val)}
              />
              <label className="text-xl mb-4 font-semibold wfacturas-table-text mt-5 block">
                Datos de consumo
              </label>
              <TicketDataInputForm
                type="cancel"
                uuid={prevInvoiceData.uuid}
                register={register}
                errors={errors}
                cheques={prevInvoiceData}
              />
              <ItemsPreview
                type="cancel"
                cheques={[prevInvoiceData]}
                total={prevInvoiceData.total}
              />
            </>
          ) : (
            <div className="flex flex-col w-full justify-center items-center p-4">
              <BarLoader />
              <label className="mt-2">Cargando datos de la factura</label>
            </div>
          )}
        </div>
        <div className="w-buttons-container">
          <>
            {loading ? (
              <div className="flex flex-col w-full justify-center items-center p-4">
                <BarLoader />
                <label className="mt-2">{loaderMessage}</label>
              </div>
            ) : (
              <>
                <button
                  className="wfacturas-button-outline"
                  onClick={handleClose}
                >
                  Regresar
                </button>
                <button className="wfacturas-button" type="submit">
                  {typeRebill ? "refacturar" : "Cancelar y refacturar"}
                </button>
              </>
            )}
          </>
        </div>
      </form>
    </Modal>
  );
};

export default WCancelAndInvoice;

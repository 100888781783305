import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ErrorComponent from "../ErrorComponent";
import LoadingIndicator from "../LoadingIndicator";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import modal component.
import Modal from "react-modal";
import { setGoals } from "../../api/Restaurant";
import { useTranslation } from "react-i18next";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const schema = yup.object().shape({
  daily: yup
    .number()
    .min(0, "Debe ser un número positivo.")
    .typeError("Debe ser un número."),
  weekly: yup
    .number()
    .min(0, "Debe ser un número positivo.")
    .typeError("Debe ser un número."),
  monthly: yup
    .number()
    .min(0, "Debe ser un número positivo.")
    .typeError("Debe ser un número."),
  yearly: yup
    .number()
    .min(0, "Debe ser un número positivo.")
    .typeError("Debe ser un número."),
});

const GoalsForm = ({ closeModal, modalIsOpen, id, goals }) => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    setGoals(id, data)
      .then(() => {
        setSuccess(true);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        setError(error);
      });
  };

  useEffect(() => {
    if (success) {
      setSuccess(false);
      closeModal();
      window.location.reload();
    }
  }, [success]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="px-8 py-6 bg-white border max-w-lg w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={closeModal}
        ></MdCloseCircle>
        <h3 className="font-medium text-gray-900 text-xl mb-1 leading-tight">
          {t("restPage.goalModalTitle")}
        </h3>
        {loading ? (
          <LoadingIndicator></LoadingIndicator>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                {t("restPage.goalModalInput1")}
              </label>
              <input
                type="number"
                name="daily"
                ref={register}
                className="input"
                defaultValue={goals.daily || 0}
              />
              <ErrorComponent error={errors.daily}></ErrorComponent>
            </div>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                {t("restPage.goalModalInput2")}
              </label>
              <input
                type="number"
                name="weekly"
                ref={register}
                className="input"
                defaultValue={goals.weekly || 0}
              />
              <ErrorComponent error={errors.weekly}></ErrorComponent>
            </div>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                {t("restPage.goalModalInput3")}
              </label>
              <input
                type="number"
                name="monthly"
                ref={register}
                className="input"
                defaultValue={goals.monthly || 0}
              />
              <ErrorComponent error={errors.monthly}></ErrorComponent>
            </div>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                {t("restPage.goalModalInput4")}
              </label>
              <input
                type="number"
                name="yearly"
                ref={register}
                className="input"
                defaultValue={goals.yearly || 0}
              />
              <ErrorComponent error={errors.yearly}></ErrorComponent>
            </div>
            <button type="submit" className="button-main">
              {t("restPage.goalModalBtn")}
            </button>
            <ErrorComponent error={null}></ErrorComponent>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default GoalsForm;

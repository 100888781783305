import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WSelect from "../WSelect";
import LoadingIndicator from "../LoadingIndicator";
import { useToast } from "../../context/ToastContext";
import "jspdf-autotable";
import { getAllClientsFromRest } from "../../api/Utils";
import ClientsTable from "../restaurant/views/ClientsTable";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";

const ClientsForm = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const selectRef = useRef(null);
  const { restaurants, restId, setRestId, loading, setLoading } =
    useRestsDropdown();
  const [allClients, setAllClients] = useState(null);

  const fetchClients = async (restId) => {
    try {
      setLoading(true);
      const clients = await getAllClientsFromRest(restId);
      setAllClients(clients);
    } catch (error) {
      setAllClients([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRestaurantChange = (value) => {
    setLoading(true);
    setRestId(value);
    setAllClients([]);
  };

  useEffect(() => {
    if (restId) {
      fetchClients(restId);
    }
  }, [restId]);

  return (
    <div className="p-8 w-full mt-12 md:mt-0">
      <div className="flex justify-between">
        <div>
          <h3 className="facturas-title">Clientes</h3>
          <text className="text-md">
            En esta sección puedes encontrar a tus clientes registrados por
            sucursal
          </text>
        </div>
      </div>
      <div>
        <div className="input-wrapper mt-4 mb-4">
          <WSelect
            mandatory
            name="restId"
            label="Seleccione sucursal"
            defaultValue={restaurants}
            ref={selectRef}
            onChange={(value) => handleRestaurantChange(value)}
          >
            {restaurants?.map((rest, index) => (
              <option key={index} value={rest.id}>
                {rest.text}
              </option>
            ))}
          </WSelect>
        </div>
      </div>
      <div className="mt-10">
        {loading || !allClients ? (
          <LoadingIndicator />
        ) : (
          allClients &&
          (allClients.length > 0 ? (
            <ClientsTable clients={allClients} restId={restId} />
          ) : (
            <div>Esta sucursal no tiene ningun cliente registrado</div>
          ))
        )}
      </div>
    </div>
  );
};

export default ClientsForm;

import React, { useEffect, useState } from "react";
import "./MyAccountPage.css";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import WFacturasLayout from "../../components/WFacturasLayout";
import {
  APP_PARTIAL_PAY_LEGAL_DATA,
  APP_SEARCH_FOLIO_FACTURA,
} from "../../constants/paths";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import sectionArrow from "../../images/sectionArrow.svg";
import LoadingIndicator from "../../components/LoadingIndicator";
import NewPartialPayForm from "../../components/account/NewPartialPayForm";

const NewPartialPayPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { rest_id, clientInput, series, invoices, customers, selectedClient } =
    location.state;
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientInput) {
      const filtered = invoices.filter(
        (i) => i.customer.tax_id === clientInput.tax_id
      );

      if (filtered.length > 0) setFilteredInvoices(filtered);
    }
  }, []);

  return (
    <WFacturasLayout title="Mi cuenta" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <div className="p-8 w-full mt-12 md:mt-0">
          <div className="flex items-center font-medium text-xl collaborator-install-back-text-container text-green-800 mt-4">
            <span
              onClick={() => history.push(APP_SEARCH_FOLIO_FACTURA)}
              className="cursor-pointer"
            >
              Folios pendientes
            </span>
            <span>
              <img className="mx-4" src={sectionArrow} />
            </span>
            <span
              onClick={() => {
                history.push({
                  pathname: `${APP_PARTIAL_PAY_LEGAL_DATA}/${rest_id}`,
                  state: {
                    nextPageClients: customers,
                    nextPageSeries: series,
                    nextPageInvoices: invoices,
                    selectedClient: selectedClient,
                  },
                });
              }}
              className="cursor-pointer"
            >
              Información legal
            </span>
            <span>
              <img className="mx-4" src={sectionArrow} />
            </span>
            <span className="font-bold">Nuevo complemento de pago</span>
          </div>
          <div className="flex flex-col">
            {loading ? (
              <LoadingIndicator />
            ) : (
              <NewPartialPayForm
                clientInput={clientInput}
                restId={rest_id}
                invoices={filteredInvoices}
                series={series}
              />
            )}
          </div>
        </div>
      </div>
    </WFacturasLayout>
  );
};

export default NewPartialPayPage;

import React from "react";
import plan150 from "../images/150_plan.svg";
import plan300 from "../images/300_plan.svg";
import plan1000 from "../images/1000_plan.svg";
import planUnlimited from "../images/unl_plan.svg";
import securePayment from "../images/secure_pay.svg";
import stripeLogo from "../images/stripe_pay.svg";
import { LoaderIcon } from "react-hot-toast";
import LoadingIndicator from "./LoadingIndicator";
import { BarLoader } from "react-spinners";

const planLogo = {
  150: plan150,
  300: plan300,
  1000: plan1000,
  UNL: planUnlimited,
};

const PlanCardPay = ({ plan, loading, active, monthly }) => {
  const planPrices = {
    150: monthly ? 499 : 5099,
    300: monthly ? 759 : 7749,
    1000: monthly ? 999 : 9590,
    UNL: monthly ? 999 : 9590,
  };

  const subtotal = parseFloat(planPrices[plan]).toFixed(2);
  const iva = parseFloat(subtotal * 0.16).toFixed(2);
  const total = parseFloat(subtotal * 1.16).toFixed(2);
  const logo = planLogo[plan];

  return (
    <>
      <div className="flex flex-col h-full items-center folios-table-bg rounded-3xl">
        <div className="w-full my-8 flex items-center justify-center">
          <img src={logo} />
        </div>
        <div className="wbg-brown-light wfacturas-table-text w-full py-2 mb-2 items-center text-center">
          <label className="font-bold">
            Facturación {monthly ? "mensual" : "anual"}
          </label>
        </div>
        <div className="flex w-full p-2 px-10 items-center justify-between">
          <label className="ml-4 font-medium wfacturas-table-text text-base">
            Subtotal
          </label>
          <label className="ml-4 font-medium wfacturas-table-text text-base">
            ${subtotal}
          </label>
        </div>
        <div className="flex w-full p-2 px-10 items-center justify-between">
          <label className="ml-4 font-medium wfacturas-table-text text-base">
            IVA
          </label>
          <label className="ml-4 font-medium wfacturas-table-text text-base">
            ${iva}
          </label>
        </div>
        <div className="flex w-full p-2 px-10 items-center justify-between font-semibold wfacturas-table-text text-2xl">
          <label>Total</label>
          <label>${total}</label>
        </div>
        <div className="flex w-full px-10 pt-6 items-end h-full">
          {loading ? (
            <div className="flex w-full justify-center">
              <BarLoader />
            </div>
          ) : active ? (
            <span className="wfacturas-button justify-center w-full h-12">
              Plan activo
            </span>
          ) : (
            <button className="wfacturas-button justify-center w-full h-12">
              Completar compra
            </button>
          )}
        </div>
        <div className="flex py-4 items-center justify-between">
          <img className="mr-4" src={securePayment} />
          <img src={stripeLogo} />
        </div>
      </div>
    </>
  );
};

export default PlanCardPay;

import React from "react";
import NewGroupForm from "../../../components/groups/NewGroupForm";
import Layout from "../../../components/Layout";
import { useTranslation } from "react-i18next";

const NewGroupPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title="Nuevo grupo">
      <div className="py-8 wrapper">
        <h2 className="text-2xl text-gray-800 font-bold">
          {t("dashboard.newGroup")}
        </h2>
        <NewGroupForm></NewGroupForm>
      </div>
    </Layout>
  );
};

export default NewGroupPage;

import Modal from "react-modal";
import React from "react";
import LoadingIndicator from "../LoadingIndicator";
const customStyles = {
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};
const LoadingModal = ({ isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      className="wfacturas-modal-loader tab-rep-resp"
    >
      <LoadingIndicator white />
    </Modal>
  );
};

export default LoadingModal;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { signIn } from "../../../api/Auth";
import { FORGOT_PASSWORD_PATH, SIGNUP_PATH } from "../../../constants/paths";
import ErrorComponent from "../../ErrorComponent";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup.string().required("Debes ingresar tu correo electrónico."),
  password: yup.string().required("Debes ingresar tu contraseña"),
});

const SigninForm = () => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  let path;
  if (location.state !== undefined && location.state !== null) {
    path = location.state.path;
  }

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    signIn(data)
      .then((result) => {
        setLoading(false);
        setError(null);
        if (path !== undefined) {
          history.push(path);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="input-wrapper mt-4">
        <label className="">{t("signInForm.email")}</label>
        <input type="email" name="email" ref={register} className="input" />
        <ErrorComponent error={errors.email}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="">{t("signInForm.pass")}</label>
        <input
          type="password"
          name="password"
          ref={register}
          className="input"
        />
        <ErrorComponent error={errors.password}></ErrorComponent>
      </div>

      <button type="submit" name="submit" className="button-main login-btn">
        {t("navBar.signIn")}
      </button>

      <Link to={SIGNUP_PATH}>
        <button
          type="button"
          name="register"
          className="button-main register-btn"
        >
          {t("navBar.register")}
        </button>
      </Link>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to={FORGOT_PASSWORD_PATH} style={{ textDecoration: "underline" }}>
          {t("signInForm.forgot")}
        </Link>
      </div>

      <br></br>
      <div className="">
        <ErrorComponent error={error}></ErrorComponent>
      </div>
    </form>
  );
};

export default SigninForm;

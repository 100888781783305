import React from "react";
import { useTranslation } from "react-i18next";
import WFacturasLayout from "../../components/WFacturasLayout";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import PlansSubscribe from "../../components/account/PlansSubscribe";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const PlansPay = ({ match }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { plan, restId } = match.params;
  const { planType, active } = location.state?.data || {};

  const searchParams = new URLSearchParams(location.search);
  const monthly = searchParams.get("monthly");
  const planNumber = plan.split("?")[0];
  return (
    <WFacturasLayout title="Mis suscripciones" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <PlansSubscribe
          plan={planNumber}
          restId={restId}
          planType={planType}
          active={active}
          monthly={monthly === "true"}
        />
      </div>
    </WFacturasLayout>
  );
};

export default PlansPay;

import React from "react";
import { useTranslation } from "react-i18next";
import WSelect from "../WSelect";

const CfdiSelect = ({ register, defaultValue, name, type }) => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <WSelect
        mandatory={type !== "landing"}
        name={name}
        label="Uso de CFDI"
        ref={register}
        defaultValue={defaultValue}
        type={type}
      >
        <option value="no">Uso del CFDI</option>
        <option value="G01">G01 - Adquisición de mercancías</option>
        <option value="G02">
          G02 - Devoluciones, descuentos o bonificaciones
        </option>
        <option value="G03">G03 - Gastos en general</option>
        <option value="I01">I01 - Construcciones</option>
        <option value="I02">
          I02 - Mobiliario y equipo de oficina por inversiones
        </option>
        <option value="I03">I03 - Equipo de transporte</option>
        <option value="I04">I04 - Equipo de computo y accesorios</option>
        <option value="I05">
          I05 - Dados, troqueles, moldes, matrices y herramental
        </option>
        <option value="I06">I06 - Comunicaciones telefónicas</option>
        <option value="I07">I07 - Comunicaciones satelitales</option>
        <option value="I08">I08 - Otra maquinaria y equipo</option>
        <option value="D01">
          D01 - Honorarios médicos, dentales y gastos hospitalarios
        </option>
        <option value="D02">
          D02 - Gastos médicos por incapacidad o discapacidad
        </option>
        <option value="D03">D03 - Gastos funerales</option>
        <option value="D04">D04 - Donativos</option>
        <option value="D05">
          D05 - Intereses reales efectivamente pagados por créditos hipotecarios
          (casa habitación)
        </option>
        <option value="D06">D06 - Aportaciones voluntarias al SAR</option>
        <option value="D07">D07 - Primas por seguros de gastos médicos</option>
        <option value="D08">
          D08 - Gastos de transportación escolar obligatoria
        </option>
        <option value="D09">
          D09 - Depósitos en cuentas para el ahorro, primas que tengan como base
          planes de pensiones
        </option>
        <option value="D10">
          D10 - Pagos por servicios educativos (colegiaturas)
        </option>
        <option value="S01">S01 - Sin efectos fiscales</option>
        <option value="CP01">CP01 - Pagos</option>
        <option value="CN01">CN01 - Nómina</option>
      </WSelect>
    </div>
  );
};

export default CfdiSelect;

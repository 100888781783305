import React from "react";
import CfdiSelect from "./CfdiSelect";
import WInput from "../WInput";
import PaymentMethodSelect from "./PaymentMethodSelect";
import WSelect from "../WSelect";

const publicoEnGeneral = {
  legal_name: "Público en general",
  tax_id: "XAXX010101000",
  tax_system: "616",
  use: "S01",
};

const TicketDataInputForm = ({
  type,
  cheques,
  register,
  publicoGral,
  errors,
  uuid,
}) => {
  const minDate = new Date();
  minDate.setHours(minDate.getHours() - 72);
  const isTypeCancel = type === "cancel";
  const gridStyle = isTypeCancel
    ? "grid grid-cols-1 md:grid-cols-2 grid-rows-3 gap-6 gap-x-6 items-center mb-6"
    : "grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center mb-6";

  return (
    <div className={gridStyle}>
      <div>
        <CfdiSelect
          name="use"
          register={register}
          defaultValue={publicoGral ? publicoEnGeneral.use : "G03"}
        />
        {errors.use && <div className="error">Requerido</div>}
      </div>
      <div>
        <WInput
          mandatory
          type="date"
          name="date"
          label="Fecha de emision"
          ref={register}
          maxDate={new Date().toISOString().split("T")[0]}
          minDate={minDate.toISOString().split("T")[0]}
          defaultValue={new Date().toISOString().split("T")[0]}
        />
        {errors.date && (
          <div className="error">Fecha fuera del rango de facturación</div>
        )}
      </div>
      <div>
        <PaymentMethodSelect
          isDisabled={
            cheques?.payment_method != "no"
              ? type !== "cancel"
                ? true
                : false
              : false
          }
          name="payment_form"
          register={register}
        />
        {errors.payment_form && <div className="error">Requerido</div>}
      </div>
      <div>
        <WSelect
          removeArrow
          disabled
          name="payment_method"
          label="Método de pago"
          ref={register}
        >
          <option value="PUE">PUE - Pago en Una sola Exhibición</option>
          <option value="PPD">PPD - Pago en Parcialidades o Diferido</option>
        </WSelect>
        {errors.payment_method && <div className="error">Requerido</div>}
      </div>
      <div>
        <WInput
          mandatory
          disabled
          type="currency"
          ref={register}
          label="Monto"
          name="amount"
        />
        {errors.amount && <div className="error">Monto invalido</div>}
      </div>
      <div>
        <WInput ref={register} label="Notas" name="pdf_custom_section" />
        {errors.pdf_custom_section && (
          <div className="error">Clave inválida</div>
        )}
      </div>
    </div>
  );
};

export default TicketDataInputForm;

import React, { useState, useEffect } from "react";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";

import MdMail from "react-ionicons/lib/MdMail";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import SortableTable from "../../components/SortableTable";
import { Link } from "react-router-dom";
import DotComponent from "../../components/DotComponent";
import { fetchReferral } from "../../api/Distributor";
import {
  DISTRIBUTOR_HOME_PATH,
  DISTRIBUTOR_REFERRALS_PATH,
} from "../../constants/distributorPaths";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DistributorReferralPage = ({ match }) => {
  // Variables for handling user data from API.
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurants once
    fetchReferral(match.params.id)
      .then((usr) => {
        setUser(usr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const NameCell = ({ value }) => {
    const { id, name } = value;
    return (
      <Link
        to={`${DISTRIBUTOR_REFERRALS_PATH}/${match.params.id}/restaurantes/${id}`}
        className="font-medium"
      >
        {name}
      </Link>
    );
  };

  const ActiveCell = ({ value }) => {
    return <DotComponent status={value}></DotComponent>;
  };

  const columns = [
    {
      Header: "Estado",
      accessor: "active",
      Cell: ActiveCell,
    },
    {
      Header: "Nombre del restaurante",
      accessor: (values) => {
        return { id: values.id, name: values.text };
      },
      Cell: NameCell,
    },
    {
      Header: "Plan",
      accessor: (values) => {
        return `${values.subscription.plan.nickname} (${formatter.format(
          values.subscription.plan.amount / 100
        )})`;
      },
    },
  ];

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <LayoutV2 title={`${user.name} ${user.lastName}`} distributor>
      <div className="py-8 wrapper">
        <BackArrow to={DISTRIBUTOR_HOME_PATH} title="Atrás"></BackArrow>
        <div className="flex justify-between items-end text-gray-700">
          <div>
            <h2 className="text-2xl text-gray-800 font-bold">
              {`${user.name} ${user.lastName}`}
            </h2>
            <p className="text-sm mb-4">
              Se unió el{" "}
              {dayjs(user.created_at)
                .locale("es")
                .format("dddd D [de] MMMM [del] YYYY")}
            </p>
            <a href={`mailto:${user.email}`} className="text-blue-700 text-sm">
              <MdMail className="fill-current mr-1 inline-block w-4"></MdMail>
              {user.email}
            </a>
          </div>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <div>
          <h2 className="text-xl text-gray-800 font-bold">Restaurantes</h2>
          <SortableTable
            id={`user-${user.id}-restaurants`}
            data={user.restaurants}
            columns={columns}
          ></SortableTable>
        </div>
      </div>
    </LayoutV2>
  );
};

export default DistributorReferralPage;

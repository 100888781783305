import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./NewSubscriptionForm.css";
import ErrorComponent from "../ErrorComponent";
import PlanOption from "./PlanOption";
import PaymentMethodOption from "./PaymentMethodOption";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  createRestaurantSubscription,
  createRestaurantSubscriptionOxxoSpei,
  getPaymentMethods,
  newOrder,
  validateCoupon,
} from "../../api/User";

// Import paths
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_PAYMENT_METHOD_PATH,
  NEW_SUBSCRIPTION_PATH,
  INSTALL_PATH,
  PAYMENT_REFERENCE,
} from "../../constants/paths";
import {
  MENSUAL_OXXO_SPEI,
  TRIMESTRAL_OXXO_SPEI,
  SEMESTRAL_OXXO_SPEI,
  ANUAL_OXXO_SPEI,
  PROMO12,
  PROMO11,
  PROMO10,
  PROMO09,
  PROMO08,
  PROMO07,
  PROMO06,
  PROMO05,
  PROMO04,
  PROMO03,
  PROMO02,
  PROMO01,
} from "../../constants/plans";
import LoadingIndicator from "../LoadingIndicator";
import { validatePassword } from "../../api/Restaurant";
import NewSubscriptionModal from "./NewSubscriptionModal";
import NewPaymentMethodForm from "../methods/NewPaymentMethodForm";

window.Conekta.setPublicKey("key_Sud9prnqdFEs7x7YtYKMb7Q");

const passwordSchema = yup.object().shape({
  password: yup.string().required("Debes ingresar la contraseña."),
});

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del restaurante."),
  plan: yup.string().required("Selecciona un plan de pago"),
  coupon: yup
    .string()
    .test(
      "length",
      "El cupón debe contener exactamente 10 caracteres o estar en blanco.",
      (coupon) => {
        if (coupon === "" || !coupon || coupon.length === 10) return true;

        return false;
      }
    ),
});

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const RenewSubscriptionSpeiOxxoFormShareable = ({
  id,
  pm,
  conektaid,
  subscription,
  user_email,
}) => {
  // Variable for holding restaurant data
  const [restaurant, setRestaurant] = useState({});

  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [response, setResponse] = useState({});
  const history = useHistory();

  // Variable for success modal
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Instantiate restaurant form
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Variables for watching form attributes
  const plan = watch("plan");
  const method = watch("method");

  // Register method and plan fields
  useEffect(() => {
    register("plan");
    register("email");
  }, [register]);

  const selectMethod = (id) => {
    setValue("method", id, true);
    clearErrors("method");
  };

  const selectPlan = (id) => {
    setValue("plan", id, true);
    clearErrors("plan");
  };

  const onSubmit = async (data) => {
    // setLoading(true);

    if (pm === 2) {
      newOrder({
        title: data.title,
        plan: data.plan,
        customer_id: conektaid,
        card_token: "spei",
      })
        .then((response) => {
          setResponse(response.data);
          history.push({
            pathname: PAYMENT_REFERENCE,
            state: {
              data: {
                order: response.data.order,
              },
            },
          });
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(true);
          setError(error);
          console.log(error);
        });
    } else if (pm === 3) {
      newOrder({
        title: data.title,
        plan: data.plan,
        customer_id: conektaid,
        card_token: "oxxo_cash",
      })
        .then((response) => {
          setResponse(response.data);
          history.push({
            pathname: PAYMENT_REFERENCE,
            state: {
              data: {
                order: response.data.order,
              },
            },
          });
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(true);
          setError(error);
          console.log(error);
        });
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setShouldRedirect(true);
  };

  useEffect(() => {
    // Show a success popup if needed
    if (success && !id) {
      openModal();
    } else if (success) {
      setShouldRedirect(true);
    }
  }, [success, response]);

  if (loading) return <LoadingIndicator></LoadingIndicator>;
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <NewSubscriptionModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        license={response.key}
        restaurant={response.restaurant}
      ></NewSubscriptionModal>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Nombre del restaurante:
        </label>
        <input
          type="text"
          name="title"
          ref={register}
          className="input new-sub-input"
          defaultValue={subscription || ""}
          value={subscription}
        />
        <ErrorComponent error={errors.title}></ErrorComponent>
      </div>
      <div className="input-wrapper mt-4">
        <div className="mb-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Nombre del titular de la cuenta:
          </label>
          <input
            className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="name"
            ref={register}
            placeholder="Titular de la cuenta"
          />
        </div>

        <div className="mb-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Correo electrónico:
          </label>
          <input
            className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="email"
            ref={register}
            placeholder="correo@correo.com"
            defaultValue={user_email}
          />
        </div>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Plan de pagos
        </label>
        <div className="flex flex-wrap">
          <PlanOption
            active={
              plan !== TRIMESTRAL_OXXO_SPEI &&
              plan !== SEMESTRAL_OXXO_SPEI &&
              plan !== ANUAL_OXXO_SPEI &&
              plan !== undefined
            }
            title="Plan mensual"
            period="mes"
            price={349}
            id={MENSUAL_OXXO_SPEI}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === TRIMESTRAL_OXXO_SPEI}
            title="Plan trimestral"
            period="trimestre"
            price={979}
            id={TRIMESTRAL_OXXO_SPEI}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === SEMESTRAL_OXXO_SPEI}
            title="Plan semestral"
            period="semestre"
            price={1919}
            id={SEMESTRAL_OXXO_SPEI}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === ANUAL_OXXO_SPEI}
            title="Plan anual"
            period="año"
            price={3699}
            id={ANUAL_OXXO_SPEI}
            onClick={selectPlan}
            isLast
          ></PlanOption>
        </div>
        <ErrorComponent error={errors.plan}></ErrorComponent>
      </div>

      <button type="submit" className="button-main new-sub-btn">
        Renovar suscripción
      </button>
    </form>
  );
};

export default RenewSubscriptionSpeiOxxoFormShareable;

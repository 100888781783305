import React, { useEffect, useState } from "react";
import SignupForm from "../../../components/auth/forms/SignupForm";
import Layout from "../../../components/Layout";
import { getDistributor } from "../../../api/User";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignupPage = ({ location }) => {
  const [distributor, setDistributor] = useState(null);
  const loca = useLocation();
  const { t } = useTranslation();

  const email =
    loca.state !== null && loca.state !== undefined ? location.state.email : "";

  useEffect(() => {
    const distributorId = location.search?.split("?distribuidor=")[1];

    getDistributor(distributorId)
      .then((distributor) => {
        setDistributor(distributor);
      })
      .catch((error) => {});
  }, [location]);

  return (
    <LayoutV2 title="Regístrate" className="mx:px-0 px-4">
      <div className="login-main-container">
        <div className="login-form-card">
          <img
            src={require("../../../images/full-logo.svg")}
            alt="wmobil logo"
          />
          <h2 className="login-title">{t("signUpForm.title")}</h2>
          {distributor ? (
            <p className="mb-8 block text-center text-gray-500 font-normal">
              <bold className="text-gray-700 font-medium">
                {distributor.name} {distributor.lastName}
              </bold>{" "}
              {t("signUpForm.subtitleDist")}
            </p>
          ) : (
            <p className="mb-8 block text-center text-gray-500 font-normal">
              {t("signUpForm.subtitle")}
            </p>
          )}

          <SignupForm
            defaultEmail={email}
            distributor={distributor}
            referalId={distributor ? distributor.id : null}
          ></SignupForm>
        </div>
      </div>
    </LayoutV2>
  );
};

export default SignupPage;

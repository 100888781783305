import React from "react";

const ItemsPreview = ({ type, cheques, total, totalTips, subtotal }) => {
  const pricesPerItemArray = [];
  const totalPerItemArray = [];
  const isTypeCancel = type === "cancel";
  let affectedFolios = "Sin folio";

  if (isTypeCancel) {
    cheques[0].items.forEach((item) => {
      const cheqPrice = parseFloat(item.product.price); // * 1.16);
      pricesPerItemArray.push(cheqPrice);
      const cheqQty = parseFloat(item.quantity);
      totalPerItemArray.push(cheqPrice * cheqQty);
    });

    if (cheques[0].wf_numcheque.length > 0) {
      affectedFolios =
        cheques[0].wf_numcheque.length > 1
          ? `${cheques[0].wf_numcheque[0]} - ${cheques[0].wf_numcheque[cheques[0].wf_numcheque.length - 1]}`
          : `${cheques[0].wf_numcheque[0]}`;
    }
  }

  return (
    <>
      <p className="subtitle-text mb-4">Desglose de productos</p>
      <div className="custom-item-prev-container overflow-auto">
        <table class="w-full text-sm text-left">
          <thead class="text-md folios-table-bg sticky top-0">
            <tr>
              <th scope="col" class="px-6 py-3 rounded-tl-xl font-bold">
                Folio
              </th>
              <th scope="col" class="px-6 py-3 font-bold">
                Producto
              </th>
              <th scope="col" class="px-6 py-3 font-bold">
                Cantidad
              </th>
              <th scope="col" class="px-6 py-3 font-bold">
                Precio
              </th>
              <th scope="col" class="px-6 py-3 font-bold rounded-tr-xl ">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {cheques?.map((cheq) =>
              cheq.items?.map((item, index) => (
                <tr key={index} class="folios-table-bg">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-black whitespace-nowrap"
                  >
                    {isTypeCancel ? affectedFolios : cheq.wf_numcheque}
                  </th>
                  <td class="px-6 py-4 text-black">
                    {item.product.description}
                  </td>
                  <td class="px-6 py-4 text-black">{item.quantity}</td>
                  <td class="px-6 py-4 text-black">
                    $
                    {isTypeCancel
                      ? pricesPerItemArray[index].toFixed(2)
                      : item.product.price}
                  </td>
                  <td class="px-6 py-4 text-black">
                    $
                    {isTypeCancel
                      ? totalPerItemArray[index].toFixed(2)
                      : item.total}
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot className="sticky bottom-0 folios-table-bg">
            {totalTips > 0 && (
              <tr class="font-semibold text-gray-900 dark:text-white">
                <th scope="row" class="px-6 py-3">
                  Total propinas
                </th>
                <td />
                <td />
                <td />
                <td class="px-6 py-3">${totalTips}</td>
              </tr>
            )}

            {subtotal > 0 && (
              <tr class="font-semibold text-gray-900 dark:text-white">
                <th scope="row" class="px-6 py-2">
                  Total antes de impuestos
                </th>
                <td />
                <td />
                <td />
                <td class="px-6">${subtotal}</td>
              </tr>
            )}
            <tr class="font-semibold text-gray-900 dark:text-white">
              <th scope="row" class="px-6 py-2 text-base rounded-bl-xl">
                Monto total
              </th>
              <td />
              <td />
              <td />
              <td class="px-6 rounded-br-xl">${total}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default ItemsPreview;

import React, { useState, useEffect } from "react";

import "./ProfileNavbar.scss";
import WfacturasLinks from "./WfacturasLinks";

const InvoicesSidebar = ({ distributor, restaurant }) => {
  return <WfacturasLinks distributor={distributor} restaurant={restaurant} />;
};

export default InvoicesSidebar;

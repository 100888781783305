import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import "./TerminosyCondiciones.scss";
import { useTranslation } from "react-i18next";

const TerminosyCondiciones = ({ children }) => {
  const { t } = useTranslation();

  return (
    <LayoutV2 title="Inicia sesión" className="mx:px-0 px-4">
      <div className="terminos-main-container">
        <div className="terminos-form-card">
          <h2 className="terminos-title">{t("terms.title")}</h2>
          <br />
          <h5 className="terminos-text">{t("terms.term1")}</h5>
          <br />
          <h5 className="terminos-subtitle">{t("terms.subtitle")}</h5>
          <br />
          <h5 className="terminos-text"></h5>
          <br />
          <h5 className="terminos-text">{t("terms.term2")}</h5>
          <br />
          <h5 className="terminos-text">{t("terms.term3")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term4title")}</h5>
          <h5 className="terminos-text">{t("terms.term4")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term5title")}</h5>
          <h5 className="terminos-text">{t("terms.term5")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term6title")}</h5>
          <h5 className="terminos-text">{t("terms.term6")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term7title")}</h5>
          <h5 className="terminos-text">{t("terms.term7")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term8title")}</h5>
          <h5 className="terminos-text">{t("terms.term8")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term9title")}</h5>
          <h5 className="terminos-text"> {t("terms.term9")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term10title")}</h5>
          <h5 className="terminos-text">{t("terms.term10")}</h5>
          <br />
          <h5 className="terminos-subtitle2">{t("terms.term11title")}</h5>
          <h5 className="terminos-text">{t("terms.term11")}</h5>
        </div>
      </div>
    </LayoutV2>
  );
};

export default TerminosyCondiciones;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchDistributors, fetchRegularUsers } from "../../api/Admin";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";

import SortableTable from "../../components/SortableTable";

import {
  ADMIN_DISTRIBUTORS_PATH,
  ADMIN_NEW_DISTRIBUTOR_PATH,
} from "../../constants/adminPaths";

const AdminDistributorsPage = () => {
  const [distributors, setDistributors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurants once
    fetchDistributors()
      .then((usrs) => {
        setDistributors(usrs);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    // Get restaurants every 5 minutes
    const interval = setInterval(() => {
      fetchDistributors()
        .then((usrs) => {
          setDistributors(usrs);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, 1000 * 2 * 60);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (error) {
    return (
      <Layout title="Distribuidores">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <Layout title="Distribuidores" admin>
      <div className="py-8 wrapper">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl text-gray-800 font-bold">Distribuidores</h2>
          <Link
            to={ADMIN_NEW_DISTRIBUTOR_PATH}
            className="border px-4 py-2 rounded shadow-sm bg-gray-100 text-sm"
          >
            Nuevo distribuidor
          </Link>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <SortableTable
          id="admin-distributors-table"
          columns={columns}
          data={distributors}
        ></SortableTable>
      </div>
    </Layout>
  );
};

const NameCell = ({ value }) => {
  const { id, name } = value;
  return (
    <Link to={`${ADMIN_DISTRIBUTORS_PATH}/${id}`} className="font-medium">
      {name}
    </Link>
  );
};

const columns = [
  {
    Header: "Nombre",
    accessor: (values) => {
      return { id: values.id, name: `${values.name} ${values.lastName}` };
    },
    Cell: NameCell,
  },
  {
    Header: "Correo electrónico",
    accessor: "email",
  },
  {
    Header: "Porcentaje a Ingresos",
    accessor: (values) => {
      var tester = values.porcentajeIngresos;
      if (tester == null || tester == undefined || tester == "") {
        return "No definido";
      } else {
        return tester + " %";
      }
    },
  },
];

export default AdminDistributorsPage;

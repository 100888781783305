import axios from "axios";
import { API_PATH } from "../constants/api";

export const getGroup = (id) => {
  const token = localStorage.getItem("token");

  let group = {};

  return axios
    .get(`${API_PATH}/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      group = response.data.group;

      return axios.get(`${API_PATH}/groups/${id}/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    })
    .then((response) => {
      group.restaurants = response.data.restaurants;

      return group;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getAllGroups = () => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/groups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.groups;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getAllRestaurants = () => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/groups/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.restaurants;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const createGroup = (group) => {
  const token = localStorage.getItem("token");

  return axios
    .post(`${API_PATH}/groups`, group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.group_id;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const editGroup = (group_id, group) => {
  const token = localStorage.getItem("token");

  return axios
    .patch(`${API_PATH}/groups/${group_id}`, group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const deleteGroup = (id) => {
  const token = localStorage.getItem("token");

  return axios
    .delete(`${API_PATH}/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

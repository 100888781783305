import React, { useEffect, useState } from "react";
import "./MyAccountPage.css";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import WFacturasLayout from "../../components/WFacturasLayout";
import { APP_SEARCH_FOLIO_FACTURA } from "../../constants/paths";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllClientsFromRest,
  getFolioSettings,
  getInvoicesWithPartialPayment,
} from "../../api/Utils";
import sectionArrow from "../../images/sectionArrow.svg";
import LoadingIndicator from "../../components/LoadingIndicator";
import LegalDataForm from "../../components/account/LegalDataForm";

const LegalDataPage = ({ match }) => {
  const { rest_id } = match.params;
  const location = useLocation();
  const { nextPageSeries, nextPageInvoices, nextPageClients, selectedClient } =
    location.state || {};
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [series, setSeries] = useState([]);
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (nextPageClients) {
      setInvoices(nextPageInvoices);
      setClients(nextPageClients);
      setSeries(nextPageSeries);
      setLoading(false);
    } else {
      try {
        const fetchData = async () => {
          const invoices = await getInvoicesWithPartialPayment(rest_id);
          const allClients = await getAllClientsFromRest(rest_id);
          const settings = await getFolioSettings(rest_id);

          setInvoices(invoices);
          setClients(allClients);
          setSeries(settings.series);
          setLoading(false);
        };

        fetchData();
      } catch (error) {
        setLoading(false);
      }
    }
  }, []);

  return (
    <WFacturasLayout title="Mi cuenta" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <div className="p-8 w-full mt-12 md:mt-0">
          <div className="flex items-center font-medium text-xl collaborator-install-back-text-container text-green-800 mt-4">
            <span
              onClick={() => history.push(APP_SEARCH_FOLIO_FACTURA)}
              className="cursor-pointer"
            >
              Folios pendientes
            </span>
            <span>
              <img className="mx-4" src={sectionArrow} />
            </span>
            <span className="font-bold">Información legal</span>
          </div>
          <div className="flex flex-col">
            {loading ? (
              <LoadingIndicator />
            ) : (
              <LegalDataForm
                restId={rest_id}
                clients={clients}
                series={series}
                invoices={invoices}
                prevClient={selectedClient ? selectedClient : null}
              />
            )}
          </div>
        </div>
      </div>
    </WFacturasLayout>
  );
};

export default LegalDataPage;

import React, { useEffect, useState } from "react";
import BarChart from "../BarChart";
import { GroupsSalesDetailedCard } from "../components/GroupsSalesDetailedCard";
import DetailedCard from "../DetailedCard";
import DetailedCardMoreInfo from "../DetailedCardMoreInfo";

import PieChart from "../PieChart";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Online = ({ online, year, month, week, groupSales }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-2xl text-gray-700 font-bold">General</h3>
      <DetailedCard {...online}></DetailedCard>
      <DetailedCardMoreInfo {...online}></DetailedCardMoreInfo>
      <div className="flex flex-wrap md:flex-no-wrap">
        {online ? (
          <PieChart
            title={t("restPage.pieTitle")}
            customProperties={{
              animate: true,
              margin: { top: 30, right: 30, bottom: 30, left: 30 },
            }}
            data={[
              {
                id: "efectivo",
                label: t("restPage.pieLabel1"),
                value: parseFloat(online.efectivo),
              },
              {
                id: "tarjeta",
                label: t("restPage.pieLabel2"),
                value: parseFloat(online.tarjeta),
              },
              {
                id: "otros",
                label: t("restPage.pieLabel3"),
                value: parseFloat(online.otros),
              },
            ]}
          />
        ) : (
          <></>
        )}
        {online ? (
          <BarChart
            className="md:ml-4"
            title={t("restPage.chartTitle")}
            customProperties={{
              layout: "horizontal",
              fit: true,
              animate: true,
              margin: {
                top: 30,
                right: 30,
                bottom: 30,
                left: 50,
              },
              axisBottom: {
                format: function (value) {
                  return formatter.format(value);
                },
                tickValues: 5,
              },
            }}
            data={[
              /*  {
                id: "año",
                label: "Hoy, hace un año",
                value: parseFloat(year.total),
              }, */
              {
                id: t("restPage.chartLabel4"),
                label: t("restPage.chartLabel1"),
                //value: parseFloat(online.month),
                value: parseFloat(month.total),
              },
              {
                id: t("restPage.chartLabel5"),
                label: t("restPage.chartLabel2"),
                // value: parseFloat(online.week),
                value: parseFloat(week.total),
              },
              {
                id: t("restPage.chartLabel3"),
                label: t("restPage.chartLabel3"),
                value: parseFloat(online.total),
                //value: parseFloat(online.total),
              },
            ]}
          ></BarChart>
        ) : (
          <></>
        )}
      </div>
      {groupSales !== undefined && groupSales.length > 0 && (
        <GroupsSalesDetailedCard groupSales={groupSales} />
      )}
    </>
  );
};

export default Online;

import axios from "axios";
import { API_PATH } from "../constants/api";

export const signUp = (user) => {
  return axios
    .post(`${API_PATH}/users/register`, { ...user })
    .then((response) => {
      // localStorage.setItem("conektaId", response.data.conektaId);
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const signIn = (user) => {
  user["isPC"] = "true";
  return axios
    .post(`${API_PATH}/users/login`, { ...user })
    .then((response) => {
      localStorage.setItem("conektaId", response.data.conektaId);
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      error.message = "Fallo al iniciar sesión o ya hay una sesión activa";
      throw error;
    });
};

//SIGN A USER IN FROM ADMIN
export const signInFromAdmin = (user, admin) => {
  user["isPC"] = "true";
  return axios
    .post(`${API_PATH}/users/login-from-admin`, { ...user })
    .then((response) => {
      localStorage.setItem("conektaId", response.data.conektaId);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("adminSession", JSON.stringify(admin));
      window.dispatchEvent(new Event("storage"));

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      error.message = "Fallo al iniciar sesión o ya hay una sesión activa";
      throw error;
    });
};

export const signOut = (email) => {
  var user = { email: email };
  user["isPC"] = "true";

  return axios
    .post(
      `${API_PATH}/users/logout`,
      { ...user },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      localStorage.clear();
      window.dispatchEvent(new Event("storage"));

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchUserProfile = () => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return { ...response.data.user };
    })
    .catch((error) => {
      localStorage.removeItem("token");
      console.error(error);
      window.location.reload();
      throw error;
    });
};

import React, { forwardRef } from "react";
import WInput from "../WInput";

const PartialPaySectionsForm = forwardRef(({ sections, errors }, ref) => {
  return (
    <>
      {!sections.payor && (
        <>
          <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
            Datos del beneficiario
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 gap-6 gap-x-40 items-center">
            <div>
              <WInput
                mandatory
                type="text"
                ref={ref}
                label="RFC"
                name="payData.tax_id_payee"
              />
              <div className="error">
                {errors.payData?.tax_id_payee?.message}
              </div>
            </div>
            <div>
              <WInput
                mandatory
                type="digits"
                ref={ref}
                label="Número de cuenta"
                name="payData.acc_number_payee"
              />
              {errors.acc_number_payee && (
                <div className="error">Requerido</div>
              )}
            </div>
          </div>
        </>
      )}
      {!sections.payee && (
        <>
          <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
            Datos del ordenante
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 gap-6 gap-x-40 items-center">
            <div>
              <WInput
                mandatory
                type="text"
                ref={ref}
                label="RFC"
                name="payData.tax_id_payor"
              />
              <div className="error">
                {errors.payData?.tax_id_payor?.message}
              </div>
            </div>
            <div>
              <WInput
                mandatory
                type="digits"
                ref={ref}
                label="Número de cuenta"
                name="payData.acc_number_payor"
              />
              {errors.acc_number_payor && (
                <div className="error">Requerido</div>
              )}
            </div>
            <div>
              <WInput
                mandatory
                type="text"
                ref={ref}
                label="Nombre del banco"
                name="payData.bank"
              />
              {errors.bank && <div className="error">Requerido</div>}
            </div>
          </div>
        </>
      )}
      {!sections.transfer && (
        <>
          <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
            Datos del certificado de pago
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center">
            <div>
              <WInput
                mandatory
                type="text-big"
                ref={ref}
                label="Certificado de pago"
                name="payData.pay_certificate"
              />
              {errors.pay_certificate && <div className="error">Requerido</div>}
            </div>
            <div>
              <WInput
                mandatory
                type="text-big"
                ref={ref}
                label="Cadena de pago"
                name="payData.pay_link"
              />
              {errors.pay_link && <div className="error">Requerido</div>}
            </div>
            <div>
              <WInput
                mandatory
                type="text-big"
                ref={ref}
                label="Sello de pago"
                name="payData.pay_signature"
              />
              {errors.pay_signature && <div className="error">Requerido</div>}
            </div>
            <div>
              <WInput
                mandatory
                readOnly
                ref={ref}
                label="Tipo de pago"
                name="payData.pay_type"
              />
              {errors.pay_type && <div className="error">Requerido</div>}
            </div>
          </div>
        </>
      )}
    </>
  );
});
export default PartialPaySectionsForm;

import React from "react";
import { Link } from "react-router-dom";
import { MY_SUBSCRIPTIONS_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

const SubscriptionPaymentPending = ({
  restaurant_title,
  activated,
  restaurant_id,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Link
        to={`${MY_SUBSCRIPTIONS_PATH}/${restaurant_id}`}
        className={`flex w-full p-4 bg-white shadow-md items-center justify-between rounded border mb-4 highlighted-row`}
      >
        <div className="flex items-center">
          <div
            className={`w-2 h-2 rounded-full mr-2 ${
              activated == 1 ? "bg-green-300" : "bg-red-300"
            }`}
          ></div>
          <p className="title">{restaurant_title}</p>
        </div>
        <p>{t("restPage.subPay")}</p>
      </Link>
    </>
  );
};

export default SubscriptionPaymentPending;

import React from "react";
import "./PlanOption.css";

const PlanOption = ({ onClick, id, title, period, price, active, isLast }) => {
  return (
    <div
      className={`select-plan mb-4 flex justify-between ${
        active ? "active" : ""
      } ${isLast ? "" : "mb-0"}`}
      onClick={() => onClick(id)}
      style={{ borderRadius: "10px", padding: "4px" }}
    >
      <h3 className="font-bold text-md">{title}</h3>
      <p className="text-black-700 flex items-baseline">
        <span className="text-xs pr-1 font-bold plan-option-price">$</span>
        <span className="text-2xl font-bold plan-option-price">{price}</span>
        <span className="text-xs pl-1">{price === 1040 ? "USD" : "MXN"}</span>
      </p>
    </div>
  );
};

export default PlanOption;

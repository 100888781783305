import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SortFoliosTable from "../../SortFoliosTable";
import { Checkbox } from "@mui/material";
import WInput from "../../WInput";
import WFacturasActionModal from "../../modals/WFacturasActionModal";
import {
  cancelFactura,
  getFacturaPdf,
  sendFacturaByEmail,
} from "../../../api/Utils";
import { useToast } from "../../../context/ToastContext";
import WCancelAndInvoice from "../../WCancelAndInvoice";
import { createFactura, getInvoiceData } from "../../../api/User";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const InvoicesTable = ({
  invoices,
  restId,
  onSelectionChange,
  selectedInvoices,
  fetchData,
  series,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [selectAll, setSelectAll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredCheques, setFilteredCheques] = useState(invoices);
  const [modalData, setModalData] = useState([]);
  const [openCancelWithMotive, setOpenCancelWithMotive] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [motive, setMotive] = useState("no");
  const [currPage, setCurrPage] = useState(0);
  const [desc, setDesc] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleCheckboxChange = (id) => {
    onSelectionChange((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleOpenModal = (type, id, folio) => {
    const isRebill = type === "rebill";

    const getInvoiceEmail = async () => {
      setModalLoading(true);
      const invoiceData = await getInvoiceData(id, restId);
      setUserEmail(invoiceData.customer.email);
      setModalLoading(false);
    };

    if (type === "send") getInvoiceEmail();

    setModalData({
      type: type,
      id: id,
      folio: folio,
    });

    if (isRebill) {
      setOpenCancelWithMotive(true);
    } else {
      setOpenModal(true);
    }
  };

  const initializeAction = (value) => {
    switch (modalData.type) {
      case "cancel":
        if (value === "01") {
          setMotive(value);
          setOpenModal(false);
          setOpenCancelWithMotive(true);
        } else {
          setModalData((prevModalData) => ({
            ...prevModalData,
            motive: value,
          }));
        }
        break;
      case "download":
        setModalData((prevModalData) => ({
          ...prevModalData,
          download: value,
        }));
        break;
      case "send":
        setModalData((prevModalData) => ({
          ...prevModalData,
          email: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleCreateInvoice = async (data) => {
    try {
      setLoading(true);
      const newInvoice = await createFactura(data);
      setLoading(false);

      if (modalData.type === "rebill") {
        showToast("Factura generada con éxito", "success");
        setOpenCancelWithMotive(false);
        fetchData(restId);
      }

      return newInvoice;
    } catch (error) {
      showToast("Error al generar su factura, verifique sus datos", "error");
      setLoading(false);
      throw error;
    }
  };

  const handleCancel = async (data) => {
    try {
      setLoading(true);
      const { invoiceId, motive, uuid, newInvId } = data;
      await cancelFactura(invoiceId, motive, restId, uuid, newInvId);
      setLoading(false);
      showToast("Factura cancelada correctamente", "success");
      setOpenCancelWithMotive(false);
      fetchData(restId);
      return;
    } catch (error) {
      showToast("Error al cancelar la factura", "error");
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (modalData.type === "cancel" && modalData.motive) {
      handleModalAction(modalData);
    } else if (modalData.type === "download" && modalData.download) {
      handleModalAction(modalData);
    } else if (modalData.type === "send" && modalData.email) {
      handleModalAction(modalData);
    }
  }, [modalData]);

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    onSelectionChange(() => {
      if (!selectAll) {
        const filteredInvoices = invoices.filter(
          (invoice) =>
            selectedStatus === "all" || invoice.status === selectedStatus
        );
        return filteredInvoices.map((invoice) => invoice.id);
      } else {
        return [];
      }
    });
  };

  const handleSort = (col) => {
    const sortedInvoices = [...invoices].sort((a, b) => {
      if (desc) {
        return new Date(b[col]) - new Date(a[col]);
      } else {
        return new Date(a[col]) - new Date(b[col]);
      }
    });

    setFilteredCheques(sortedInvoices);
    setDesc((prev) => !prev);
  };

  const columns = [
    {
      id: "Cancelar",
      Header: (
        <div className="flex items-center justify-center">
          <span onClick={handleSelectAll}>
            <Checkbox checked={selectAll} />
          </span>
          <div className="arrow-down" onClick={handleSelectAll}>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      ),
      accessor: (values) => {
        return (
          <Checkbox
            checked={selectedInvoices.includes(values.id)}
            onChange={() => handleCheckboxChange(values.id)}
          />
        );
      },
    },
    {
      Header: "Folio factura",
      accessor: (values) => {
        const folioWithSerie = values.series + values.folio_number;
        return folioWithSerie;
      },
    },
    { Header: "Folio ticket", accessor: "folio_ticket" },
    {
      id: "created_at",
      Header: (
        <div
          className="cursor-pointer"
          onClick={() => handleSort("created_at")}
        >
          Fecha creado
        </div>
      ),
      accessor: (values) => {
        const formattedFecha = values.created_at
          .replace("T", " ")
          .split(".")[0];
        return formattedFecha;
      },
    },
    {
      id: "date",
      Header: (
        <div className="cursor-pointer" onClick={() => handleSort("date")}>
          Fecha factura
        </div>
      ),
      accessor: (values) => {
        const formattedFecha = values.date.split("T")[0];
        return formattedFecha;
      },
    },
    {
      Header: "Razón social",
      accessor: "customer.legal_name",
    },
    {
      Header: "RFC",
      accessor: "customer.tax_id",
    },
    {
      Header: "Estatus",
      accessor: (values) => {
        const statusValid = values.status === "valid";
        const cancelStatusPending = values.cancellation_status === "pending";

        const isValid = statusValid && !cancelStatusPending;
        const isCanceled = !statusValid;

        const status = isValid
          ? "Válida"
          : isCanceled
            ? "Cancelada"
            : "Pendiente";

        return (
          <div
            className={`font-semibold ${
              isValid
                ? "text-green-700"
                : isCanceled
                  ? "text-red-700"
                  : "text-yellow-600"
            } `}
          >
            {status}
          </div>
        );
      },
    },
    {
      Header: "Monto",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number" ? values.total : parseFloat(values.total)
        );
      },
    },
    {
      Header: "Acciones",
      accessor: (values) => {
        return (
          <div className="flex w-full p-2 items-center justify-around">
            {values.status !== "canceled" && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOpenModal("cancel", values.id, values.folio_number);
                }}
              >
                <img
                  src={require("../../../images/cancel-action-button.svg")}
                />
              </div>
            )}
            <div
              className="cursor-pointer"
              onClick={() =>
                handleOpenModal("download", values.id, values.folio_number)
              }
            >
              <img
                src={require("../../../images/download-action-button.svg")}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() =>
                handleOpenModal("send", values.id, values.folio_number)
              }
            >
              <img src={require("../../../images/send-action-button.svg")} />
            </div>
            {values.status !== "valid" && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOpenModal("rebill", values.id, values.folio_number);
                }}
              >
                <img
                  src={require("../../../images/rebill-action-button.svg")}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleSelectedFilterType = (type) => {
    setSelectedStatus(type);
  };

  //This function is in charge of calling the action button type
  const handleModalAction = async (values) => {
    setModalLoading(true);
    switch (values.type) {
      case "cancel":
        try {
          await cancelFactura(values.id, values.motive, restId);
          showToast("Factura cancelada con éxito", "success");
          fetchData(restId);
          return;
        } catch (error) {
          showToast(
            "Error al cancelar factura, intente de nuevo mas tarde",
            "error"
          );
        } finally {
          setModalLoading(false);
          return;
        }
      case "send":
        try {
          await sendFacturaByEmail(restId, values.id, values.email);
          showToast("Factura enviada con éxito", "success");
          return;
        } catch (error) {
          showToast(
            "Error al enviar factura, intente de nuevo mas tarde",
            "error"
          );
        } finally {
          setModalLoading(false);
          return;
        }
      case "download":
        try {
          await getFacturaPdf(restId, values.id, values.download, values.folio);
          showToast("Factura descargada con éxito", "success");
        } catch (error) {
          showToast(
            "Error al descargar factura, intente de nuevo mas tarde",
            "error"
          );
        } finally {
          setModalLoading(false);
          return;
        }
      default:
        return null;
    }
  };

  useEffect(() => {
    const getNestedValue = (obj, path) => {
      return path.split(".").reduce((value, key) => value && value[key], obj);
    };
    const filtered = invoices.filter((cheque) => {
      const includesSearch = [
        "folio_number",
        "folio_ticket",
        "customer.legal_name",
        "customer.tax_id",
        "total",
        "status",
        "use",
        "series",
      ].some((key) => {
        const value = getNestedValue(cheque, key);
        if (value === undefined || value === null) return false;
        return String(value).toLowerCase().includes(searchValue.toLowerCase());
      });

      const matchesStatus =
        selectedStatus === "all" ||
        cheque.status.toLowerCase() === selectedStatus ||
        cheque.series.toLowerCase() === selectedStatus.toLowerCase();

      return includesSearch && matchesStatus;
    });

    setFilteredCheques(filtered);
  }, [selectedStatus, searchValue]);

  return (
    <div>
      <WFacturasActionModal
        loading={modalLoading}
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        modalData={modalData}
        initializeAction={(value) => initializeAction(value)}
        userEmail={userEmail}
      />
      {modalData.id && (
        <WCancelAndInvoice
          restId={restId}
          modalData={modalData}
          motive={motive}
          series={series}
          setUserEmail={(data) => setUserEmail(data)}
          openCancelWithMotive={openCancelWithMotive}
          handleClose={() => setOpenCancelWithMotive(false)}
          handleCancel={(data) => handleCancel(data)}
          handleCreateInvoice={(data) => handleCreateInvoice(data)}
          loading={loading}
        />
      )}
      <div className="flex items-center justify-end mb-4">
        <div className="w-full">
          {selectedInvoices.length > 0 && (
            <label className="wfacturas-table-text">
              {selectedInvoices.length} de {invoices.length}
            </label>
          )}
        </div>
        <div className="flex flex-col mr-8 justify-center">
          <div className="inline-block relative">
            <select
              className="outline-none"
              value={selectedStatus}
              onChange={(e) => handleSelectedFilterType(e.target.value)}
            >
              <option value="all">Todas</option>
              <option value="valid">Válidas</option>
              <option value="canceled">Canceladas</option>
              <option value="f">Serie F</option>
              {series?.map((serie) => (
                <option key={serie.id} value={serie.serie_id}>
                  Serie {serie.serie_id}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <WInput
            type="search"
            label="Buscar"
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <SortFoliosTable
        actualPage={currPage}
        setCurrPage={setCurrPage}
        columns={columns}
        data={filteredCheques}
      />
    </div>
  );
};

export default InvoicesTable;

import React from "react";
import { useTranslation } from "react-i18next";
import WSelect from "../WSelect";

const RegimenSelect = ({
  register,
  defaultValue = "no",
  name,
  type,
  system,
}) => {
  const { t } = useTranslation();
  const persona = system === "persona";
  const both = system === "both";
  return (
    <div className="relative">
      <WSelect
        name={name}
        ref={register}
        label="Regimen fiscal"
        defaultValue={defaultValue}
        type={type}
      >
        <option value="no">Régimen fiscal</option>
        {(!persona || both) && (
          <>
            <option value="601">601 - General de Ley Personas Morales</option>
            <option value="603">
              603 - Personas Morales con Fines no Lucrativos
            </option>
          </>
        )}
        {(persona || both) && (
          <option value="605">
            605 - Sueldos y Salarios e Ingresos Asimilados a Salarios
          </option>
        )}
        {(persona || both) && <option value="606">606 - Arrendamiento</option>}
        {(!persona || both) && (
          <option value="607">
            607 - Régimen de Enajenación o Adquisición de Bienes
          </option>
        )}
        {(persona || both) && <option value="608">608 - Demás ingresos</option>}
        {(!persona || both) && <option value="609">609 - Consolidación</option>}
        {(persona || both) && (
          <option value="610">
            610 - Residentes en el Extranjero sin Establecimiento Permanente en
            México
          </option>
        )}
        {(persona || both) && (
          <option value="611">
            611 - Ingresos por Dividendos (socios y accionistas)
          </option>
        )}
        {(persona || both) && (
          <option value="612">
            612 - Personas Físicas con Actividades Empresariales y Profesionales
          </option>
        )}
        {(persona || both) && (
          <option value="614">614 - Ingresos por intereses</option>
        )}
        {(persona || both) && (
          <option value="615">
            615 - Régimen de los ingresos por obtención de premios
          </option>
        )}
        {(persona || both) && (
          <option value="616">616 - Sin obligaciones fiscales</option>
        )}
        {(!persona || both) && (
          <option value="620">
            620 - Sociedades Cooperativas de Producción que optan por diferir
            sus ingresos
          </option>
        )}
        {(persona || both) && (
          <option value="621">621 - Incorporación Fiscal</option>
        )}
        {(persona || both) && (
          <option value="622">
            622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
          </option>
        )}
        {(!persona || both) && (
          <>
            <option value="623">
              623 - Opcional para Grupos de Sociedades
            </option>
            <option value="624">624 - Coordinados</option>
          </>
        )}
        {(persona || both) && (
          <option value="625">
            625 - Régimen de las Actividades Empresariales con ingresos a través
            de Plataformas Tecnológicas
          </option>
        )}
        {(persona || both) && (
          <option value="626">626 - Régimen Simplificado de Confianza</option>
        )}
        {(!persona || both) && <option value="628">628 - Hidrocarburos</option>}
        {(persona || both) && (
          <option value="629">
            629 - De los Regímenes Fiscales Preferentes y de las Empresas
            Multinacionales
          </option>
        )}
        {(persona || both) && (
          <option value="623">
            623 - Enajenación de acciones en bolsa de valores
          </option>
        )}
      </WSelect>
    </div>
  );
};

export default RegimenSelect;

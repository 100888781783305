import React, { forwardRef } from "react";
import fileIcon from "../images/fileIcon.svg";

const WFile = ({ label, name, disabled, className, onClick, value }) => {
  return (
    <div className="relative flex" onClick={onClick}>
      <span className={className}>{value || "Seleccionar archivo"}</span>
      <label className="wfacturas-input-label">{label}</label>
      <div className="wfacturas-file-icon cursor-pointer">
        <img src={fileIcon} alt="custom-image" />
      </div>
    </div>
  );
};

export default WFile;

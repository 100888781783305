import React, { useState } from "react";
import Select from "../../Select";
import SortableTable from "../../SortableTable";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DiscountsAndCancellations = ({ cancelledProducts, restaurantId }) => {
  const { t } = useTranslation();

  const options = [
    {
      value: "cancelledProducts",
      title: t("dashboard.dropdownOps.discTableDropdown1"),
    },
  ];

  const [option, setOption] = useState("cancelledProducts");

  const changeOption = (e) => {
    setOption(e.currentTarget.value);
  };

  const columns = [
    { Header: t("restPage.discountHeader1"), accessor: "clave" },
    {
      Header: t("restPage.discountHeader2"),
      accessor: "cantidad",
    },
    { Header: t("restPage.discountHeader3"), accessor: "producto" },
    {
      Header: t("restPage.discountHeader4"),
      accessor: (values) => {
        var format = values.razon;
        if (
          (format == "") |
          (format == null) |
          (format == undefined) |
          (format == " ")
        ) {
          return t("restPage.accTableNoFol");
        } else {
          return format;
        }
      },
    },
    {
      Header: t("restPage.discountHeader5"),
      accessor: (values) => {
        var nombre = values.usuariocancelo;
        if (
          nombre == "" ||
          nombre == null ||
          nombre == undefined ||
          nombre == " " ||
          nombre == 0 ||
          nombre === 0 ||
          nombre == "0" ||
          nombre === "0"
        ) {
          return t("restPage.accTableNoFol");
        } else {
          return nombre;
        }
      },
    },
    {
      Header: t("restPage.discountHeader6"),
      accessor: (values) => {
        var format = values.fecha;
        format = format.replace("T", " / ");
        format = format.slice(0, -5);
        return format;
      },
    },
    {
      Header: t("restPage.discountHeader7"),
      accessor: (values) => {
        return formatter.format(
          typeof values.precio === "number"
            ? values.precio
            : parseFloat(values.precio)
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between w-full">
        <h3 className="text-2xl text-gray-700 font-bold">
          {t("restPage.discountTitle")}
        </h3>
        <Select options={options} onChange={changeOption}></Select>
      </div>
      {option === "cancelledProducts" && cancelledProducts ? (
        <SortableTable
          columns={columns}
          data={cancelledProducts}
          id="cancelled-products"
        ></SortableTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default DiscountsAndCancellations;

import React, { useState, useEffect } from "react";
import ProfileUserLinks from "./ProfileUserLinks";

import "./ProfileNavbar.scss";

const ProfileNavbar = ({ admin, distributor, restaurant }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShow(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return show ? (
    // <div className="dropdown-menu-account h-full">
    <ProfileUserLinks
      admin={admin}
      distributor={distributor}
      restaurant={restaurant}
    />
  ) : // </div>
  null;
};

export default ProfileNavbar;

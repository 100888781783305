import React from "react";

export function GroupsSalesDetailedCard({ groupSales }) {
  return (
    <div className="detail-card-more-info-container">
      {groupSales.map((groupSale, i) => {
        return (
          <div className="card" key={"group-sale-card-" + i}>
            <p className="">{groupSale.grupo}: </p>
            <p>
              <span className="text-lg font-medium ">
                {"$ " + groupSale.venta_total.toFixed(2) + " "}
              </span>
              MXN
            </p>
          </div>
        );
      })}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  APP_SEARCH_FOLIO_FACTURA,
  APP_WFACTURAS_PLANS,
} from "../../constants/paths";
import WInput from "../WInput";
import PlanCardPay from "../PlanCardPay";
import { useToast } from "../../context/ToastContext";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cancelFacturacionSubscription,
  createFacturacionSubscription,
  newRestaurantUser,
} from "../../api/User";
import { fetchUserProfile } from "../../api/Auth";

const paymentSchema = yup.object().shape({
  restName: yup.string().optional("Requerido"),
  cardNumber: yup.string().required("Requerido"),
  name: yup.string().required("Requerido"),
  exp: yup.string().required("Requerido"),
  cvc: yup.string().required("Requerido"),
});

const PlansSubscribe = ({ plan, restId, planType, active, monthly }) => {
  restId = restId !== "null" ? restId : null;
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [cardNo, setCradNo] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userId = 1057;
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(paymentSchema),
  });

  const onSubmit = (formData) => {
    setLoading(true);
    handleConektaSubmit(formData);
  };

  const handleConektaSubmit = async (data) => {
    const planPrices = {
      150: monthly ? 499 : 5099,
      300: monthly ? 759 : 7749,
      1000: monthly ? 999 : 9590,
      UNL: monthly ? 999 : 9590,
    };
    const subtotal = parseFloat(planPrices[plan]);
    const total = parseFloat(subtotal * 116);

    const card = data.cardNumber.replace(/\s/g, "");
    const last4 = card.substring(card.length - 4);
    const tokenParams = {
      card: {
        number: data.cardNumber,
        name: data.name,
        exp_year: data.exp.split("/")[1],
        exp_month: data.exp.split("/")[0],
        cvc: data.cvc,
      },
    };
    const selectedPlan = monthly ? `PLAN_${plan}_MONTH` : `PLAN_${plan}_YEAR`;

    return window.Conekta.Token.create(
      tokenParams,
      async (card) => {
        if (!restId) {
          const usrData = await fetchUserProfile();
          const res = await newRestaurantUser(usrData, data.restName, 0);
          if (res.status === 200) {
            try {
              createSubIfRestPresent(card, monthly, total, last4, selectedPlan);
            } catch (error) {
              showToast(
                "No se pudo crear el resaturante, intente de nuevo mas tarde",
                "error"
              );
              history.poush(APP_WFACTURAS_PLANS);
            }
          }
        } else {
          createSubIfRestPresent(card, monthly, total, last4, selectedPlan);
        }
      },
      errorCallback
    );
  };

  const createSubIfRestPresent = (
    card,
    monthly,
    total,
    last4,
    selectedPlan
  ) => {
    createFacturacionSubscription({
      card_token: card.id,
      rest_id: restId,
      selected_plan: selectedPlan,
      last_four: last4,
      price: total,
      periodicity: monthly ? "month" : "year",
    })
      .then((response) => {
        showToast(`Success`, "success");
        setLoading(false);

        setTimeout(() => {
          history.push(APP_SEARCH_FOLIO_FACTURA);
        }, 1500);
      })
      .catch((error) => {
        showToast(
          "Error al procesar tu pago, intente de nuevo mas tarde",
          "error"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    register("number");
    register("exp_year");
    register("exp_month");
    register("cvc");
    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_LK);
  }, [register]);

  const successCallback = (data) => {
    const token = localStorage.getItem("token");
    const customerId = `cus_${userId}`;
  };

  const errorCallback = (data) => {
    alert(
      "Alguno de los datos de la tarjeta es inválido. Revisa la información y vuelve a intentarlo"
    );
    setLoading(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    setCradNo(inputValue);
  };

  const handleExpChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2);
    }

    inputValue = inputValue.slice(0, 5);

    setExpDate(inputValue);
  };

  const handleCvcChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");

    inputValue = inputValue.slice(0, 3);

    setCvc(inputValue);
  };

  const handleCancelSubscription = async () => {
    if (
      window.confirm(
        `¿Seguro que desea cancelar su suscripción: PLAN_${planType}?`
      )
    ) {
      try {
        await cancelFacturacionSubscription({
          rest_id: restId,
        });
        showToast("Suscripción cancelada", "success");
        setLoading(false);

        setTimeout(() => {
          history.push(APP_SEARCH_FOLIO_FACTURA);
        }, 1500);
      } catch (error) {
        showToast("Error al cancelar la suscripción", "error");
      }
    }
  };

  const formatInput = (value) => {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.join(" ");
  };

  return (
    <div className="p-8 mt-10 md:mt-0 w-full">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col md:flex-row items-center justify-between mb-6 ">
            <div className="flex items-center font-medium text-xl collaborator-install-back-text-container text-green-800 mt-4">
              <span
                onClick={() => history.push(APP_WFACTURAS_PLANS)}
                className="cursor-pointer"
              >
                Tienda de timbres
              </span>
              <span>
                <svg
                  className="h-5 w-5 mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <span className="font-bold">Datos de pago</span>
            </div>
            <div className="flex w-full md:w-1/4 items-center justify-start md:justify-end">
              <button
                onClick={() => history.push(APP_WFACTURAS_PLANS)}
                className="wfacturas-button-outline-green"
              >
                Seleccionar otro plan
              </button>
            </div>
          </div>
          <text className="text-md">
            Complete la compra de timbres ingresando un método de pago
          </text>
          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="w-full md:w-1/2">
              {!restId && (
                <div>
                  <label className="text-2xl mb-4 font-semibold text-green-800 mt-5 block">
                    Crea un restaurante
                  </label>
                  <div className="grid grid-cols-1 grid-rows-1 gap-x-10 gap-y-8">
                    <div>
                      <WInput
                        name="restName"
                        ref={register}
                        label="Nombre de la sucursal"
                        placeHolder="Mi restuarante"
                      />
                      {errors.cardNumber && (
                        <div className=" text-red-600 py-1 text-sm">
                          {errors.cardNumber.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <label className="text-2xl mb-4 font-semibold text-green-800 mt-5 block">
                  Datos de cuenta
                </label>
                <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-8">
                  <div>
                    <WInput
                      type="digits"
                      name="cardNumber"
                      ref={register}
                      label="Numero en la tarjeta"
                      placeHolder="1234 5678 9101 1121"
                      value={formatInput(cardNo)}
                      onChange={handleChange}
                    />
                    {errors.cardNumber && (
                      <div className=" text-red-600 py-1 text-sm">
                        {errors.cardNumber.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <WInput
                      type="digits"
                      name="exp"
                      ref={register}
                      label="Fecha de expiración"
                      placeHolder="07/28"
                      value={expDate}
                      onChange={handleExpChange}
                    />
                    {errors.exp && (
                      <div className=" text-red-600 py-1 text-sm">
                        {errors.exp.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <WInput
                      name="name"
                      ref={register}
                      label="Nombre en la tarjeta"
                      placeHolder="Nombre del titular"
                    />
                    {errors.name && (
                      <div className=" text-red-600 py-1 text-sm">
                        {errors.name.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <WInput
                      type="digits"
                      name="cvc"
                      ref={register}
                      label="CVC"
                      placeHolder="***"
                      value={cvc}
                      onChange={handleCvcChange}
                    />
                    {errors.cvc && (
                      <div className=" text-red-600 py-1 text-sm">
                        {errors.cvc.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {active && (
                <div className="mt-4">
                  <span
                    onClick={handleCancelSubscription}
                    className="text-red-600 py-1 text-sm cursor-pointer"
                  >
                    Cancelar suscripción
                  </span>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/4 mt-8 md:mt-0">
              <PlanCardPay
                plan={plan}
                loading={loading}
                active={active}
                monthly={monthly}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PlansSubscribe;

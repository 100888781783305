import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchDistributorsPayments, fetchRegularUsers } from "../../api/Admin";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";

import SortableTable from "../../components/SortableTable";

import { ADMIN_DISTRIBUTORS_PATH } from "../../constants/adminPaths";

const AdminPaymentsPage = () => {
  const [distributors, setDistributors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurants once
    fetchDistributorsPayments()
      .then((usrs) => {
        setDistributors(usrs);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    // Get restaurants every 5 minutes
    const interval = setInterval(() => {
      fetchDistributorsPayments()
        .then((usrs) => {
          setDistributors(usrs);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, 1000 * 2 * 60);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (error) {
    return (
      <Layout title="Distribuidores">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <Layout title="Distribuidores" admin>
      <div className="py-8 wrapper">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl text-gray-800 font-bold">
            Pagos a distribuidores
          </h2>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <SortableTable
          id="admin-distributors-table"
          columns={columns}
          data={distributors}
        ></SortableTable>
      </div>
    </Layout>
  );
};

const NameCell = ({ value }) => {
  const { id, name } = value;
  return (
    <Link to={`${ADMIN_DISTRIBUTORS_PATH}/${id}`} className="font-medium">
      {name}
    </Link>
  );
};

const columns = [
  {
    Header: "Distribuidor (CLABE)",
    accessor: (values) => {
      var clabe = values.clabe;
      if (clabe == null || clabe == undefined || clabe == "") {
        clabe = "Aún no definida";
      }

      return {
        id: values.id,
        name: `${values.name} ${values.lastName}  ${"(CLABE: " + clabe + ")"}`,
      };
    },
    Cell: NameCell,
  },
  {
    Header: "Suscripciones activas (Detalle)",
    accessor: (values) => {
      var dato = values.licenciasActivas;
      var mes = values.suscripcionesMensuales;
      var tri = values.suscripcionesTrimestrales;
      var sem = values.suscripcionesSemestrales;
      var ano = values.suscripcionesAnuales;

      if (dato == null || dato == undefined || dato == "") {
        return 0;
      } else {
        return (
          dato +
          "    " +
          "(Mes: " +
          mes +
          ", Trimes: " +
          tri +
          ", Semest: " +
          sem +
          ", Año :" +
          ano +
          ")"
        );
      }
    },
  },
  {
    Header: "Usuarios (Restaurantes)",
    accessor: (values) => {
      var dato = values.totalUsuarios;

      var res = values.licenciasRegistradas;
      if (res == null || res == undefined || res == "") {
        res = 0;
      }

      if (dato == null || dato == undefined || dato == "") {
        return 0;
      } else {
        return dato + "    (" + res + ")";
      }
    },
  },

  {
    Header: "Ingresos al mes",
    accessor: (values) => {
      var ingreso = values.ingresoTotalMensual;
      if (ingreso == null || ingreso == undefined || ingreso == "") {
        return "No disponible";
      } else {
        return parseFloat(ingreso).toFixed(2) + " MXN";
      }
    },
  },

  {
    Header: "Pagar la semana",
    accessor: (values) => {
      var pagar = values.ingresoTotalSemanal;
      var porcentaje = values.porcentajeIngresos;
      if (pagar == null || pagar == undefined || pagar == "") {
        return "No definido";
      } else {
        if (porcentaje == null) {
          porcentaje = 1;
          return parseFloat(pagar * porcentaje).toFixed(2) + " MXN";
        } else {
          return parseFloat(pagar * (porcentaje / 100)).toFixed(2) + " MXN";
        }
      }
    },
  },
  {
    Header: "Porcentaje a ingresos",
    accessor: (values) => {
      var pi = values.porcentajeIngresos;
      if (pi == null || pi == undefined || pi == "") {
        return "No definido";
      } else {
        return pi + " %";
      }
    },
  },
];

export default AdminPaymentsPage;

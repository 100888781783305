import React from "react";
import WInput from "../WInput";
import WDropdown from "../WDropdown";
import RegimenSelect from "./RegimenSelect";
import ErrorComponent from "../ErrorComponent";
import WSelect from "../WSelect";

const publicoEnGeneral = {
  legal_name: "Público en general",
  tax_id: "XAXX010101000",
  tax_system: "616",
  use: "S01",
};

const LegalDataInputForm = ({
  publicoGral,
  register,
  filteredLegalName,
  filteredTaxid,
  selectedClient,
  errors,
  evaluateTaxId,
  handleClientSearch,
  setSelectedClient,
  type,
}) => {
  const isTypeCancel = type === "cancel";
  const gridStyle = isTypeCancel
    ? "grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-6 gap-x-6 items-center"
    : "grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center";
  const minDate = new Date();
  const year = minDate.getFullYear();
  const prevYear = year - 1;
  minDate.setHours(minDate.getHours() - 72);

  return (
    <>
      <div className={gridStyle}>
        {isTypeCancel && (
          <div className="relative w-full">
            <WSelect
              mandatory
              type="text"
              ref={register}
              label="Tipo de comprobante"
              name="type"
            >
              <option value="I">Ingreso</option>
              <option value="E">Egreso</option>
            </WSelect>
            {errors.tax_id && <div className="error">Requerido</div>}
          </div>
        )}
        <div className="relative w-full">
          <WInput
            mandatory
            type="text"
            ref={register}
            label="RFC"
            name="tax_id"
            onChange={
              !isTypeCancel ? (e) => evaluateTaxId(e.target.value) : null
            }
            defaultValue={publicoGral ? publicoEnGeneral.tax_id : ""}
          />
          {filteredTaxid?.length > 0 && !selectedClient && (
            <WDropdown
              filteredLegalName={filteredTaxid}
              setSelectedClient={(val) => setSelectedClient(val)}
            />
          )}
          {errors.tax_id && <div className="error">Requerido</div>}
        </div>
        <div className="relative">
          <WInput
            mandatory
            type="text"
            ref={register}
            label="Razón social"
            name="legal_name"
            onChange={
              !isTypeCancel ? (e) => handleClientSearch(e.target.value) : null
            }
          />
          {filteredLegalName.length > 0 && !selectedClient && (
            <WDropdown
              filteredLegalName={filteredLegalName}
              setSelectedClient={(val) => setSelectedClient(val)}
            />
          )}
          <div className="error">{errors.legal_name?.message}</div>
        </div>
        <div className="">
          <RegimenSelect
            system="both"
            name="tax_system"
            register={register}
            defaultValue={publicoGral ? publicoEnGeneral.tax_system : ""}
          />
          {errors.tax_system && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput type="text" ref={register} name="street" label="Calle" />
          {errors.street && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput type="text" ref={register} name="exterior" label="Número" />
          {errors.exterior && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput
            mandatory
            type="text"
            ref={register}
            name="zip"
            label="Codigo postal"
          />
          {errors.zip && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput type="text" ref={register} name="state" label="Estado" />
          {errors.state && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput type="text" ref={register} name="city" label="Ciudad" />
          {errors.city && <div className="error">Requerido</div>}
        </div>
        <div className="">
          <WInput type="text" ref={register} name="phone" label="Teléfono" />
          <ErrorComponent error={errors.phone}></ErrorComponent>
        </div>
        <div className="">
          <WInput
            type="text"
            ref={register}
            name="support_email"
            label="Correo"
          />
          {errors.support_email && <div className="error">Requerido</div>}
        </div>
      </div>
      {publicoGral && (
        <>
          <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
            Factura global
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-1 gap-6 gap-x-40 items-center">
            <div>
              <WSelect
                mandatory
                type="text"
                ref={register}
                name="period"
                label="Periodo"
              >
                <option value="no">Seleecione un periodo</option>
                <option value="day">Diario</option>
                <option value="week">Semanal</option>
                <option value="fortnight">Quincenal</option>
                <option value="month">Mensual</option>
                <option value="two_months">Bimestral</option>
              </WSelect>
              {errors.period && <div className="error">Requerido</div>}
            </div>
            <div>
              <WSelect
                mandatory
                type="text"
                ref={register}
                name="month"
                label="Mes"
              >
                <option value="no">Seleecione uno</option>
                <option value="01">Enero</option>
                <option value="02">Febrero</option>
                <option value="03">Marzo</option>
                <option value="04">Abril</option>
                <option value="05">Mayo</option>
                <option value="06">Junio</option>
                <option value="07">Julio</option>
                <option value="08">Agosto</option>
                <option value="09">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
                <option value="13">Enero-Febrero</option>
                <option value="14">Marzo-Abril</option>
                <option value="15">Mayo-Junio</option>
                <option value="16">Julio-Agosto</option>
                <option value="17">Septiembre-Octubre</option>
                <option value="18">Noviembre-Diciembre</option>
              </WSelect>
              {errors.month && <div className="error">Requerido</div>}
            </div>
            <div>
              <WSelect
                mandatory
                type="text"
                ref={register}
                name="year"
                label="Año"
              >
                <option value="no">Seleecione uno</option>
                <option value={year}>{year}</option>
                <option value={prevYear}>{prevYear}</option>
              </WSelect>
              {errors.year && <div className="error">Requerido</div>}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default LegalDataInputForm;

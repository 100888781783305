import React, { useState } from "react";
import { useEffect } from "react";
import Layout from "../../components/Layout";
import NotFoundPage from "../public/NotFoundPage";
import { useHistory } from "react-router-dom";
import {
  fetchRegularUserFromAdmin,
  getLicensesFromAdmin,
} from "../../api/Admin";
import SubscriptionItemShareable from "../../components/subscriptions/SubscriptionItemShareable";

const MyShareableLink = ({ match }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [payLink, setPayLink] = useState([]);
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const history = useHistory();

  const sortByFinalDate = (a, b) => {
    if (!a.final_date && b.final_date) {
      return -1;
    } else if (!a.final_date && b.final_date) {
      return 1;
    } else {
      return 0;
    }
  };

  restaurants.sort(sortByFinalDate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const licenses = await getLicensesFromAdmin(match.params.user_id);

        if (licenses.message === "Conversion completa") {
          history.go(0);
        } else if (licenses.message === "Usuario no tiene restaurantes") {
          return false;
        } else {
          if (licenses.licenses.length > 0) {
            setRestaurants(licenses.licenses);
          } else {
            return false;
          }
        }
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchRegularUserFromAdmin(match.params.user_id)
      .then((usr) => {
        setUser(usr);
      })
      .catch((error) => {
        setError(error);
        console.error(error);
      });
  }, []);
  if (error) {
    return <NotFoundPage />;
  }
  return (
    <Layout title="Mis suscripciones" fullWidth>
      <div className="py-8 wrapper dashboard-resp">
        <div className="flex justify-between items-end page-padding">
          <div className="flex flex-wrap">
            <div className="select-resp">
              {user.name && (
                <h2 className="text-2xl text-gray-800 font-bold title-padding">
                  Hola {user.name} !
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-xl font-bold page-padding">Tus suscripciones</h3>

      <div className="py-8 wrapper page-padding">
        {/* {payLink} */}
        {restaurants.map((rest) => (
          <SubscriptionItemShareable
            key={rest.restaurant_id}
            {...rest}
            user_id={match.params.user_id}
            user_email={user.email}
          />
        ))}
      </div>
    </Layout>
  );
};

export default MyShareableLink;

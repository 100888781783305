import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PreviewCard from "../../components/restaurant/PreviewCard";

import { Link, useHistory } from "react-router-dom";
import { getAllGroups } from "../../api/Groups";
import { getfechasAtrasadas, getRestaurants } from "../../api/Restaurant";
import { getLicenses } from "../../api/User";
import LoadingIndicator from "../../components/LoadingIndicator";
import Select from "../../components/Select";
import { MdArrowBack } from "react-icons/md";
import {
  GROUP_PATH,
  INTRODUCTION_PATH,
  NEW_GROUP_PATH,
  NEW_SUBSCRIPTION_PATH,
} from "../../constants/paths";
import { ADMIN_HOME_PATH } from "../../constants/adminPaths";
import MailVerification from "./MailVerification";
import { signIn, signOut } from "../../api/Auth";

import DateApi from "date-and-time";
import MdAddCircle from "react-ionicons/lib/MdAddCircle";
import MdCreate from "react-ionicons/lib/MdCreate";
import SubscriptionItemNoInstalled from "../../components/subscriptions/SubscriptionItemNoInstalled";
import SubscriptionPaymentPending from "../../components/subscriptions/SubscriptionPaymentPending.js";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const timedMonthOptions = [
  { value: "actual", title: "Mes actual" },
  { value: "month_12", title: "Diciembre" },
  { value: "month_11", title: "Noviembre" },
  { value: "month_10", title: "Octubre" },
  { value: "month_9", title: "Septiembre" },

  { value: "month_8", title: "Agosto" },
  { value: "month_7", title: "Julio" },
  { value: "month_6", title: "Junio" },
  { value: "month_5", title: "Mayo" },

  { value: "month_4", title: "Abril" },
  { value: "month_3", title: "Marzo" },
  { value: "month_2", title: "Febrero" },
  { value: "month_1", title: "Enero" },
];

const timedWeekOptions = [{ value: "actual", title: "Semana Actual" }];
for (let i = 52; i > 0; i--) {
  timedWeekOptions.push({ value: `semana_${i}`, title: `Semana ${i}` });
}

const DashboardPageMock = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const historicOptions = [
    { value: "total", title: "Total" },
    { value: "efectivo", title: t("dashboard.dropdownOps.cash") },
    { value: "tarjeta", title: t("dashboard.dropdownOps.card") },
    { value: "otros", title: t("dashboard.dropdownOps.other") },
    { value: "totalFacturado", title: t("dashboard.dropdownOps.chargedTotal") },
    { value: "totalCostos", title: t("dashboard.dropdownOps.totalCost") },
    { value: "totalutilidad", title: t("dashboard.dropdownOps.profit") },
  ];

  const liveOptions = [
    { value: "total", title: "Total" },
    { value: "efectivo", title: t("dashboard.dropdownOps.cash") },
    { value: "tarjeta", title: t("dashboard.dropdownOps.card") },
    { value: "otros", title: t("dashboard.dropdownOps.other") },
    { value: "efectivoEnCaja", title: t("dashboard.dropdownOps.cashReg") },
    { value: "retiros", title: t("dashboard.dropdownOps.withdr") },
    { value: "depositos", title: t("dashboard.dropdownOps.deposits") },
    { value: "totalFacturado", title: t("dashboard.dropdownOps.chargedTotal") },
    { value: "totalCostos", title: t("dashboard.dropdownOps.totalCost") },
    { value: "totalutilidad", title: t("dashboard.dropdownOps.profit") },
  ];

  const globalOptions = [
    { value: "online", title: "⏺️ " + t("dashboard.dropdownOps.online") },
    { value: "yesterday", title: t("dashboard.dropdownOps.yest") },
    { value: "week", title: t("dashboard.dropdownOps.week") },
    { value: "month", title: t("dashboard.dropdownOps.monthly") },
    { value: "year", title: t("dashboard.dropdownOps.annual") },
  ];

  // Variables for filtering between groups
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [groupOptions, setGroupOptions] = useState([
    { value: 0, title: t("dashboard.dropdownOps.myRests") },
  ]);

  const [licenses, setLicenses] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [stayLicenses, setStayLicenses] = useState(true);

  const [globalSetting, setGlobalSetting] = useState("online");
  const [timedSetting, setTimedSetting] = useState("actual");
  const [detailedSetting, setDetailedSetting] = useState("total");
  const [restaurantTotalByRange, setRestaurantTotalByRange] = useState([]);

  const [generalOptions, setGeneralOptions] = useState([]);
  const [generalRanges, setGeneralRanges] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [adminSessionData, setAdminSessionData] = useState({});
  //If logged in as admin
  const hasAdminSession = window.localStorage.getItem("adminSession")
    ? true
    : false;

  const pendingPayment = [];

  useEffect(() => {
    const adminUserSession = JSON.parse(localStorage.getItem("adminSession"));
    if (adminUserSession) {
      setAdminSessionData({
        email: adminUserSession.email,
        password: "123456",
        userEmail: adminUserSession.userEmail,
      });
    } else return;
  }, []);

  const handleRestoreAdminUserSession = () => {
    if (
      window.confirm(
        `Desea restaurar la sesión de ${adminSessionData.email} y terminar la sesión de: ${adminSessionData.userEmail} ?`
      )
    ) {
      signOut(adminSessionData.userEmail)
        .then(() => {
          signIn(adminSessionData)
            .then(() => {
              history.push(ADMIN_HOME_PATH);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else return;
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const licenses = await getLicenses();

        if (licenses.message === "Conversion completa") {
          history.go(0);
        } else if (licenses.message === "Usuario no tiene restaurantes") {
          setStayLicenses(false);
        } else {
          if (licenses.licenses.length > 0) {
            setLicenses(licenses.licenses);
            setLoading(false);
          } else {
            setStayLicenses(false);
          }
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  licenses.forEach((license) => {
    if (license.final_date === null || license.activated === 0)
      pendingPayment.push(license);
  });

  useEffect(() => {
    const getUserGroups = async () => {
      try {
        const allGroups = await getAllGroups();
        setGroupOptions([
          { title: t("dashboard.dropdownOps.myRests"), value: 0 },
          ...allGroups.map((group) => {
            return { value: group.id, title: group.title };
          }),
        ]);

        return allGroups;
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getUserGroups();
  }, []);

  useEffect(() => {
    const fetchRests = async () => {
      setLoading(true);

      if (selectedGroup != null && !isNaN(selectedGroup)) {
        // Get restaurants once
        getRestaurants(selectedGroup)
          .then((rest) => {
            setRestaurants(rest);

            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
            setLoading(false);
          });

        // Get restaurants every 5 minutes
        const interval = setInterval(
          () => {
            getRestaurants(selectedGroup)
              .then((rest) => {
                setRestaurants(rest);
                setLoading(false);
              })
              .catch((error) => {
                setError(error);
                setLoading(false);
              });
          },
          1000 * 60 * 5
        );
        return () => clearInterval(interval);
      }
    };

    fetchRests();
  }, [selectedGroup]);

  useEffect(() => {
    if (restaurants.length > 0) {
      const newTotal = restaurants.reduce((currentTotal, restaurant) => {
        const val = restaurant[globalSetting][detailedSetting];
        const secondTotal = typeof val === "number" ? val : parseFloat(val);
        setLoading(false);
        return currentTotal + secondTotal;
      }, 0);
      setTotal(newTotal);
    }
  }, [restaurants, globalSetting, detailedSetting]);

  useEffect(() => {
    if (restaurants.length == 0 && !stayLicenses) {
      return history.push(INTRODUCTION_PATH);
    }
  }, [licenses, restaurants]);

  const filterTimedOptionsByType = (optionsArr, type) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const monthNumber = currentDate.getMonth() + 1;
    const weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7);
    const header = optionsArr[0];
    const isTypeMonth = type === "month";

    const timedNumber = isTypeMonth ? monthNumber : weekNumber;
    const typeMaxLength = isTypeMonth ? 12 : 52;

    const filteredOptions = optionsArr.filter((option) => {
      const timedValue = parseInt(option.value.split("_")[1]);
      return timedValue <= timedNumber - 1 && timedValue <= typeMaxLength;
    });
    filteredOptions.reverse();
    filteredOptions.push(header);
    filteredOptions.reverse();

    setGeneralOptions(filteredOptions);

    const generatedRangesObjects = isTypeMonth
      ? getMonthRanges(timedNumber)
      : getWeekRanges(timedNumber);

    setGeneralRanges(generatedRangesObjects);
  };

  useEffect(() => {
    const shouldGnerateOptions =
      globalSetting === "month" || globalSetting === "week";

    const arrayToUse =
      globalSetting === "month" ? timedMonthOptions : timedWeekOptions;

    if (shouldGnerateOptions) {
      filterTimedOptionsByType(arrayToUse, globalSetting);
    }
  }, [globalSetting]);

  const getWeekRanges = (e) => {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const startOfThisWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - (today.getDay() - 1)
    );
    const endOfThisWeek = new Date(startOfThisWeek.getTime() + 6 * oneDay);
    const ranges = [];

    for (let i = 0; i <= e; i++) {
      const startOfWeek = new Date(startOfThisWeek.getTime() - i * 7 * oneDay);
      const endOfWeek = new Date(endOfThisWeek.getTime() - i * 7 * oneDay);

      const range = {
        start: startOfWeek,
        end: endOfWeek,
      };

      ranges.push(range);
    }

    return ranges.reverse();
  };

  const getMonthRanges = (e) => {
    const buildRanges = [];

    for (let i = 0; i < e; i++) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - i);
      currentDate.setDate(1);
      currentDate.setHours(6, 0, 0, 0);

      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      const range = {
        start: currentDate,
        end: lastDayOfMonth,
      };

      buildRanges.push(range);
    }
    buildRanges.reverse();
    return buildRanges;
  };

  const getNumberFromCode = (e) => {
    var i = e.indexOf("_");

    if (i === -1) return generalRanges.length;

    var str = e.slice(i + 1);
    return str;
  };

  const reformatDate = (e, type) => {
    var reformatStart = new Date(e);
    var yyyy = reformatStart.getFullYear();
    var mm = reformatStart.getMonth() + 1; // Months start at 0!
    var dd = reformatStart.getDate();
    const lastHours = type === "end" ? " 05:59:59" : " 06:00:00";

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    reformatStart = yyyy + "-" + mm + "-" + dd + lastHours;
    return reformatStart;
  };

  const fechasPasadasAPIByRangeWeek = () => {
    setLoading(true);
    var promises = [];
    var restaurantsData = {};
    var licensesArr = licenses;
    var indexRemoval = [];
    var i = 0;

    licensesArr.forEach((element) => {
      if (!element.license_used) {
        indexRemoval.push(i);
      }
      i++;
    });
    indexRemoval.forEach((element) => {
      licensesArr.splice(element, 1);
    });

    licensesArr.forEach((element) => {
      var index = getNumberFromCode(timedSetting);
      var range = generalRanges[index - 1];
      var reformatStartStr = reformatDate(range.start, "start");
      var reformatEndStr = reformatDate(DateApi.addDays(range.end, 1), "end");
      var query = getfechasAtrasadas(
        element.restaurant_id,
        reformatStartStr,
        reformatEndStr
      )
        .then((licenses) => {
          restaurantsData[element.restaurant_id] = licenses;
          return restaurantsData;
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setLoading(true);
        });
      promises.push(query);
    });

    return Promise.all(promises).then((r) => {
      var data = r[0] || [];
      var relativeTotal = 0;
      for (const e in data) {
        relativeTotal = relativeTotal + data[e][detailedSetting];
      }
      setTotal(relativeTotal);
      setRestaurantTotalByRange(data);

      setLoading(false);
    });
  };

  const fechasPasadasAPIByRangeMonth = async () => {
    setLoading(true);

    var promises = [];
    var restaurantsData = {};
    var licensesArr = licenses;
    var indexRemoval = [];
    var i = 0;
    licensesArr.forEach((element) => {
      if (!element.license_used) {
        indexRemoval.push(i);
      }
      i++;
    });
    indexRemoval.forEach((element) => {
      licensesArr.splice(element, 1);
    });
    licensesArr.forEach((element) => {
      var index = getNumberFromCode(timedSetting);
      var range = generalRanges[index - 1];
      var reformatStartStr = reformatDate(range.start, "start");
      var reformatEndStr = reformatDate(DateApi.addDays(range.end, 1), "end");
      var query = getfechasAtrasadas(
        element.restaurant_id,
        reformatStartStr,
        reformatEndStr
      )
        .then((licenses) => {
          restaurantsData[element.restaurant_id] = licenses;
          return restaurantsData;
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setLoading(true);
        });
      promises.push(query);
    });
    return Promise.all(promises).then((r) => {
      var data = r[0] || [];
      var relativeTotal = 0;
      for (const e in data) {
        relativeTotal = relativeTotal + data[e][detailedSetting];
      }
      setTotal(relativeTotal);
      setRestaurantTotalByRange(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (
      globalSetting == "week" &&
      timedSetting !== "actual" &&
      timedSetting !== undefined
    ) {
      fechasPasadasAPIByRangeWeek();
    } else if (
      globalSetting == "month" &&
      timedSetting !== "actual" &&
      timedSetting !== undefined
    ) {
      fechasPasadasAPIByRangeMonth();
    } else if (timedSetting === "actual") {
      const newTotal = restaurants.reduce((currentTotal, restaurant) => {
        const val = restaurant[globalSetting][detailedSetting];
        const secondTotal = typeof val === "number" ? val : parseFloat(val);
        setLoading(false);
        return currentTotal + secondTotal;
      }, 0);
      setTotal(newTotal);
    }
  }, [timedSetting, detailedSetting]);

  useEffect(() => {
    if (globalSetting == "week" || globalSetting == "month") {
      setTimedSetting("actual");
    }
  }, [globalSetting]);

  const changeSelectedGroup = (e) => {
    setSelectedGroup(parseInt(e.currentTarget.value));
  };

  const changeTimedWeekSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );

    setTimedSetting(e.currentTarget.value);
    if (!currentDetailPossible) {
      setDetailedSetting("total");
    }
  };

  const changeTimedMonthSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );

    setTimedSetting(e.currentTarget.value);
    if (!currentDetailPossible) {
      setDetailedSetting("total");
    }
  };

  const changeGlobalSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );

    setGlobalSetting(e.currentTarget.value);
    if (!currentDetailPossible) {
      setDetailedSetting("total");
    }
  };

  const changeDetailedSetting = (e) => {
    setDetailedSetting(e.currentTarget.value);
  };

  if (error) {
    return (
      <Layout title="Dashboard">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard">
      <div className="py-8 wrapper dashboard-resp">
        <div className="flex justify-between items-end">
          <div className="flex flex-wrap">
            {hasAdminSession && (
              <button
                className="wfacturas-button mb-4"
                onClick={handleRestoreAdminUserSession}
              >
                {" "}
                <MdArrowBack />
                Back to admin
              </button>
            )}
            <div className="w-full text-xs text-gray-500">
              <MailVerification />
              <Link to={NEW_GROUP_PATH} className="inline-flex items-center">
                <MdAddCircle className="mr-1 fill-current w-4" />
                {t("dashboard.newGroup")}
              </Link>
            </div>
            <div className="select-resp">
              <Select
                selected={selectedGroup}
                onChange={changeSelectedGroup}
                options={groupOptions}
              />
              <button
                className="ml-2 appearance-none bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-center new-rest-resp"
                onClick={() => history.push(NEW_SUBSCRIPTION_PATH)}
              >
                {t("dashboard.newRest")}
              </button>
            </div>
          </div>
          <div className="select-resp">
            {globalSetting === "month" && (
              <>
                <Select
                  onChange={changeTimedMonthSetting}
                  options={generalOptions}
                />
                <div className="inline ml-2"></div>
              </>
            )}

            {globalSetting === "week" && generalOptions.length > 0 && (
              <>
                <Select
                  onChange={changeTimedWeekSetting}
                  options={generalOptions}
                />
                <div className="inline ml-2 resp-display-none"></div>
              </>
            )}

            <Select onChange={changeGlobalSetting} options={globalOptions} />
            <div className="inline ml-2 resp-display-none"></div>
            <Select
              onChange={changeDetailedSetting}
              options={
                globalSetting === "online" ? liveOptions : historicOptions
              }
            />
          </div>
        </div>
        <hr className="mt-2 mb-4"></hr>
        {loading || restaurants.length === 0 ? (
          <LoadingIndicator />
        ) : (
          <>
            {!restaurants || restaurants.length === 0 ? (
              <div className="border rounded py-8">
                <h4 className="text-center text-xl font-bold text-gray-800 leading-tight">
                  {t("dashboard.noSub")}
                </h4>
                <p className="text-base text-gray-700 font-light mt-4 text-center">
                  {t("dashboard.registerRest")}{" "}
                  <Link
                    to={NEW_SUBSCRIPTION_PATH}
                    className="text-blue-700 font-medium"
                  >
                    {t("dashboard.here")}
                  </Link>
                </p>
              </div>
            ) : (
              <>
                {restaurants.map((restaurant) => (
                  <PreviewCard
                    key={`preview-card-${restaurant.id}`}
                    restaurant={restaurant}
                    globalSetting={globalSetting}
                    detailedSetting={detailedSetting}
                    restaurantTotalByRange={
                      restaurantTotalByRange[`${restaurant.id}`]
                    }
                    delayedTables={restaurant.delayedTables}
                    poleadorActive={restaurant.poleadorActive}
                    timedSetting={timedSetting}
                  />
                ))}
                <hr className="mt-4 mb-2"></hr>
                <div className="flex justify-between pr-4">
                  <h3 className="text-lg text-gray-800 font-bold">Total</h3>
                  <span className="flex items-center">
                    <span className="text-brand-700 font-bold text-xl">
                      {formatter.format(total)}
                    </span>
                    <span className="text-xs ml-1 uppercase text-gray-700">
                      MXN
                    </span>
                  </span>
                </div>
              </>
            )}
          </>
        )}
        {selectedGroup && selectedGroup !== 0 ? (
          <div className="w-full text-xs text-gray-500 mt-4">
            <Link
              to={`${GROUP_PATH}/${selectedGroup}`}
              className="inline-flex items-center"
            >
              <MdCreate className="mr-1 fill-current w-4" />
              {t("dashboard.editGroup")}
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
      {licenses.filter((license) => license.final_date === null).length > 0 && (
        <div className="py-8 wrapper">
          <div className="border py-8 px-5 rounded">
            <div className="mb-4">
              <h4 className="text-xl font-bold text-gray-800 leading-tight">
                {t("dashboard.pendingPay")}
              </h4>
            </div>
            {pendingPayment.map((restaurant) => (
              <SubscriptionPaymentPending {...restaurant} />
            ))}
          </div>
        </div>
      )}
      {licenses.filter((e) => !e.license_used).length > 0 && (
        <div className="py-8 wrapper">
          <div className="border py-8 px-5 rounded">
            <div className="mb-4">
              <h4 className="text-xl font-bold text-gray-800 leading-tight">
                {t("dashboard.pendingInst")}
              </h4>
            </div>
            {licenses.map((license) => (
              <SubscriptionItemNoInstalled {...license} />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DashboardPageMock;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {
  getTimbresLanding,
  searchFolioLanding,
  sendFacturaByEmailLanding,
} from "../../../api/Utils";
import { HOME_PATH_FACTURACION } from "../../../constants/paths";
import WFacturasActionModal from "../../../components/modals/WFacturasActionModal";
import { useToast } from "../../../context/ToastContext";
import infoIcon from "../../../images/info-icon.svg";
import WFacturasInfoModal from "../../../components/modals/WFacturasInfoModal";

const NewFacturaFormLanding = (props) => {
  const { restId, picKey } = props;
  const { t } = useTranslation();
  const { showToast } = useToast();
  let history = useHistory();

  const schema = yup.object().shape({
    folio: yup.number().required(),
    rest_id: restId ? yup.number() : yup.number().required(),
    amount: yup.string().required(),
    // .test(function (value) {
    //   const currentDate = new Date().toISOString().split("T")[0];
    //   const inputDate = new Date(value).toISOString().split("T")[0];
    //   const currentMonth = currentDate.split("-")[1];
    //   const inputMonth = inputDate.split("-")[1];

    //   return currentMonth === inputMonth;
    // }),
  });
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [invalidFolio, setInvalidFolio] = useState(false);
  const [getMessage, setGetMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [modalType, setModalType] = useState("");
  const [amount, setAmount] = useState("");

  const formatCurrency = (val) => {
    let cleanValue = val.replace(/[^0-9.]/g, "");
    const dotCount = cleanValue.split(".").length - 1;

    if (dotCount > 1) {
      cleanValue = cleanValue.replace(/\./g, (match, offset, string) =>
        offset === string.indexOf(".") ? "." : ""
      );
    }
    const parts = cleanValue.split(".");

    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatCurrency(inputValue);
    setAmount(formattedValue);
  };

  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOpenModal = (type, id, folio) => {
    setModalData({
      type: type,
      id: id,
      folio: folio,
    });

    setOpenModal(true);
  };

  const handleInfoModal = (type) => {
    setModalType(type);
    setOpenInfo(true);
  };

  const handleSendInvoice = async (email) => {
    try {
      const rest_id = restId ? restId : modalData.id;
      await sendFacturaByEmailLanding(rest_id, modalData.folio, email);
      showToast("Factura enviada con éxito", "success");
      setOpenModal(false);
      return;
    } catch (error) {
      if (error.response?.data?.message === "ERROR_PANF") {
        showToast("Operación no disponible", "custom");
        setOpenModal(false);
        return;
      }
      showToast("Error al enviar factura, revise sus datos", "error");
    }
  };

  const handleCloseModal = () => {
    setOpenInfo(false);
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const rest_id = restId ? restId : values.rest_id;
      const timbresRes = await getTimbresLanding(rest_id);
      const clearedAmount = amount.replace(/,/g, "");
      const parsedInputAmount = parseFloat(clearedAmount) || 0;

      const res = await searchFolioLanding({
        restId: rest_id,
        folio: values.folio,
        fecha: values.fecha,
      });

      if (!timbresRes.active || timbresRes.timbres === 0) {
        setGetMessage("[ERROR_BANF]");
        setInvalidFolio(true);
        setLoading(false);
        return null;
      }

      if (res.message === "expired") {
        setGetMessage("Folio vencido");
        setInvalidFolio(true);
        setLoading(false);
        return null;
      }
      const cheqAmount = parseFloat(res.cheque[0].wf_totalsinpropina);

      if (cheqAmount !== parsedInputAmount) {
        setGetMessage("Folio no encontrado");
        setInvalidFolio(true);
        setLoading(false);
        return null;
      }

      if (res.cheque.length === 0) {
        setGetMessage("Folio no encontrado");
        setInvalidFolio(true);
      } else if (res.cheque[0].facturado === "1") {
        setGetMessage("Folio ya se encuentra facturado");
        setInvalidFolio(true);

        handleOpenModal("send-landing", rest_id, values.folio);
      } else {
        history.push({
          pathname: `${HOME_PATH_FACTURACION}/${rest_id}/${values.folio}/${values.fecha}`,
          state: { key: picKey },
        });
      }
      setLoading(false);
    } catch (error) {
      setGetMessage("Datos invalidos");
      setInvalidFolio(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <WFacturasActionModal
        loading={loading}
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        modalData={modalData}
        initializeAction={(email) => handleSendInvoice(email)}
      />
      <WFacturasInfoModal
        type={modalType}
        isOpen={openInfo}
        onRequestClose={handleCloseModal}
      />
      <label className="subtitle-text">Datos del consumo</label>
      <div className="flex relative">
        <div className="input-wrapper mt-6 mr-3 flex-1 relative">
          <input
            disabled={restId}
            type="text"
            ref={register}
            className="landing-input"
            name="rest_id"
            defaultValue={restId || ""}
            placeholder="Id restaurante"
          />
          {errors.rest_id && <div className="error">Restaurante invalido</div>}
          <div className="info-icon" onClick={() => handleInfoModal("restId")}>
            <img src={infoIcon} />
          </div>
        </div>
        <div className="input-wrapper mt-6 flex-1 relative">
          <input
            type="text"
            ref={register}
            className="landing-input"
            name="folio"
            placeholder="Folio"
          />
          {errors.folio && <div className="error">Folio invalido</div>}
          <div className="info-icon" onClick={() => handleInfoModal("folio")}>
            <img src={infoIcon} />
          </div>
        </div>
      </div>
      <div className="flex w-full pt-6 items-center justify-center md:justify-between">
        <div className="input-wrapper max-w-sm md:mr-0 mr-4 flex-1 relative">
          <input
            className="landing-input focus:outline-none"
            name="amount"
            placeholder="importe"
            ref={register}
            defaultValue={amount}
            onChange={handleChange}
          />
          {errors.amount && <div className="error">Debe ingresar el monto</div>}
          <div className="info-icon" onClick={() => handleInfoModal("amount")}>
            <img src={infoIcon} />
          </div>
        </div>
        <button className="wfacturas-button-landing" type="submit">
          Siguiente
        </button>
      </div>
      {invalidFolio && (
        <div className="text-red-500 font-semibold">{getMessage}</div>
      )}
    </form>
  );
};

export default NewFacturaFormLanding;

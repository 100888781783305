import React from "react";
import PieChart from "../PieChart";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PaymentsAndMovements = ({ payments, movements }) => {
  const { t } = useTranslation();

  if (payments.length === 0 && movements.length === 0) {
    return (
      <h3 className="text-2xl text-gray-700 font-bold">
        {t("restPage.paymentsTitle2")}
      </h3>
    );
  }

  return (
    <>
      <h3 className="text-2xl text-gray-700 font-bold">
        {t("restPage.paymentsTitle")}
      </h3>
      <div className="flex flex-wrap md:flex-no-wrap">
        {payments.length > 0 ? (
          <PieChart
            title={t("restPage.paymentsChart1Title")}
            customProperties={{
              animate: true,
              margin: { top: 30, right: 30, bottom: 30, left: 30 },
            }}
            data={payments.map((payment, index) => {
              return {
                id: payment.descripcion,
                label: payment.descripcion,
                value: parseFloat(payment.precio),
              };
            })}
          ></PieChart>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ml-4">
            <div className="text-sm text-gray-800 font-medium mb-2">
              {t("restPage.paymentsTitle2")}
            </div>
          </div>
        )}
        {movements.length > 0 ? (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ml-4">
            <div className="text-sm text-gray-800 font-medium mb-2">
              {t("restPage.paymentsChart2Title")}
            </div>
            <ul>
              {movements.map(
                ({ concepto, fecha, idturno, importe, referencia, tipo }) => {
                  return (
                    <li className="py-2 px-4 rounded border border-gray-300 text-gray-800 flex justify-between items-end mb-2">
                      <div>
                        {tipo === "1" ? (
                          <div className="text-xs text-red-700 uppercase font-medium">
                            {t("restPage.paymentsChart2Op1")}
                          </div>
                        ) : (
                          <div className="text-xs text-green-700 uppercase font-medium">
                            {t("restPage.paymentsChart2Op2")}
                          </div>
                        )}
                        <div className="text-xs">
                          {new Date(fecha).toLocaleDateString()}
                          {" - "}
                          {new Date(fecha).toLocaleTimeString()}
                        </div>
                        <div className="uppercase text-sm">{concepto}</div>
                        <div className="text-sm">{referencia}</div>
                      </div>
                      {tipo === "1" ? (
                        <div className="text-sm text-red-700 uppercase font-bold">
                          {formatter.format(parseFloat(importe))}
                        </div>
                      ) : (
                        <div className="text-sm text-green-700 uppercase font-bold">
                          {formatter.format(parseFloat(importe))}
                        </div>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ml-4">
            <div className="text-sm text-gray-800 font-medium mb-2">
              {t("restPage.paymentsTitle2")}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentsAndMovements;

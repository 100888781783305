import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { APP_RESTAURANTS_PATH } from "../../constants/paths";
import { BsWifiOff } from "react-icons/bs";
import { GiTable } from "react-icons/gi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import "./styles/PreviewCard.scss";

import { color } from "@mui/system";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PreviewCard = ({
  restaurant,
  globalSetting,
  detailedSetting,
  restaurantTotalByRange,
  timedSetting,
  delayedTables,
  poleadorActive,
}) => {
  const { text: title, id } = restaurant;
  const [dataB, setDataB] = useState({});

  var values;
  if (
    globalSetting == "month" ||
    (globalSetting == "week" && restaurantTotalByRange !== undefined)
  ) {
    if (timedSetting !== "actual" && restaurantTotalByRange !== undefined) {
      values = restaurantTotalByRange;
    } else {
      values = restaurant[globalSetting] || 0;
    }
  } else {
    values = restaurant[globalSetting] || 0;
  }

  return (
    <Link
      to={`${APP_RESTAURANTS_PATH}/${id}`}
      className="block px-4 py-2 rounded border mt-2 shadow-sm text-gray-700 border-gray-200"
    >
      <h2 className="flex justify-between flex-grow-card items-center">
        <span className="text-base text-gray-800">{title}</span>

        <span className="table-icon-no-resp rm-margin-resp">
          {delayedTables.length !== 0 ? (
            <>
              <span
                style={{ display: "flex", color: "red", marginRight: "2.5px" }}
              >
                {`${delayedTables.length}`}
              </span>

              <span
                style={{ display: "flex", position: "relative", top: "-2.5px" }}
              >
                <GiTable style={{ width: "auto", height: "25px" }} fill="red" />
                <GiTable
                  style={{
                    width: "auto",
                    height: "15px",
                    position: "absolute",
                    marginLeft: "5px",
                    marginTop: "6px",
                  }}
                  fill="red"
                />
                <BsFillExclamationTriangleFill
                  style={{
                    width: "auto",
                    height: "10px",
                    marginTop: "5px",
                    position: "absolute",
                    marginLeft: "15px",
                  }}
                  fill="red"
                />
              </span>
            </>
          ) : (
            <> </>
          )}
          {!poleadorActive ? (
            <span
              style={{
                marginTop: "5px",
                marginLeft: "25px",
              }}
            >
              <BsWifiOff fill="red" />
            </span>
          ) : (
            <> </>
          )}
        </span>
        <span className="flex items-center">
          <span className="text-brand-700 font-bold text-xl text-resp">
            {formatter.format(
              typeof values[detailedSetting] === "number"
                ? values[detailedSetting]
                : parseFloat(values[detailedSetting])
            )}
          </span>
          <span className="text-xs ml-1 uppercase">MXN</span>
        </span>
      </h2>
    </Link>
  );
};

export default PreviewCard;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./NewSubscriptionForm.css";
import ErrorComponent from "../ErrorComponent";
import PlanOption from "./PlanOption";
import PaymentMethodOption from "./PaymentMethodOption";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  createRestaurantSubscription,
  createRestaurantSubscriptionOxxoSpei,
  createRestaurantSubscriptionPaypal,
  getPaymentMethods,
  validateCoupon,
} from "../../api/User";

// Import paths
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_PAYMENT_METHOD_PATH,
  NEW_SUBSCRIPTION_PATH,
  INSTALL_PATH,
  PAYMENT_REFERENCE,
} from "../../constants/paths";
import {
  TRIMESTRAL_PAYPAL,
  SEMESTRAL_PAYPAL,
  ANUAL_PAYPAL,
  MENSUAL_PAYPAL,
} from "../../constants/plans";
import LoadingIndicator from "../LoadingIndicator";
import { validatePassword } from "../../api/Restaurant";
import NewSubscriptionModal from "./NewSubscriptionModal";
import NewPaymentMethodForm from "../methods/NewPaymentMethodForm";
import { PayPalButton } from "react-paypal-button-v2";
import { useTranslation } from "react-i18next";

window.Conekta.setPublicKey("key_Sud9prnqdFEs7x7YtYKMb7Q");

const passwordSchema = yup.object().shape({
  password: yup.string().required("Debes ingresar la contraseña."),
});

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del restaurante."),
  plan: yup.string().required("Selecciona un plan de pago"),
});

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const NewSubscriptionPayPalForm = ({ id, pm }) => {
  const { t } = useTranslation();

  // Variables for changing password form state
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  //Variable for steps
  const [step, setStep] = useState(1);

  // Variable for holding restaurant data
  const [restaurant, setRestaurant] = useState({});

  const initialCoupon = {
    code: "",
  };

  const [datos, setDatos] = useState(null);

  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState({});
  const [couponCode, setCouponCode] = useState(initialCoupon);
  const history = useHistory();
  const [restaurantName, setRestaurantName] = useState("");
  const [modification, setModification] = useState(true);

  // Variable for success modal
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Instantiate password form
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
  } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: { password: "" },
  });

  // Instantiate restaurant form
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Variables for watching form attributes
  const plan = watch("plan");
  const method = watch("method");

  // Register method and plan fields
  useEffect(() => {
    register("plan");
    register("title");
  }, [register]);

  const selectMethod = (id) => {
    setValue("method", id, true);
    clearErrors("method");
  };

  const selectPlan = (id) => {
    setValue("plan", id, true);
    setStep(1);
    clearErrors("plan");
  };

  const onSubmit = async (data) => {
    setDatos({ ...data });
    setStep(2);
  };

  const onPasswordSubmit = ({ password }) => {
    setPasswordLoading(true);
    validatePassword(id, password)
      .then((restaurant) => {
        setPasswordLoading(false);
        setPasswordSuccess(true);
        setRestaurant(restaurant);
        setPasswordError(null);
      })
      .catch((error) => {
        setPasswordLoading(false);
        setPasswordSuccess(false);
        setPasswordError(error);
      });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setShouldRedirect(true);
  };

  const createSubscription = (data) => {
    createRestaurantSubscriptionPaypal(data).then((response) => {
      if (response.data.message === "restaurant created successfully") {
        setStep(1);
        setSuccess(true);
      }
    });
  };

  useEffect(() => {
    // Show a success popup if needed
    if (success && !id) {
      openModal();
    } else if (success) {
      setShouldRedirect(true);
    }
  }, [success, response]);

  if (shouldRedirect) return <Redirect to={INSTALL_PATH}></Redirect>;

  if (loading || passwordLoading) return <LoadingIndicator></LoadingIndicator>;

  if (id && !passwordSuccess)
    return (
      <form onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Contraseña del restaurante
          </label>
          <input
            type="password"
            name="password"
            ref={passwordRegister}
            className="input"
            key="password-input"
          />
          <ErrorComponent error={passwordErrors.password}></ErrorComponent>
        </div>
        <button type="submit" className="button-main">
          Verificar contraseña
        </button>
        <ErrorComponent error={passwordError}></ErrorComponent>
      </form>
    );

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <NewSubscriptionModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          license={response.key}
          restaurant={response.restaurant}
        ></NewSubscriptionModal>
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("subsPage.subFormRest")}
          </label>
          <input
            type="text"
            name="title"
            ref={register}
            className="input new-sub-input"
            defaultValue={restaurant.text || ""}
          />
          <ErrorComponent error={errors.title}></ErrorComponent>
        </div>

        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("subsPage.subFormPlans")}
          </label>
          <div className="flex flex-wrap">
            <PlanOption
              active={
                plan !== TRIMESTRAL_PAYPAL &&
                plan !== SEMESTRAL_PAYPAL &&
                plan !== ANUAL_PAYPAL &&
                plan !== undefined
              }
              title={t("subsPage.subFormPlan1")}
              period="mes"
              price={349}
              id={MENSUAL_PAYPAL}
              onClick={selectPlan}
            ></PlanOption>
            <PlanOption
              active={plan === TRIMESTRAL_PAYPAL}
              title={t("subsPage.subFormPlan2")}
              period="trimestre"
              price={979}
              id={TRIMESTRAL_PAYPAL}
              onClick={selectPlan}
            ></PlanOption>
            <PlanOption
              active={plan === SEMESTRAL_PAYPAL}
              title={t("subsPage.subFormPlan3")}
              period="semestre"
              price={1919}
              id={SEMESTRAL_PAYPAL}
              onClick={selectPlan}
            ></PlanOption>
            <PlanOption
              active={plan === ANUAL_PAYPAL}
              title={t("subsPage.subFormPlan4")}
              period="año"
              price={3699}
              id={ANUAL_PAYPAL}
              onClick={selectPlan}
              isLast
            ></PlanOption>
          </div>
          <ErrorComponent error={errors.plan}></ErrorComponent>
        </div>

        {step === 1 && (
          <button type="submit" className="button-main new-sub-btn">
            {t("subsPage.subFormNext")}
          </button>
        )}

        {step === 2 && plan === MENSUAL_PAYPAL && (
          <div style={{ marginTop: "20px" }}>
            <PayPalButton
              type="submit"
              style={{
                shape: "pill",
                color: "blue",
                layout: "horizontal",
                label: "subscribe",
              }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  /* Creates the subscription */
                  plan_id: "P-4G1850047D198031LMGE2E6A",
                });
              }}
              onApprove={(data, actions) => {
                actions.subscription.get().then(function (details) {
                  const createSubData = {
                    name: datos.title,
                    plan: datos.plan,
                    created_at: details.create_time,
                    final_date: details.billing_info.next_billing_time,
                    database_string: restaurant.database_string,
                    license_id: restaurant.license_id,
                    paypal_id: details.id,
                  };

                  createSubscription(createSubData);
                });
              }}
            />
          </div>
        )}

        {step === 2 && plan === TRIMESTRAL_PAYPAL && (
          <PayPalButton
            style={{
              shape: "pill",
              color: "blue",
              layout: "horizontal",
              label: "subscribe",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: "P-1G581961SH138151GMGE2GUQ",
              });
            }}
            onApprove={(data, actions) => {
              actions.subscription.get().then(function (details) {});
            }}
          />
        )}

        {step === 2 && plan === SEMESTRAL_PAYPAL && (
          <PayPalButton
            style={{
              shape: "pill",
              color: "blue",
              layout: "horizontal",
              label: "subscribe",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: "P-5KS630310U931842FMGE2HSA",
              });
            }}
            onApprove={(data, actions) => {
              actions.subscription.get().then(function (details) {});
            }}
          />
        )}

        {step === 2 && plan === ANUAL_PAYPAL && (
          <PayPalButton
            style={{
              shape: "pill",
              color: "blue",
              layout: "horizontal",
              label: "subscribe",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                /* Creates the subscription */
                plan_id: "P-2FL80002ML641044HMGE2H5Q",
              });
            }}
            onApprove={(data, actions) => {
              actions.subscription.get().then(function (details) {});
            }}
          />
        )}
      </form>
      {/* se tiene que crear la suscripcion */}
    </>
  );
};

export default NewSubscriptionPayPalForm;

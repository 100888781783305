import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import DotComponent from "../../components/DotComponent";
import LoadingIndicator from "../../components/LoadingIndicator";
import { DISTRIBUTOR_REFERRALS_PATH } from "../../constants/distributorPaths";
import Layout from "../../components/Layout";
import BackArrow from "../../components/BackArrow";
import { fetchRestaurant } from "../../api/Distributor";
import SortableTable from "../../components/SortableTable";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";

const DistributorRestaurantPage = ({ match }) => {
  // Variables for handling restaurant data from API.
  const [restaurant, setRestaurant] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurant
    fetchRestaurant(match.params.user_id, match.params.restaurant_id)
      .then((res) => {
        setRestaurant(res);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <LayoutV2 title={`${restaurant.name}`} admin>
      <div className="py-8 wrapper">
        <BackArrow
          to={`${DISTRIBUTOR_REFERRALS_PATH}/${match.params.user_id}`}
          title="Atrás"
        ></BackArrow>
        <div>
          <h2 className="text-4xl text-gray-800 font-bold leading-tight">
            {restaurant.name}
          </h2>{" "}
          <p className="text-sm mb-4">
            Se creó el{" "}
            {dayjs(restaurant.created_at)
              .locale("es")
              .format("dddd D [de] MMMM [del] YYYY")}
          </p>
          <DotComponent status={restaurant.active}></DotComponent>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <div>
          <h5 className="font-bold text-xl text-gray-700">Últimos cobros</h5>
          <SortableTable
            columns={columns}
            data={restaurant.invoices}
          ></SortableTable>
        </div>
      </div>
    </LayoutV2>
  );
};

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const StatusCell = ({ value }) => {
  if (value === "paid") return <span>Pagado</span>;

  return <span></span>;
};

const PaymentDateCell = ({ value }) => {
  return (
    <span className="">
      {dayjs(value * 1000)
        .locale("es")
        .format("dddd D [de] MMMM [del] YYYY")}
    </span>
  );
};

const QuantityCell = ({ value }) => {
  return <span>{formatter.format(value / 100)}</span>;
};

const ExternalLinkCell = ({ value }) => {
  return (
    <a href={value} target="_blank" rel="nofollow" className="font-medium">
      Ver detalles
    </a>
  );
};

const columns = [
  { Header: "Fecha de pago", accessor: "created", Cell: PaymentDateCell },
  { Header: "Estado", accessor: "status", Cell: StatusCell },
  { Header: "Cantidad", accessor: "amount_paid", Cell: QuantityCell },
  {
    Header: "Detalles",
    accessor: "hosted_invoice_url",
    Cell: ExternalLinkCell,
  },
];

export default DistributorRestaurantPage;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WToggle from "../WToggle";
import { getTimbresData } from "../../api/Utils";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";
import WSelect from "../WSelect";
import PlanCard from "../PlanCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_WFACTURAS_PLANS } from "../../constants/paths";
import { useToast } from "../../context/ToastContext";
import { cancelFacturacionSubscription } from "../../api/User";

const parseLocalStorage = () => {
  const planActive = parseInt(localStorage.getItem("timbresPlanActive"));
  if (!isNaN(planActive)) {
    return planActive;
  } else {
    return localStorage.getItem("timbresPlanActive") || null;
  }
};

const PlansForm = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const history = useHistory();
  const [monthlyPlan, setMonthlyPlan] = useState(true);
  const planInterval = localStorage.getItem("planInterval");
  const [planType, setPlanType] = useState(parseLocalStorage());
  const { restaurants, restId, setRestId, loading, setLoading, selectRef } =
    useRestsDropdown();

  const getPlanData = async (restId) => {
    try {
      const planResponse = await getTimbresData(restId);
      const planId = planResponse.plan === 99999 ? "UNL" : planResponse.plan;

      if (planResponse.active) {
        setPlanType(planId);
      }
    } catch (error) {
      setPlanType(null);
      return null;
    }
  };

  const handleRestaurantChange = (value) => {
    setRestId(value);
  };

  const handlePeriodicityChange = (value) => {
    setMonthlyPlan(value);
  };

  const handleCancelSubscription = async () => {
    if (
      window.confirm(
        `¿Seguro que desea cancelar su suscripción: PLAN_${planType}?`
      )
    ) {
      try {
        await cancelFacturacionSubscription({
          rest_id: restId,
        });
        showToast("Suscripción cancelada", "success");
      } catch (error) {
        showToast("Error al cancelar la suscripción", "error");
      }
    }
  };

  const handlePlanPreview = (plan) => {
    history.push({
      pathname: `${APP_WFACTURAS_PLANS}/${restId}/${plan}`,
      search: `?monthly=${monthlyPlan}`,
      state: {
        data: {
          planType: planType,
          active: planType && planType !== "false",
        },
      },
    });
  };

  useEffect(() => {
    if (restId) {
      getPlanData(restId);
    }
  }, [restId]);

  return (
    <div className="p-8 w-full mt-12 md:mt-0">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="facturas-title">Tienda de timbres</h3>
          <text className="text-md">
            Selecciona el plan más conveniente para ti
          </text>
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <label
            className={`${
              monthlyPlan ? "text-green-600" : "wfacturas-table-text"
            } font-bold`}
          >
            Mensual
          </label>
          <WToggle onChange={handlePeriodicityChange} value={!monthlyPlan} />
          <label
            className={`${
              !monthlyPlan ? "text-green-600" : "wfacturas-table-text"
            } font-bold`}
          >
            Anual
          </label>
        </div>
      </div>
      <div className="input-wrapper mt-4 mb-4">
        <WSelect
          mandatory
          name="restId"
          label="Seleccione sucursal"
          defaultValue={restaurants}
          ref={selectRef}
          onChange={(value) => handleRestaurantChange(value)}
        >
          {restaurants?.map((rest, index) => (
            <option key={index} value={rest.id}>
              {rest.text}
            </option>
          ))}
        </WSelect>
      </div>
      <div className="grid mt-10 grid-cols-1 md:grid-cols-3 gap-y-8 gap-x-16">
        {[150, 300, 1000].map((plan) => (
          <PlanCard
            key={plan}
            plan={plan}
            handlePlanPreview={handlePlanPreview}
            activePlan={planType}
            monthly={monthlyPlan}
            cancel={handleCancelSubscription}
            planInterval={planInterval}
          />
        ))}
      </div>
    </div>
  );
};

export default PlansForm;

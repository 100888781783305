import React from "react";
import EditGroupForm from "../../../components/groups/EditGroupForm";
import Layout from "../../../components/Layout";

const EditGroupPage = ({ match }) => {
  return (
    <Layout title="Editar grupo">
      <div className="py-8 wrapper">
        <h2 className="text-2xl text-gray-800 font-bold">Editar grupo</h2>
        <EditGroupForm id={match.params.id}></EditGroupForm>
      </div>
    </Layout>
  );
};

export default EditGroupPage;

import React, { useState, useEffect } from "react";
import { fetchUserProfile } from "../../api/Auth";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  generateMailCode,
  validateMailCode,
  setUserVerified,
  isUserVerified,
} from "../../api/User";
import Layout from "../../components/Layout";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import "./Verification.css";
import { INTRODUCTION_PATH } from "../../constants/paths";

const Verification = () => {
  const history = useHistory();
  const [userEmail, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [verified, setVerified] = useState(true);
  const [loading, setLoading] = useState(true);

  const { register, handleSubmit, errors, reset } = useForm({
    /* resolver: yupResolver(schema),
        defaultValues: userData, */
  });

  useEffect(() => {
    fetchUserProfile()
      .then((usr_data) => {
        setEmail(usr_data.email);
        setUserName(usr_data.name);
        isUserVerified(usr_data.email).then((res) => {
          if (res.emailVerified) {
            history.push(INTRODUCTION_PATH);
          } else {
            setLoading(false);
          }
        });
        //emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const sendMailCode = () => {
    generateMailCode(userEmail).then((code) => {
      var templateParams = {
        reply_to: userEmail,
        code: code.mailCode,
        name: userName,
      };
      emailjs.send(
        "service_ss9w9ws",
        "template_tc2fxi5",
        templateParams,
        "user_itfQnWMwlnZrVcVMwjZwQ"
      );
    });
  };

  const onSubmit = (values) => {
    var code = values.code;
    if (code.length != 0) {
      setSuccess(false);
      validateMailCode(userEmail, code)
        .then((res) => {
          setSuccess(true);
          setError(null);

          if (res.isValid) {
            setUserVerified(userEmail, code).then((res) => {
              history.push(INTRODUCTION_PATH);
            });
          }
        })
        .catch((error) => {
          setError(error);
          setSuccess(false);
        });
    } else {
    }
  };

  return (
    <Layout title="Verificación" className="w-100">
      <div className="wmobil-install-background"></div>
      <div class="comienza-main-container">
        <div class="comienza-card">
          {loading ? (
            <h2>Cargando</h2>
          ) : (
            <>
              <div class="comienza-card-title-container">
                <h2>!Comienza tu viaje con Wmobil!</h2>
              </div>
              <div class="comienza-text-container">
                <p>
                  Bienvenido a Wmobil, esperamos que estes listo y emocionado de
                  poder controlar todo lo que necesita tu negocio desde la
                  comodidad de la web.
                </p>
                <p>
                  Te ayudaremos y guiaremos por tu primera experiencia con
                  Wmobil, pero antes de comenzar necesitamos que verifiques la
                  direccion de correo con la que te registraste.
                </p>
                <p>
                  Hemos enviado un correo con un código de verificación a la
                  siguiente dirección:
                  <br />
                  {userEmail}
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="comienza-card-input-container">
                  <span>
                    Ingresa el código de verificación para comenzar a usar
                    Wmobil:
                  </span>
                  <div class="comienza-card-input">
                    <input
                      ref={register}
                      name="code"
                      defaultValue={""}
                      pattern="[0-9]+"
                      maxLength={6}
                      minLength={6}
                      type="text"
                    />
                  </div>
                </div>
                <div class="comienza-card-verificar-btn-container">
                  <button type="submit">Verificar Cuenta</button>
                </div>
              </form>
              <div class="comienza-card-reenviar-container">
                <p>¿No recibiste el código? Solicita uno nuevo</p>
                <button onClick={sendMailCode}>Enviar código de nuevo</button>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Verification;

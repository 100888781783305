import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const ImageDropzone = ({ currentImage, setCurrentImage }) => {
  const [error, setError] = useState(false);
  const onDropAccepted = useCallback((acceptedFiles) => {
    setCurrentImage(acceptedFiles[0]);
    setError(false);
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    setError(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "image/svg+xml, image/png",
  });

  return (
    <div
      className={`bg-gray-100 w-32 h-32 rounded shadow-inner flex items-center justify-center border ${
        error && !currentImage ? "border-red-400" : ""
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} multiple={false} />
      {currentImage ? (
        <img
          className="w-full p-4"
          src={
            typeof currentImage === "string"
              ? currentImage
              : URL.createObjectURL(currentImage)
          }
        ></img>
      ) : error ? (
        <p className="text-xs text-red-500 p-4 text-center font-medium">
          El archivo debe ser de tipo PNG o SVG...
        </p>
      ) : (
        <p className="text-sm text-gray-500 p-4 text-center font-medium">
          Arrastra el ícono aquí...
        </p>
      )}
    </div>
  );
};

export default ImageDropzone;

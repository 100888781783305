import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchReferrals, getDistributorData } from "../../api/Distributor";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import SortableTable from "../../components/SortableTable";
import { DISTRIBUTOR_REFERRALS_PATH } from "../../constants/distributorPaths";
import { DOMAIN_PATH, SIGNUP_PATH } from "../../constants/paths";

// Import copy icon
import MdCopy from "react-ionicons/lib/MdCopy";
import ClipboardCopy from "../../components/ClipboardCopy";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";

const DistributorHomePage = () => {
  // Variables for holding referrals
  const [referrals, setReferrals] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Variable for getting distributor id
  const [distributorId, setDistributorId] = useState(null);

  useEffect(() => {
    // Get distributor id
    setDistributorId(getDistributorData().id);

    // Get restaurants once
    fetchReferrals()
      .then((usrs) => {
        setReferrals(usrs);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (error) {
    return (
      <Layout title="Dashboard">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <LayoutV2 title="Dashboard" distributor>
      <div className="py-8 wrapper" style={{ paddingBottom: "18%" }}>
        <h2 className="text-2xl text-gray-800 font-bold">Referidos</h2>
        <ClipboardCopy
          value={`Regístrate a Wmobil en el siguiente enlace: ${DOMAIN_PATH}${SIGNUP_PATH}?distribuidor=${distributorId}`}
        >
          <p
            className="flex items-center text-gray-800 text-sm mt-2"
            style={{ width: "fit-content" }}
          >
            <MdCopy className="mr-1 fill-current"></MdCopy>
            Enlace de registro
          </p>
        </ClipboardCopy>
        <hr className="mt-2 mb-4"></hr>
        {!referrals || referrals.length === 0 ? (
          <div className="border rounded py-8">
            <h4 className="text-center text-xl font-bold text-gray-800 leading-tight mb-4">
              Aún no tienes usuarios referidos
            </h4>
            <div className="flex w-full justify-center">
              <ClipboardCopy
                value={`Regístrate a Wmobil en el siguiente enlace: ${DOMAIN_PATH}${SIGNUP_PATH}?distribuidor=${distributorId}`}
              >
                <p
                  className="text-base text-blue-700 font-light text-center flex"
                  style={{ width: "fit-content" }}
                >
                  <MdCopy className="mr-1 fill-current"></MdCopy>
                  Comparte tu enlace de registro
                </p>
              </ClipboardCopy>
            </div>
          </div>
        ) : (
          <SortableTable
            id="distributor-referrals-table"
            columns={columns}
            data={referrals}
          ></SortableTable>
        )}
      </div>
    </LayoutV2>
  );
};

const NameCell = ({ value }) => {
  const { id, name } = value;
  return (
    <Link to={`${DISTRIBUTOR_REFERRALS_PATH}/${id}`} className="font-medium">
      {name}
    </Link>
  );
};

const columns = [
  {
    Header: "Nombre",
    accessor: (values) => {
      return { id: values.id, name: `${values.name} ${values.lastName}` };
    },
    Cell: NameCell,
  },
  {
    Header: "Correo electrónico",
    accessor: "email",
  },
];

export default DistributorHomePage;

import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import WInput from "../WInput";
import PaymentMethodSelect from "./PaymentMethodSelect";
import WSelect from "../WSelect";
import PartialPaySectionsForm from "./PartialPaySectionsForm";
import RelatedDocsSectionForm from "./RelatedDocsSectionForm";
import { newPayComplement } from "../../api/User";
import { useToast } from "../../context/ToastContext";
import { APP_PARTIAL_PAY_LEGAL_DATA } from "../../constants/paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingModal from "../modals/LoadingModal";

const schema = yup.object().shape({
  payData: yup.object().shape({
    date: yup.string().optional(),
    payment_form: yup.string().optional(),
    currency: yup.string().optional(),
    exchange: yup.string().optional(),
    tax_id_payee: yup.string().optional().min(12).max(13),
    acc_number_payee: yup.string().optional("Ingrese un número de cuenta"),
    tax_id_payor: yup
      .string()
      .optional("Ingrese un RFC válido")
      .min(12)
      .max(13),
    acc_number_payor: yup.string().optional("Ingrese un número de cuenta"),
    bank: yup.string().optional(),
    pay_certificate: yup.string().optional(),
    pay_link: yup.string().optional(),
    pay_signature: yup.string().optional(),
    pay_type: yup.string().optional(),
    invoice_select: yup.string().optional(),
  }),
  relatedDocs: yup.object().shape({
    series: yup.string().optional(),
    folio_number: yup.string().required("Debe ingresar el folio asociado"),
    uuid: yup.string().required("Debe ingresar el UUID"),
    payment_method: yup.string().required("Seleccione método de pago"),
    prev_balance: yup
      .string()
      .required("Ingrese un monto")
      .test({
        name: "positive",
        message: "El saldo anterior debe ser mayor que cero",
        test: function (value) {
          const parsedVal = parseFloat(value);
          if (isNaN(parsedVal)) {
            return this.createError({
              message: "El saldo anterior debe ser un número válido",
            });
          }
          if (parsedVal === 0) {
            return this.createError({
              message: "El saldo anterior debe ser mayor que cero",
            });
          }
          return true;
        },
      }),
    amount: yup.string().optional(),
    balance_due: yup.string().optional(),
    currency2: yup.string().optional(),
    exchange2: yup.string().optional(),
    partiality: yup.string().required("Ingrese el número de parcialidad"),
  }),
});

const initialValues = {
  payData: {
    date: new Date().toISOString().split("T")[0],
    payment_form: "01",
    currency: "MXN",
    exchange: "1.00",
    tax_id_payee: "",
    acc_number_payee: "",
    tax_id_payor: "",
    acc_number_payor: "",
    bank: "",
    pay_certificate: "",
    pay_link: "",
    pay_signature: "",
    pay_type: "SPEI",
    invoice_select: -1,
  },
  relatedDocs: {
    series: "",
    folio_number: "",
    uuid: "",
    payment_method: "PUE",
    prev_balance: "0.00",
    amount: "0.00",
    balance_due: "0.00",
    currency2: "MXN",
    exchange2: "1.00",
    partiality: "",
  },
};

const sections = {
  payor: true,
  payee: true,
  transfer: true,
};

const NewPartialPayForm = ({ clientInput, restId, invoices, series }) => {
  const [disabledSection, setDisabledSection] = useState(sections);
  const [selectedComplement, setSelectedComplement] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const history = useHistory();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const handleSetValues = (item) => {
    const newValues = {
      folio_number: item.folio_number,
      uuid: item.uuid,
      prev_balance: item.total,
    };

    setValue("relatedDocs", newValues, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const handleSelectedComplement = (value) => {
    const index = invoices.findIndex((i) => i.uuid === value);

    if (index === -1) {
      reset();
      setSelectedComplement(null);
      return;
    }

    const selected = invoices[index];
    handleSetValues(selected);
    setSelectedComplement(selected);
  };

  const handlePaymentMethodChange = (value) => {
    const valueInt = parseInt(value);
    const typeCard = [2, 4, 5, 28, 29].includes(valueInt);
    const reset =
      !disabledSection.payor ||
      !disabledSection.payee ||
      !disabledSection.transfer;

    if (valueInt === 3) {
      setDisabledSection({
        payor: false,
        payee: false,
        transfer: false,
      });
    } else if (typeCard) {
      setDisabledSection({
        payor: false,
        payee: false,
        transfer: true,
      });
    } else if (valueInt === 6) {
      setDisabledSection({
        payor: true,
        payee: false,
        transfer: true,
      });
    } else if (reset) {
      setDisabledSection(sections);
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      values.payData.client = clientInput;
      await newPayComplement(restId, values);
      showToast("Exito al generar el complemento", "success");
      reset();
      setLoading(false);
      history.push(`${APP_PARTIAL_PAY_LEGAL_DATA}/${restId}`);
    } catch (error) {
      setLoading(false);
      showToast(
        "Error al generar el complemento, intente de nuevo mas tarde o verifique sus datos",
        "error"
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingModal isOpen={loading} />
      <div>
        <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
          Datos del pago
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center">
          <div>
            <WInput
              mandatory
              type="date"
              name="payData.date"
              label="Fecha de emision"
              ref={register}
              maxDate={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div>
            <PaymentMethodSelect
              name="payData.payment_form"
              register={register}
              onChange={(val) => handlePaymentMethodChange(val)}
            />
            <div className="error">{errors.payment_form?.message}</div>
          </div>
          <div>
            <WSelect name="payData.currency" ref={register} label="Moneda">
              <option value="MXN">MXN</option>
              <option value="USD">USD</option>
            </WSelect>
            <div className="error">{errors.currency?.message}</div>
          </div>
          <div>
            <WInput
              type="currency"
              name="payData.exchange"
              ref={register}
              label="Tipo de cambio"
            />
            <div className="error">{errors.exchange?.message}</div>
          </div>
        </div>
        <PartialPaySectionsForm
          sections={disabledSection}
          errors={errors}
          ref={register}
        />
        <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
          Documentos relacionados
        </label>
        <RelatedDocsSectionForm
          clientInput={clientInput}
          invoices={invoices}
          selectedComplement={selectedComplement}
          series={series}
          errors={errors}
          ref={register}
          invoiceChange={(val) => handleSelectedComplement(val)}
        />
        <button className="wfacturas-button mt-8" type="submit">
          Emitir complemento
        </button>
      </div>
    </form>
  );
};

export default NewPartialPayForm;

import React from "react";

import LogoWhatsapp from "react-ionicons/lib/LogoWhatsapp";

const WhatsappButton = () => {
  return (
    <a
      className="bottom-0 right-0 md:p-4 p-3 rounded-full m-4 shadow-md fixed hover-pointer"
      style={{
        backgroundColor: "#25D366",
        zIndex: "10",
      }}
      href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`}
      target="blank"
      rel="nofollow"
    >
      <LogoWhatsapp className="text-white fill-current md:w-8 md:h-8 w-6 h-6"></LogoWhatsapp>
    </a>
  );
};

export default WhatsappButton;

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getUserData } from "../../api/User";
import * as yup from "yup";

import { NavLink, useHistory } from "react-router-dom";
import {
  APP_HOME_PATH,
  APP_MY_ACCOUNT_PATH,
  INSTALL_PATH,
  MY_PAYMENT_METHODS_PATH,
  MY_SUBSCRIPTIONS_PATH,
  APP_MY_FACTURAS_PATH,
  APP_SEARCH_FOLIO_FACTURA,
} from "../../constants/paths";

import {
  ADMIN_TOOLS_PATH,
  ADMIN_CONNECTIONS_PATH,
  ADMIN_HOME_PATH,
} from "../../constants/adminPaths";

import MdHome from "react-ionicons/lib/MdHome";
import { MdWifiTetheringErrorRounded } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import { MdArrowBack } from "react-icons/md";
import MdAlbums from "react-ionicons/lib/MdAlbums";
import MdLogOut from "react-ionicons/lib/MdLogOut";
import MdRestaurant from "react-ionicons/lib/MdRestaurant";
import MdBook from "react-ionicons/lib/MdBook";
import { FaFileInvoiceDollar } from "react-icons/fa";

import { signOut, signIn } from "../../api/Auth";
import { ADMIN_MY_ACCOUNT_PATH } from "../../constants/adminPaths";
import { DISTRIBUTOR_MY_ACCOUNT_PATH } from "../../constants/distributorPaths";
import { NotificationContext } from "../../context/NotificationContext";
import { useTranslation } from "react-i18next";
import NotificationWrapper from "./NotificationWrapper";

const ProfileUserLinks = ({ admin, distributor, show, setShow }) => {
  const { t } = useTranslation();
  // const { notificationCount } = useContext(NotificationContext);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({});
  const [adminSessionData, setAdminSessionData] = useState({});
  const history = useHistory();

  //If has admin session
  const adminSession = window.localStorage.getItem("adminSession")
    ? true
    : false;

  useEffect(() => {
    const adminUserSession = JSON.parse(localStorage.getItem("adminSession"));
    if (adminUserSession) {
      setAdminSessionData({
        email: adminUserSession.email,
        password: "123456",
        userEmail: adminUserSession.userEmail,
      });
    } else return;
  }, []);

  const handleRestoreAdminUserSession = () => {
    if (
      window.confirm(
        `Desea restaurar la sesión de ${adminSessionData.email} y terminar la sesión de: ${adminSessionData.userEmail} ?`
      )
    ) {
      signOut(adminSessionData.userEmail)
        .then(() => {
          signIn(adminSessionData)
            .then(() => {
              history.push(ADMIN_HOME_PATH);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else return;
  };

  // Instantiate form
  const { reset } = useForm({
    defaultValues: userData,
  });

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    if (success) setUserData(getUserData());
  }, [success]);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  //Para admin
  if (admin) {
    return (
      <div className="profile-navigation bg-white-100 px-4 border-gray-200 dropdown-menu-account">
        <div
          className="menu-account sticky top-0 pt-8"
          onClick={() => setShow(!show)}
        >
          <NavLink
            to={ADMIN_HOME_PATH}
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
          >
            <MdBook className="fill-current"></MdBook>
            <span class="ml-4">{t("navBar.users")}</span>
          </NavLink>
          <NavLink
            to={ADMIN_CONNECTIONS_PATH}
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
          >
            <MdWifiTetheringErrorRounded className="fill-current"></MdWifiTetheringErrorRounded>
            <span class="ml-4">{t("navBar.conn")}</span>
            {/* <NotificationWrapper notificationAlertCount={notificationCount} /> */}
          </NavLink>
          <NavLink
            to={ADMIN_TOOLS_PATH}
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
          >
            <MdSettings className="fill-current"></MdSettings>
            <span class="ml-4">{t("navBar.tools")}</span>
          </NavLink>
          <NavLink
            to=""
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
            onClick={(e) => {
              e.preventDefault();
              if (
                window.confirm(
                  "¿Seguro que deseas cerrar sesión? \nEsto te permitira iniciar sesión en otro navegador"
                )
              )
                signOut(userData.email);
            }}
          >
            <MdLogOut className="fill-current"></MdLogOut>
            <span class="ml-4">{t("navBar.signOut")}</span>
          </NavLink>
        </div>
      </div>
    );
  }
  //Para distribuidores
  if (distributor) {
    return (
      <div className="profile-navigation bg-white-100 shadow-md px-4 border-r border-gray-200 relative dropdown-menu-account">
        <div className="menu-account sticky top-0 pt-8">
          <NavLink
            to={DISTRIBUTOR_MY_ACCOUNT_PATH}
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
          >
            <MdHome className="fill-current"></MdHome>
            <span class="ml-4">{t("navBar.myAcc")}</span>
          </NavLink>
          <NavLink
            to=""
            exact
            activeClassName="active"
            className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
            onClick={(e) => {
              e.preventDefault();
              if (
                window.confirm(
                  "¿Seguro que deseas cerrar sesión? \nEsto te permitira iniciar sesión en otro navegador"
                )
              )
                signOut(userData.email);
            }}
          >
            <MdLogOut className="fill-current"></MdLogOut>
            <span class="ml-4">{t("navBar.signOut")}</span>
          </NavLink>
        </div>
      </div>
    );
  }

  //default User
  return (
    <div className="profile-navigation bg-white-100 shadow-md px-4 border-r border-gray-200">
      <div className="menu-account sticky top-0 pt-8">
        {adminSession && (
          <button
            className="wfacturas-button mt-4 md:mt-0"
            onClick={() => handleRestoreAdminUserSession()}
          >
            {" "}
            <MdArrowBack />
            Back to admin
          </button>
        )}
        <NavLink
          to={APP_MY_ACCOUNT_PATH}
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
        >
          <MdHome className="fill-current"></MdHome>
          <span class="ml-4">{t("navBar.myAcc")}</span>
        </NavLink>
        <NavLink
          to={MY_SUBSCRIPTIONS_PATH}
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
        >
          <MdAlbums className="fill-current"></MdAlbums>
          <span class="ml-4">{t("navBar.subscriptions")}</span>
        </NavLink>
        <NavLink
          to={APP_HOME_PATH}
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
        >
          <MdRestaurant className="fill-current"></MdRestaurant>
          <span class="ml-4">{t("navBar.rest")}</span>
        </NavLink>
        <NavLink
          to={INSTALL_PATH}
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
        >
          <MdBook className="fill-current"></MdBook>
          <span class="ml-4">{t("navBar.tutorials")}</span>
        </NavLink>
        <NavLink
          to={APP_SEARCH_FOLIO_FACTURA}
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
        >
          <MdBook className="fill-current"></MdBook>
          <span class="ml-4">WFacturas</span>
        </NavLink>
        <NavLink
          to=""
          exact
          activeClassName="active"
          className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900"
          onClick={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "¿Seguro que deseas cerrar sesión? \nEsto te permitira iniciar sesión en otro navegador"
              )
            )
              signOut(userData.email); //cerrar la sesion
          }}
        >
          <MdLogOut className="fill-current"></MdLogOut>
          <span class="ml-4">{t("navBar.signOut")}</span>
        </NavLink>{" "}
      </div>
    </div>
  );
};

export default ProfileUserLinks;

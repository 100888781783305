import React, { useState, useEffect } from "react";
//import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  fetchRegularUser,
  getFacturapiData,
  updateFacturapiData,
  updateFacturapiPlanData,
} from "../../api/Admin";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  ADMIN_HOME_PATH,
  ADMIN_RESTAURANTS_PATH,
  ADMIN_USERS_PATH,
} from "../../constants/adminPaths";
import { STRIPE_CUSTOMER_PATH } from "../../constants/secrets";
import {
  APP_HOME_PATH,
  HOME_PATH_FACTURACION,
  PAYMENT_LINK_PATH,
} from "../../constants/paths";

import MdLink from "react-ionicons/lib/MdLink";
import MdMail from "react-ionicons/lib/MdMail";
import { MdSettings } from "react-icons/md";
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import SortableTable from "../../components/SortableTable";
import { Link } from "react-router-dom";
import DotComponent from "../../components/DotComponent";
import {
  deleteRestaurant,
  getImpersonatedUserFromAdmin,
} from "../../api/Admin";
import { useHistory } from "react-router-dom";

import { getUserData } from "../../api/User";
import { signIn, signInFromAdmin, signOut } from "../../api/Auth";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { API_PATH } from "../../constants/api";
import Modal from "react-modal";
import html2canvas from "html2canvas";
import QrGenerationModal from "./QrGenerationModal";
import EditFacturapiDataModal from "./EditFacturapidataModal";

const AdminUserPage = ({ match }) => {
  const { t } = useTranslation();
  // Variables for handling user data from API.
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ticketExpiration, setTicketExpiration] = useState(null);
  const [userData, setUserData] = useState({});
  const [qrLink, setQrLink] = useState("");
  const [open, setOpen] = useState(false);
  const [restId, setRestId] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Get restaurants once
    fetchRegularUser(match.params.id)
      .then((usr) => {
        setUser(usr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Data for table
  const NameCell = ({ value }) => {
    const { user_id, id, name } = value;
    return (
      <Link
        to={`${ADMIN_USERS_PATH}/${user_id}/restaurantes/${id}`}
        className="font-medium"
      >
        {name}
      </Link>
    );
  };

  const ActiveCell = ({ value }) => {
    return <DotComponent status={value}></DotComponent>;
  };

  const deleteRestaurants = (value) => {
    if (
      window.confirm(
        "Realmente quieres eliminar la licencia y el restaurante completamente" +
          value.name
      )
    ) {
      deleteRestaurant(value.id)
        .then((res) => {
          history.go(0);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };

  const DeleteCell = ({ value }) => {
    const { id, name } = value;
    return (
      <button onClick={() => deleteRestaurants(value)}>
        {t("admin.delete")}
      </button>
    );
  };

  const GenerateQr = ({ value }) => {
    const { id, expiration } = value;
    return (
      <button onClick={() => returnQrFactura(id, expiration)}>
        Generar QR
      </button>
    );
  };

  const FacturapiSettings = ({ value }) => {
    const { id } = value;
    return <button onClick={() => returnSettingsModal(id)}>Editar</button>;
  };

  const handleOpenUserAccount = () => {
    if (
      window.confirm(
        `Desea cerrar sesión de ${userData.email} e iniciar como: ${user.email} ?`
      )
    ) {
      getImpersonatedUserFromAdmin(user.id)
        .then((impersonatedData) => {
          signOut(userData.email)
            .then(() => {
              signInFromAdmin(impersonatedData, {
                ...userData,
                userEmail: user.email,
              })
                .then(() => {
                  history.push(APP_HOME_PATH);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };

  const columns = [
    {
      Header: t("admin.stat"),
      accessor: (values) => {
        return values.active;
      },
      Cell: ActiveCell,
    },
    {
      Header: t("subsPage.subFormRest"),
      accessor: (values) => {
        return { id: values.id, name: values.text, user_id: user.id };
      },
      Cell: NameCell,
    },
    { Header: t("admin.db"), accessor: "dbString" },
    {
      Header: t("admin.delete") + " " + t("subsPage.lic"),
      accessor: (values) => {
        return { id: values.id, name: values.text };
      },
      Cell: DeleteCell,
    },
    {
      Header: "Factura",
      accessor: (values) => {
        return { id: values.id, expiration: values.expirationHours };
      },
      Cell: GenerateQr,
    },
    {
      Header: "Facturapi",
      accessor: (values) => {
        return { id: values.id };
      },
      Cell: FacturapiSettings,
    },
  ];

  const returnShareableLink = async () => {
    const paymentLink = `${window.location.origin}${PAYMENT_LINK_PATH}=${user.id}`;
    try {
      await navigator.clipboard.writeText(paymentLink);

      window.alert("Copiado al portapapeles!");
    } catch (error) {
      console.error("Failed to copy payment link:", error);
    }
  };

  const returnQrFactura = async (id, expiration) => {
    try {
      setQrLink(`https://wfacturas.com/facturacion/${id}`);
      setTicketExpiration(expiration);
      setOpen(true);
    } catch (error) {}
  };

  const returnSettingsModal = async (id) => {
    try {
      const facturapiData = await getFacturapiData(id);

      user.restaurants.map((rest) => {
        if (rest.id === id) {
          rest.facturapi_id = facturapiData.facturapi.facturapi_id;
          rest.facturapi_key = facturapiData.facturapi.facturapi_key;
          rest.facturapi_user_key = facturapiData.facturapi.facturapi_user_key;
          rest.wfacturas_updated = facturapiData.facturapi.wfacturas_updated;
          rest.wfacturas_duedate = facturapiData.facturapi.wfacturas_duedate;
          rest.wfacturas_bells = facturapiData.facturapi.wfacturas_bells;
          return;
        }
      });

      setRestId(id);
      setOpenEditModal(true);
    } catch (error) {}
  };

  const downloadImage = () => {
    const element = document.getElementById("capture");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "qrImage.png";
      link.click();
    });
  };

  const editFacturapiKeys = async (facturapiId, facturapiKey) => {
    const data = {
      rest_id: restId,
      facturapi_id: facturapiId,
      facturapi_key: facturapiKey,
    };
    try {
      await updateFacturapiData(data);
      window.alert("Facturapi data updated successfully");
    } catch (error) {
      window.alert(
        "Hubo un error al intentar actualizar los datos, intente de nuevo mas tarde"
      );
    }
  };

  const editFacturapiPlan = async (
    wfacturasUpdated,
    wfacturasDuedate,
    wfacturasBells
  ) => {
    const data = {
      rest_id: restId,
      wfacturas_updated: wfacturasUpdated,
      wfacturas_duedate: wfacturasDuedate,
      wfacturas_bells: wfacturasBells,
    };
    try {
      await updateFacturapiPlanData(data);
      window.alert("Facturapi plan updated successfully");
    } catch (error) {
      window.alert(
        "Hubo un error al intentar actualizar los datos del plan, intente de nuevo mas tarde"
      );
    }
  };

  document.body.style.overflow = open ? "hidden" : "";

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <>
      <QrGenerationModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        qrLink={qrLink}
        downloadImage={downloadImage}
        expiration={ticketExpiration}
        contentLabel="Example Modal"
        className="py-10 wrapper relative tab-rep-resp items-center"
      />
      {openEditModal && (
        <EditFacturapiDataModal
          isOpen={openEditModal}
          onRequestClose={() => setOpenEditModal(false)}
          restId={restId}
          editFacturapiKeys={editFacturapiKeys}
          editFacturapiPlan={editFacturapiPlan}
          facturapiData={user.restaurants}
          contentLabel="Edit modal"
          className="py-10 wrapper relative tab-rep-resp items-center"
        />
      )}
      <Layout title={`${user.name} ${user.lastName}`} admin>
        <div className="py-8 wrapper main-admin-page-padding">
          <BackArrow
            to={ADMIN_HOME_PATH}
            title={t("installPage.backBtn")}
          ></BackArrow>
          <div className="flex justify-between items-end text-gray-700 resp-link">
            <div>
              <h2 className="text-2xl text-gray-800 font-bold">
                {`${user.name} ${user.lastName}`}
              </h2>
              <p className="text-sm mb-4">
                {t("admin.userProfLabel1")}{" "}
                {dayjs(user.created_at)
                  .locale(t("admin.lan"))
                  .format(
                    `dddd D [${t("admin.userProfLabel2")}] MMMM [${t(
                      "admin.userProfLabel3"
                    )}] YYYY`
                  )}
              </p>
              <a
                href={`mailto:${user.email}`}
                className="text-blue-700 text-sm"
              >
                <MdMail className="fill-current mr-1 inline-block w-4" />
                {user.email}
              </a>
              <div
                onClick={() => handleOpenUserAccount()}
                //target="_blank"
                className="flex items-center text-gray-700 text-base cursor-pointer"
              >
                <MdSettings className="fill-current mr-1 inline-block w-6" />
                {t("admin.userProfLabel4")}
              </div>
            </div>
            <div
              onClick={() => returnShareableLink()}
              //href={`${PAYMENT_LINK_PATH}=${user.id}`}
              className="flex items-center text-gray-700 text-base cursor-pointer"
            >
              <MdLink className="fill-current mr-1 inline-block w-6"></MdLink>
              {t("admin.userProfLabel5")}
            </div>
            <a
              href={`${STRIPE_CUSTOMER_PATH}/${user.stripe_id}`}
              target="_blank"
              className="flex items-center text-gray-700 text-base"
            >
              <MdLink className="fill-current mr-1 inline-block w-6"></MdLink>
              {t("admin.userProfLabel6")}
            </a>
          </div>
          <hr className="mt-2 mb-4"></hr>
          <div>
            <h2 className="text-xl text-gray-800 font-bold">
              {t("navBar.rest")}
            </h2>
            <h3 className="text-xl text-gray-800 font-bold">
              {t("admin.useProfileText1")}
            </h3>
            <SortableTable
              id={`user-${user.id}-restaurants`}
              data={user.restaurants}
              columns={columns}
            ></SortableTable>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AdminUserPage;

import React from "react";
import ForgotPasswordForm from "../../components/auth/forms/ForgotPasswordForm";
import Layout from "../../components/Layout";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Layout title="Olvidar contraseña" className="mx:px-0 px-4">
      <div className="max-w-md py-12 mx-auto">
        <h2 className="text-gray-800 font-bold text-3xl">
          {t("signInForm.modalTitle1")}
        </h2>
        <p className="mt-2 mb-8 block text-base text-gray-500 font-normal">
          {t("signInForm.modalSubtitle")}
        </p>
        <ForgotPasswordForm></ForgotPasswordForm>
      </div>
    </Layout>
  );
};

export default ForgotPassword;

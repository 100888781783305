import React from "react";

import { ResponsivePie } from "@nivo/pie";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PieChart = ({ data, customProperties, title, className, colors }) => {
  return (
    <div
      className={`w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ${className}`}
    >
      <div className="text-sm text-gray-800 font-medium mb-2">{title}</div>
      <div style={{ height: className ? "200px" : "300px" }}>
        <ResponsivePie
          data={data.filter(({ value }) => value > 0)}
          fit
          {...customProperties}
          innerRadius={0.6}
          padAngle={2}
          cornerRadius={5}
          radialLabel={(d) =>
            `${
              className !== "distributor-dashboard-chart" ? d.label + ":" : ""
            } ${formatter.format(d.value)}`
          }
          radialLabelsLinkStrokeWidth={1}
          enableSlicesLabels={false}
          borderWidth={1}
          colors={colors}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          tooltip={({ datum }) => {
            const { value, id } = datum;

            return (
              <div className="bg-white rounded-sm py-1 px-2 shadow-xs">
                <span className="text-xs uppercase font-bold">{id}: </span>
                <span className="text-sm">{formatter.format(value)}</span>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default PieChart;

import React from "react";

import { Link } from "react-router-dom";

const BackArrow = ({ to, title }) => {
  return (
    <Link to={to} className="flex items-center mb-2">
      <span className="text-gray-500 mr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-current h-3"
        >
          <path
            class="heroicon-ui"
            d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"
          />
        </svg>
      </span>
      <span className="text-gray-500 text-sm">{title}</span>
    </Link>
  );
};

export default BackArrow;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingIndicator from "../LoadingIndicator";
import { useTranslation } from "react-i18next";
import {
  getFolioSettings,
  getFoliosFromRest,
  getTimbresData,
} from "../../api/Utils";
import PendingFoliosTable from "../restaurant/views/PendingFoliosTable";
import WInput from "../WInput";
import WSelect from "../WSelect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  APP_CREATE_FACTURA,
  APP_CREATE_GENERAL_FACTURA,
  APP_PARTIAL_PAY_LEGAL_DATA,
} from "../../constants/paths";
import { useToast } from "../../context/ToastContext";
import { Checkbox } from "@mui/material";
import { useTimbres } from "../../context/TimbresContext";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";
import { getProfilePicKey } from "../../api/User";
import { useProfilePic } from "../../context/ProfilePicContext";
import { updateIndividualFolioExpoiration } from "../../api/Restaurant";
import Modal from "react-modal";
import WFacturasInvoiceTypeModal from "../modals/WFacturasInvoiceTypeModal";

const schema = yup.object().shape({
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  restId: yup.string().required(),
});

const SearchFacturaForm = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const { pictureKey, updatePictureKey } = useProfilePic();
  const { showToast } = useToast();
  const { timbresCount, updateTimbresCount } = useTimbres();
  const { restaurants, restId, setRestId, loading, setLoading } =
    useRestsDropdown();

  const [generalDescription, setGeneralDescription] = useState("");
  const [includeTip, setIncludeTip] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);
  const [foliosNoFacturados, setFoliosNoFacturados] = useState([]);
  const [selectedFolios, setSelectedFolios] = useState([]);
  const [selectedWfNumcheques, setSelectedWfNumcheques] = useState([]);
  const [deselectedFolios, setDeselectedFolios] = useState([]); // DESELECTED
  const [selectAll, setSelectAll] = useState(false);
  const [blockInvoice, setBlockInvoice] = useState(false);
  const [folioSearchDates, setFolioSearchDates] = useState(null);
  const [folioFirstDate, setFolioFirstDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [folioEndDate, setFolioEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const minDate = new Date();
  const currentDayOfMonth = minDate.getDate();
  minDate.setMonth(minDate.getMonth() - 1);
  minDate.setDate(1);

  if (currentDayOfMonth >= 4) {
    minDate.setMonth(minDate.getMonth() + 1);
  }
  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const getFoliosNoFacturados = (id) => {
    setLoading(true);

    getTimbresData(id)
      .then((timbresReponse) => {
        updateTimbresCount(timbresReponse.timbres);
      })
      .catch((error) => {
        setLoading(false);
        setFoliosNoFacturados([]);
      });

    getFoliosFromRest(id)
      .then((allFolios) => {
        setFoliosNoFacturados(allFolios);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setFoliosNoFacturados([]);
      });
  };

  const handleRestChange = (value) => {
    setRestId(value);
    const match = restaurants.find(({ id }) => id === Number(value));
    getFoliosNoFacturados(value);
  };

  const handleGetProfilePicKey = async (id) => {
    const storedPicKey = localStorage.getItem("pictureKey");
    if (storedPicKey === "" || !storedPicKey) {
      const profilePicKey = await getProfilePicKey(id);
      if (profilePicKey) {
        updatePictureKey(profilePicKey);
      } else {
        updatePictureKey("");
      }
    }
  };

  const handleSaveHours = async (data) => {
    try {
      const { hours, folio } = data;
      const hoursInt = parseInt(hours);
      await updateIndividualFolioExpoiration(restId, folio, hoursInt);
      showToast("Vigencia actualizada correctamente", "success");
      await fetchFolios();
    } catch (error) {
      showToast("No se pudo actualizar, intente de nuevo mas tarde", "error");
    }
  };

  const fetchFolios = async () => {
    try {
      setLoading(true);
      handleGetProfilePicKey(restId);

      const folios = await getFoliosFromRest(restId);

      setFoliosNoFacturados(folios);
      setFolioEndDate(folios[0].fecha.split("T")[0]);
      setFolioFirstDate(folios[folios.length - 1].fecha.split("T")[0]);
      setFolioSearchDates({
        folio_first_date: folios[folios.length - 1].fecha.split("T")[0],
        folio_end_date: folios[0].fecha.split("T")[0],
      });
      setLoading(false);

      const userSettings = await getFolioSettings(restId);
      const shouldAssignGeneralDesc =
        userSettings.general_description ||
        userSettings.general_description !== "";

      if (shouldAssignGeneralDesc) {
        setGeneralDescription(userSettings.general_description);
      }

      if (!userSettings.folio_duedate || !userSettings.folio_start) {
        setBlockInvoice(true);
      }
    } catch (error) {
      setLoading(false);
      setFoliosNoFacturados([]);
    }
  };

  useEffect(() => {
    if (restId) {
      fetchFolios();
    }
  }, [restId]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const endDate = new Date(values.end_date);
      endDate.setDate(endDate.getDate() + 1);
      const formattedEndDate = `${endDate.toISOString().split("T")[0]}T00:00:00`;
      values.start_date += "T05:59:59";
      values.end_date = formattedEndDate;
      setFolioSearchDates({
        folio_first_date: values.start_date,
        folio_end_date: formattedEndDate,
      });
      const cheqs = await getFoliosFromRest(
        values.restId,
        values.start_date,
        values.end_date
      );
      setFoliosNoFacturados(cheqs);
      setFolioEndDate(cheqs[0].fecha.split("T")[0]);
      setFolioFirstDate(cheqs[cheqs.length - 1].fecha.split("T")[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectionChange = (selection) => {
    setSelectedFolios(selection);
    if (includeTip && selectedFolios.length === 0) {
      setIncludeTip(false);
    }
  };

  const handleWfNumchequeSelectionChange = (selection) => {
    setSelectedWfNumcheques(selection);
  };

  const handleDeselectionChange = (selection) => {
    setDeselectedFolios(selection);
  };

  const handleIncludeTip = (event) => {
    setIncludeTip(event.target.checked);
  };

  const handleInvoiceType = (type) => {
    if (!blockInvoice) {
      if (type === "invoice") {
        showToast(
          "Sin folios seleccionados, usando plantilla de factura general",
          "custom"
        );
        history.push(`${APP_CREATE_GENERAL_FACTURA}/${restId}`);
      } else {
        history.push(`${APP_PARTIAL_PAY_LEGAL_DATA}/${restId}`);
      }
    } else {
      showToast(
        "Commplete la configuración del perfil para poder usar el sistema",
        "custom"
      );
    }
  };

  const openNewFactura = () => {
    if (timbresCount === 0) {
      showToast("Has consumido todos tus timbres", "custom");
      return;
    }
    if (selectedFolios.length > 0 && !blockInvoice) {
      history.push({
        pathname: `${APP_CREATE_FACTURA}/${restId}`,
        state: {
          selectedFolios: selectedFolios,
          selectedWfNumcheques: selectedWfNumcheques,
          deselectedFolios: deselectedFolios,
          generalDescription: generalDescription,
          includeTips: includeTip,
          folioStartDate: folioSearchDates?.folio_first_date,
          folioEndDate: folioSearchDates?.folio_end_date,
          selectAll: selectAll,
        },
      });
    } else {
      setPartialPayment(true);
    }
  };

  return (
    <div className="flex flex-col w-full mt-12 md:mt-0">
      <WFacturasInvoiceTypeModal
        partialPayment={partialPayment}
        onRequestClose={() => setPartialPayment(false)}
        handleInvoiceType={(val) => handleInvoiceType(val)}
      />
      <div className="p-8">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <div className="flex flex-col justify-center">
              <h3 className="facturas-title">Folios pendientes</h3>
              <text className="text-md">
                En esta sección están los folios que no han sido facturados.
              </text>
            </div>
            <div className="flex items-center">
              <span
                onClick={openNewFactura}
                className="wfacturas-button cursor-pointer"
              >
                Nueva factura
              </span>
            </div>
          </div>
          <div className="w-full mt-4">
            <WSelect
              mandatory
              name="restId"
              ref={register}
              label="Seleccione sucursal"
              defaultValue={restaurants}
              onChange={(value) => handleRestChange(value)}
            >
              {restaurants?.map((rest, index) => (
                <option key={index} value={rest.id}>
                  {rest.text}
                </option>
              ))}
            </WSelect>
          </div>
          {!loading && (
            <div className="flex flex-col md:flex-row items-start md:items-center input-wrapper mt-4 mb-4 justify-start">
              <WInput
                name="start_date"
                type="date"
                label={"Fecha de inicio"}
                ref={register}
                maxDate={new Date().toISOString().split("T")[0]}
                defaultValue={folioFirstDate}
              />
              <div className="px-4">al</div>

              <WInput
                name="end_date"
                type="date"
                label={"Fecha final"}
                ref={register}
                maxDate={new Date().toISOString().split("T")[0]}
                defaultValue={folioEndDate}
              />
              <div className="p-4 " type="submit">
                <button className="wfacturas-button">Buscar</button>
              </div>
              {selectedFolios.length > 0 && (
                <div className="flex w-full items-center justify-between">
                  <div className="flex w-full items-center justify-start">
                    <Checkbox onChange={handleIncludeTip} />
                    <label className="wfacturas-table-text">
                      Facturar propina
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
        {loading ? (
          <LoadingIndicator />
        ) : foliosNoFacturados?.length > 0 ? (
          <PendingFoliosTable
            cheques={foliosNoFacturados}
            selectAll={selectAll}
            setSelectAll={(val) => setSelectAll(val)}
            onSelectionChange={handleSelectionChange}
            onNumchequeSelectionChange={handleWfNumchequeSelectionChange}
            onDeselectionChange={handleDeselectionChange}
            selectedFolios={selectedFolios}
            handleSaveHours={(data) => handleSaveHours(data)}
          />
        ) : (
          <p className="md:text-base text-sm text-center mt-6 font-medium">
            No se encontraron folios
          </p>
        )}
      </div>
    </div>
  );
};

export default SearchFacturaForm;

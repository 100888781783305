import React from "react";
import { useTranslation } from "react-i18next";
import WFacturasLayout from "../../components/WFacturasLayout";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import PlansForm from "../../components/account/PlansForm";

const PlansPage = () => {
  const { t } = useTranslation();

  return (
    <WFacturasLayout title="Mis suscripciones" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <PlansForm />
      </div>
    </WFacturasLayout>
  );
};

export default PlansPage;

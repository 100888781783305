import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { fetchUserProfile } from "../../api/Auth";
import { ADMIN_HOME_PATH } from "../../constants/adminPaths";
import { APP_HOME_PATH, SIGNIN_PATH } from "../../constants/paths";

const UserIsDistributorRoute = ({ children }) => {
  // State variable for tracking auth status.
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("token") ? true : false
  );

  // State variable for tracking auth type.
  const [profileType, setProfileType] = useState("loading");

  useEffect(() => {
    // Set an event listener for local storage.
    window.addEventListener("storage", reactToTokenChange);

    // Remove event listener.
    return () => {
      window.removeEventListener("storage", reactToTokenChange);
    };
  }, []);

  // Function to lookup user and determine if it's a regular user, an admin or a distributor
  useEffect(() => {
    // If user is authenticated, check the token
    if (isAuth) {
      fetchUserProfile().then(({ role }) => {
        setProfileType(role);
      });
    } else {
      setProfileType("loaded");
    }
  }, [isAuth]);

  // Function to react to token change in local storage.
  const reactToTokenChange = () => {
    setIsAuth(window.localStorage.getItem("token") ? true : false);
  };

  // Return nothing while profile type is loading
  if (profileType === "loading") return <></>;

  // Check if the user is a regular user.
  if (isAuth && profileType === "user")
    return <Redirect to={APP_HOME_PATH}></Redirect>;

  // Check if the user is an admin.
  if (isAuth && profileType === "admin")
    return <Redirect to={ADMIN_HOME_PATH}></Redirect>;

  // If user is authenticated and is a distributor return the proper route.
  if (isAuth && profileType === "distributor") return <>{children}</>;

  // Else, redirect to login page.
  return <Redirect to={SIGNIN_PATH}></Redirect>;
};

export default UserIsDistributorRoute;

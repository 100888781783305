import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PreviewCard from "../../components/restaurant/PreviewCard";

import { Link, useHistory } from "react-router-dom";
import { getAllGroups } from "../../api/Groups";
import { getfechasAtrasadas, getRestaurants } from "../../api/Restaurant";
import { getLicenses } from "../../api/User";
import LoadingIndicator from "../../components/LoadingIndicator";
import Select from "../../components/Select";
import { MdArrowBack } from "react-icons/md";
import {
  GROUP_PATH,
  INTRODUCTION_PATH,
  NEW_GROUP_PATH,
  NEW_SUBSCRIPTION_PATH,
} from "../../constants/paths";
import { ADMIN_HOME_PATH } from "../../constants/adminPaths";
import MailVerification from "./MailVerification";
import { signIn, signOut } from "../../api/Auth";

import DateApi from "date-and-time";
import MdAddCircle from "react-ionicons/lib/MdAddCircle";
import MdCreate from "react-ionicons/lib/MdCreate";
import SubscriptionItemNoInstalled from "../../components/subscriptions/SubscriptionItemNoInstalled";
import SubscriptionPaymentPending from "../../components/subscriptions/SubscriptionPaymentPending.js";
import { useTranslation } from "react-i18next";

var timedWeekOptions = [
  { value: "actual", title: "Semana Actual" },

  { value: "semana_52", title: "Semana 52" },
  { value: "semana_51", title: "Semana 51" },

  { value: "semana_50", title: "Semana 50" },
  { value: "semana_49", title: "Semana 49" },
  { value: "semana_48", title: "Semana 48" },
  { value: "semana_47", title: "Semana 47" },
  { value: "semana_46", title: "Semana 46" },

  { value: "semana_45", title: "Semana 45" },
  { value: "semana_44", title: "Semana 44" },
  { value: "semana_43", title: "Semana 43" },
  { value: "semana_42", title: "Semana 42" },
  { value: "semana_41", title: "Semana 41" },
  //4
  { value: "semana_40", title: "Semana 40" },
  { value: "semana_39", title: "Semana 39" },
  { value: "semana_38", title: "Semana 38" },
  { value: "semana_37", title: "Semana 37" },
  { value: "semana_36", title: "Semana 36" },

  { value: "semana_35", title: "Semana 35" },
  { value: "semana_34", title: "Semana 34" },
  { value: "semana_33", title: "Semana 33" },
  { value: "semana_32", title: "Semana 32" },
  { value: "semana_31", title: "Semana 31" },
  //3
  { value: "semana_30", title: "Semana 30" },
  { value: "semana_29", title: "Semana 29" },
  { value: "semana_28", title: "Semana 28" },
  { value: "semana_27", title: "Semana 27" },
  { value: "semana_26", title: "Semana 26" },

  { value: "semana_25", title: "Semana 25" },
  { value: "semana_24", title: "Semana 24" },
  { value: "semana_23", title: "Semana 23" },
  { value: "semana_22", title: "Semana 22" },
  { value: "semana_21", title: "Semana 21" },
  //2
  { value: "semana_20", title: "Semana 20" },
  { value: "semana_19", title: "Semana 19" },
  { value: "semana_18", title: "Semana 18" },
  { value: "semana_17", title: "Semana 17" },
  { value: "semana_16", title: "Semana 16" },

  { value: "semana_15", title: "Semana 15" },
  { value: "semana_14", title: "Semana 14" },
  { value: "semana_13", title: "Semana 13" },
  { value: "semana_12", title: "Semana 12" },
  { value: "semana_11", title: "Semana 11" },
  //1
  { value: "semana_10", title: "Semana 10" },
  { value: "semana_9", title: "Semana 9" },
  { value: "semana_8", title: "Semana 8" },
  { value: "semana_7", title: "Semana 7" },
  { value: "semana_6", title: "Semana 6" },

  { value: "semana_5", title: "Semana 5" },
  { value: "semana_4", title: "Semana 4" },
  { value: "semana_3", title: "Semana 3" },
  { value: "semana_2", title: "Semana 2" },
  { value: "semana_1", title: "Semana 1" },
];

var weekRanges = [];
var monthRanges = [];

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var timedMonthOptions = [
  { value: "actual", title: 0 },
  { value: "month_12", title: 12 },
  { value: "month_11", title: 11 },
  { value: "month_10", title: 10 },
  { value: "month_9", title: 9 },

  { value: "month_8", title: 8 },
  { value: "month_7", title: 7 },
  { value: "month_6", title: 6 },
  { value: "month_5", title: 5 },

  { value: "month_4", title: 4 },
  { value: "month_3", title: 3 },
  { value: "month_2", title: 2 },
  { value: "month_1", title: 1 },
];

const DashboardPage = () => {
  const { t } = useTranslation();

  const historicOptions = [
    { value: "total", title: "Total" },
    { value: "efectivo", title: t("dashboard.dropdownOps.cash") },
    { value: "tarjeta", title: t("dashboard.dropdownOps.card") },
    { value: "otros", title: t("dashboard.dropdownOps.other") },
    { value: "totalFacturado", title: t("dashboard.dropdownOps.chargedTotal") },
    { value: "totalCostos", title: t("dashboard.dropdownOps.totalCost") },
    { value: "totalutilidad", title: t("dashboard.dropdownOps.profit") },
  ];

  const liveOptions = [
    { value: "total", title: "Total" },
    { value: "efectivo", title: t("dashboard.dropdownOps.cash") },
    { value: "tarjeta", title: t("dashboard.dropdownOps.card") },
    { value: "otros", title: t("dashboard.dropdownOps.other") },
    { value: "efectivoEnCaja", title: t("dashboard.dropdownOps.cashReg") },
    { value: "retiros", title: t("dashboard.dropdownOps.withdr") },
    { value: "depositos", title: t("dashboard.dropdownOps.deposits") },
    { value: "totalFacturado", title: t("dashboard.dropdownOps.chargedTotal") },
    { value: "totalCostos", title: t("dashboard.dropdownOps.totalCost") },
    { value: "totalutilidad", title: t("dashboard.dropdownOps.profit") },
  ];

  const globalOptions = [
    { value: "online", title: "⏺️ " + t("dashboard.dropdownOps.online") },
    { value: "yesterday", title: t("dashboard.dropdownOps.yest") },
    { value: "week", title: t("dashboard.dropdownOps.week") },
    { value: "month", title: t("dashboard.dropdownOps.monthly") },
    { value: "year", title: t("dashboard.dropdownOps.annual") },
  ];
  // Variables for filtering between groups
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState([
    { value: 0, title: t("dashboard.dropdownOps.myRests") },
  ]);

  const history = useHistory();

  const [licenses, setLicenses] = useState([]);
  const [stayLicenses, setStayLicenses] = useState(true);

  const [globalSetting, setGlobalSetting] = useState("online");
  const [timedSetting, setTimedSetting] = useState("actual");
  const [detailedSetting, setDetailedSetting] = useState("total");

  const [restaurants, setRestaurants] = useState([]);
  const [restaurantTotalByRange, setRestaurantTotalByRange] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAlt, setLoadingAlt] = useState(true);
  const [total, setTotal] = useState(0);
  const [adminSessionData, setAdminSessionData] = useState({});
  //If logged in as admin
  const hasAdminSession = window.localStorage.getItem("adminSession")
    ? true
    : false;

  const pendingPayment = [];

  useEffect(() => {
    const adminUserSession = JSON.parse(localStorage.getItem("adminSession"));
    if (adminUserSession) {
      setAdminSessionData({
        email: adminUserSession.email,
        password: "123456",
        userEmail: adminUserSession.userEmail,
      });
    } else return;
  }, []);

  const handleRestoreAdminUserSession = () => {
    if (
      window.confirm(
        `Desea restaurar la sesión de ${adminSessionData.email} y terminar la sesión de: ${adminSessionData.userEmail} ?`
      )
    ) {
      signOut(adminSessionData.userEmail)
        .then(() => {
          signIn(adminSessionData)
            .then(() => {
              history.push(ADMIN_HOME_PATH);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else return;
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const licenses = await getLicenses();

        if (licenses.message === "Conversion completa") {
          history.go(0);
        } else if (licenses.message === "Usuario no tiene restaurantes") {
          setStayLicenses(false);
        } else {
          if (licenses.licenses.length > 0) {
            setLicenses(licenses.licenses);
            setLoading(false);
          } else {
            setStayLicenses(false);
          }
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  licenses.forEach((license) => {
    if (license.final_date === null) pendingPayment.push(license);
  });

  useEffect(() => {
    // Get all groups
    getAllGroups()
      .then((groups) => {
        setGroupOptions([
          { title: t("dashboard.dropdownOps.myRests"), value: 0 },
          ...groups.map((group) => {
            return { value: group.id, title: group.title };
          }),
        ]);
        setSelectedGroup(0);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [t]);

  useEffect(() => {
    setLoadingAlt(true);
    if (selectedGroup != null && !isNaN(selectedGroup)) {
      // Get restaurants once
      getRestaurants(selectedGroup)
        .then((rest) => {
          setRestaurants(rest);

          setLoadingAlt(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
          setLoadingAlt(false);
        });

      // Get restaurants every 5 minutes
      const interval = setInterval(
        () => {
          getRestaurants(selectedGroup)
            .then((rest) => {
              setRestaurants(rest);
              setLoadingAlt(false);
            })
            .catch((error) => {
              setError(error);
              setLoadingAlt(false);
            });
        },
        1000 * 60 * 5
      );
      return () => clearInterval(interval);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (restaurants.length > 0) {
      const newTotal = restaurants.reduce((currentTotal, restaurant) => {
        const val = restaurant[globalSetting][detailedSetting];
        const secondTotal = typeof val === "number" ? val : parseFloat(val);
        setLoading(false);
        return currentTotal + secondTotal;
      }, 0);
      setTotal(newTotal);
    }
  }, [restaurants, globalSetting, detailedSetting]);

  useEffect(() => {
    if (restaurants.length == 0 && !stayLicenses) {
      return history.push(INTRODUCTION_PATH);
    } else {
      // setTimeout(() => setLoading(false), 1000);
    }
  }, [licenses, restaurants]);

  useEffect(() => {
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7);

    var i = 0;
    var index = timedWeekOptions.length;

    timedWeekOptions.forEach((element) => {
      if (element.value == "semana_" + weekNumber) {
        index = i;
      }
      i++;
    });

    index = index + 2;

    var headerWeek = timedWeekOptions[0];
    var reconstructWeek = timedWeekOptions.slice(index, 53);
    reconstructWeek.reverse();
    reconstructWeek.push(headerWeek);
    reconstructWeek.reverse();

    timedWeekOptions = reconstructWeek;

    weekRanges = getWeekRanges(weekNumber - 1);

    //********************************************************************* */

    i = 0;
    index = timedMonthOptions.length;
    var monthNumber = currentDate.getMonth() + 1;
    timedMonthOptions.forEach((element) => {
      if (element.value == "month_" + monthNumber) {
        index = i;
      }
      i++;
    });

    index = index + 1;
    var headerMonth = timedMonthOptions[0];
    var reconstructMonth = timedMonthOptions.slice(index, 13);
    reconstructMonth.push(headerMonth);
    reconstructMonth.reverse();

    for (let i = 0; i < reconstructMonth.length; i++) {
      reconstructMonth[i].title = t(`dashboard.dropdownOps.${i}`);
    }

    timedMonthOptions = reconstructMonth;
    monthRanges = getMonthRanges(monthNumber - 1);
  }, []);

  const getWeekRanges = (e) => {
    var previousMonday = new Date();
    var allMondays = [];
    var allSundays = [];
    var buildRanges = [];
    for (let index = 0; index < e; index++) {
      do {
        previousMonday = DateApi.addDays(previousMonday, -1);
      } while (previousMonday.getDay() > 1);
      allMondays.push(previousMonday);
      allSundays.push(DateApi.addDays(previousMonday, -1));
      previousMonday = DateApi.addDays(previousMonday, -1);
    }
    allSundays = allSundays.slice(0, allSundays.length - 1);
    allMondays = allMondays.slice(1, allMondays.length);
    var index = allMondays.length - 1;
    var index2 = 0;
    allMondays.forEach((element) => {
      var obj = { index: index + 1, start: element, end: allSundays[index2] };
      buildRanges.push(obj);
      index--;
      index2++;
    });
    buildRanges.reverse();
    return buildRanges;
  };

  const getMonthRanges = (e) => {
    const buildRanges = [];

    for (let i = 0; i < e; i++) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - (i + 1));
      currentDate.setDate(1);

      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      const range = {
        index: e - i,
        start: currentDate,
        end: lastDayOfMonth,
      };

      buildRanges.push(range);
    }
    buildRanges.reverse();
    return buildRanges;
  };

  const getNumberFromCode = (e) => {
    var i = e.indexOf("_");
    var str = e.slice(i + 1);
    return str;
  };

  const reformatDate = (e) => {
    var reformatStart = new Date(e);
    var yyyy = reformatStart.getFullYear();
    var mm = reformatStart.getMonth() + 1; // Months start at 0!
    var dd = reformatStart.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    reformatStart = yyyy + "-" + mm + "-" + dd + " 06:00:00";
    return reformatStart;
  };

  const fechasPasadasAPIByRangeWeek = () => {
    setLoadingAlt(true);
    var promises = [];
    var restaurantsData = {};
    var licensesArr = licenses;
    var indexRemoval = [];
    var i = 0;
    licensesArr.forEach((element) => {
      if (!element.license_used) {
        indexRemoval.push(i);
      }
      i++;
    });
    indexRemoval.forEach((element) => {
      licensesArr.splice(element, 1);
    });

    licensesArr.forEach((element) => {
      var index = getNumberFromCode(timedSetting);
      var range = weekRanges[index - 1];
      //2022/04/24 06:00:00 AM
      var reformatStartStr = reformatDate(range.start);
      var reformatEndStr = reformatDate(DateApi.addDays(range.end, 1));
      var query = getfechasAtrasadas(
        element.restaurant_id,
        reformatStartStr,
        reformatEndStr
      )
        .then((licenses) => {
          restaurantsData[element.restaurant_id] = licenses;
          return restaurantsData;
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setLoading(true);
        });
      promises.push(query);
    });
    return Promise.all(promises).then((r) => {
      var data = r[0];
      var relativeTotal = 0;
      for (const e in data) {
        relativeTotal = relativeTotal + data[e][detailedSetting];
      }
      setTotal(relativeTotal);
      setRestaurantTotalByRange(r[0]);

      setLoadingAlt(false);
    });
  };

  const fechasPasadasAPIByRangeMonth = () => {
    setLoadingAlt(true);
    var promises = [];
    var restaurantsData = {};
    var licensesArr = licenses;
    var indexRemoval = [];
    var i = 0;
    licensesArr.forEach((element) => {
      if (!element.license_used) {
        indexRemoval.push(i);
      }
      i++;
    });
    indexRemoval.forEach((element) => {
      licensesArr.splice(element, 1);
    });
    licensesArr.forEach((element) => {
      var index = getNumberFromCode(timedSetting);
      var range = monthRanges[index - 1];
      //2022/04/24 06:00:00 AM
      var reformatStartStr = reformatDate(range.start);
      var reformatEndStr = reformatDate(DateApi.addDays(range.end, 1));
      var query = getfechasAtrasadas(
        element.restaurant_id,
        reformatStartStr,
        reformatEndStr
      )
        .then((licenses) => {
          restaurantsData[element.restaurant_id] = licenses;
          return restaurantsData;
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setLoading(true);
        });
      promises.push(query);
    });
    return Promise.all(promises).then((r) => {
      var data = r[0];
      var relativeTotal = 0;
      for (const e in data) {
        relativeTotal = relativeTotal + data[e][detailedSetting];
      }
      setTotal(relativeTotal);
      setRestaurantTotalByRange(r[0]);

      setLoadingAlt(false);
    });
  };

  useEffect(() => {
    if (
      globalSetting == "week" &&
      timedSetting !== "actual" &&
      timedSetting !== undefined
    ) {
      fechasPasadasAPIByRangeWeek();
    } else if (
      globalSetting == "month" &&
      timedSetting !== "actual" &&
      timedSetting !== undefined
    ) {
      fechasPasadasAPIByRangeMonth();
    } else if (timedSetting == "actual") {
      setRestaurantTotalByRange([]);
      setTimedSetting(undefined);
    }
  }, [timedSetting, detailedSetting]);

  useEffect(() => {
    if (globalSetting == "week" || globalSetting == "month") {
      setTimedSetting("actual");
    }
  }, [globalSetting]);

  const changeSelectedGroup = (e) => {
    setSelectedGroup(parseInt(e.currentTarget.value));
  };

  const changeTimedWeekSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );

    // If it exists, just change the global setting.
    if (currentDetailPossible) {
      setTimedSetting(e.currentTarget.value);
    } else {
      // If not, also change the detailed setting to the default one.
      setTimedSetting(e.currentTarget.value);
      setDetailedSetting("total");
    }
  };
  const changeTimedMonthSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );

    // If it exists, just change the global setting.
    if (currentDetailPossible) {
      setTimedSetting(e.currentTarget.value);
    } else {
      // If not, also change the detailed setting to the default one.
      setTimedSetting(e.currentTarget.value);
      setDetailedSetting("total");
    }
  };
  const changeGlobalSetting = (e) => {
    // Check if the current detail setting exists on the new options.
    const nextDetailedOptions =
      e.currentTarget.value === "online" ? liveOptions : historicOptions;
    const currentDetailPossible = nextDetailedOptions.some(
      (option) => option.value === detailedSetting
    );
    // If it exists, just change the global setting.
    if (currentDetailPossible) {
      setGlobalSetting(e.currentTarget.value);
    } else {
      // If not, also change the detailed setting to the default one.
      setGlobalSetting(e.currentTarget.value);
      setDetailedSetting("total");
    }
  };

  const changeDetailedSetting = (e) => {
    setDetailedSetting(e.currentTarget.value);
  };

  if (error) {
    return (
      <Layout title="Dashboard">
        <div>{error.message}</div>
      </Layout>
    );
  }

  if (loading) {
    return (
      <>
        <MailVerification></MailVerification>
        <LoadingIndicator fullScreen></LoadingIndicator>
      </>
    );
  } else {
    return (
      <Layout title="Dashboard">
        <div className="py-8 wrapper dashboard-resp">
          <div className="flex justify-between items-end">
            <div className="flex flex-wrap">
              {hasAdminSession && (
                <button
                  className="wfacturas-button mb-4"
                  onClick={() => handleRestoreAdminUserSession()}
                >
                  {" "}
                  <MdArrowBack />
                  Back to admin
                </button>
              )}
              <div className="w-full text-xs text-gray-500">
                <MailVerification></MailVerification>
                <Link to={NEW_GROUP_PATH} className="inline-flex items-center">
                  <MdAddCircle className="mr-1 fill-current w-4"></MdAddCircle>
                  {t("dashboard.newGroup")}
                </Link>
              </div>
              <div className="select-resp">
                <Select
                  selected={selectedGroup}
                  onChange={changeSelectedGroup}
                  options={groupOptions}
                ></Select>
                <button
                  className="ml-2 appearance-none bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-center new-rest-resp"
                  onClick={() => history.push(NEW_SUBSCRIPTION_PATH)}
                >
                  {t("dashboard.newRest")}
                </button>
              </div>
            </div>
            <div className="select-resp">
              {globalSetting === "month" ? (
                <>
                  <Select
                    onChange={changeTimedMonthSetting}
                    options={timedMonthOptions}
                  ></Select>
                  <div className="inline ml-2"></div>
                </>
              ) : (
                <></>
              )}

              {globalSetting === "week" ? (
                <>
                  <Select
                    onChange={changeTimedWeekSetting}
                    options={timedWeekOptions}
                  ></Select>
                  <div className="inline ml-2 resp-display-none"></div>
                </>
              ) : (
                <></>
              )}

              <Select
                onChange={changeGlobalSetting}
                options={globalOptions}
              ></Select>
              <div className="inline ml-2 resp-display-none"></div>
              <Select
                onChange={changeDetailedSetting}
                options={
                  globalSetting === "online" ? liveOptions : historicOptions
                }
              ></Select>
            </div>
          </div>
          <hr className="mt-2 mb-4"></hr>
          {loadingAlt ? (
            <LoadingIndicator></LoadingIndicator>
          ) : (
            <>
              {!restaurants || restaurants.length === 0 ? (
                <div className="border rounded py-8">
                  <h4 className="text-center text-xl font-bold text-gray-800 leading-tight">
                    {t("dashboard.noSub")}
                  </h4>
                  <p className="text-base text-gray-700 font-light mt-4 text-center">
                    {t("dashboard.registerRest")}{" "}
                    <Link
                      to={NEW_SUBSCRIPTION_PATH}
                      className="text-blue-700 font-medium"
                    >
                      {t("dashboard.here")}
                    </Link>
                  </p>
                </div>
              ) : (
                <>
                  {restaurants.map((restaurant) => (
                    <PreviewCard
                      key={`preview-card-${restaurant.id}`}
                      restaurant={restaurant}
                      globalSetting={globalSetting}
                      detailedSetting={detailedSetting}
                      restaurantTotalByRange={
                        restaurantTotalByRange[`${restaurant.id}`]
                      }
                      delayedTables={restaurant.delayedTables}
                      poleadorActive={restaurant.poleadorActive}
                      timedSetting={timedSetting}
                    ></PreviewCard>
                  ))}
                  <hr className="mt-4 mb-2"></hr>
                  <div className="flex justify-between pr-4">
                    <h3 className="text-lg text-gray-800 font-bold">Total</h3>
                    <span className="flex items-center">
                      <span className="text-brand-700 font-bold text-xl">
                        {formatter.format(total)}
                      </span>
                      <span className="text-xs ml-1 uppercase text-gray-700">
                        MXN
                      </span>
                    </span>
                  </div>
                </>
              )}
            </>
          )}

          {selectedGroup && selectedGroup !== 0 ? (
            <div className="w-full text-xs text-gray-500 mt-4">
              <Link
                to={`${GROUP_PATH}/${selectedGroup}`}
                className="inline-flex items-center"
              >
                <MdCreate className="mr-1 fill-current w-4"></MdCreate>
                {t("dashboard.editGroup")}
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        {licenses.filter((license) => license.final_date === null).length >
          0 && (
          <div className="py-8 wrapper">
            <div className="border py-8 px-5 rounded">
              <div className="mb-4">
                <h4 className="text-xl font-bold text-gray-800 leading-tight">
                  {t("dashboard.pendingPay")}
                </h4>
              </div>
              {pendingPayment.map((restaurant) => (
                <SubscriptionPaymentPending
                  {...restaurant}
                ></SubscriptionPaymentPending>
              ))}
            </div>
          </div>
        )}
        {licenses.filter((e) => !e.license_used).length > 0 && (
          <div className="py-8 wrapper">
            <div className="border py-8 px-5 rounded">
              <div className="mb-4">
                <h4 className="text-xl font-bold text-gray-800 leading-tight">
                  {t("dashboard.pendingInst")}
                </h4>
              </div>
              {licenses.map((license) => (
                <SubscriptionItemNoInstalled
                  {...license}
                ></SubscriptionItemNoInstalled>
              ))}
            </div>
          </div>
        )}
      </Layout>
    );
  }
};

export default DashboardPage;

import { useEffect, useRef, useState } from "react";
import { getAllRestaurants } from "../api/Groups";
import { getTimbresData } from "../api/Utils";
import { useTimbres } from "../context/TimbresContext";

export const useRestsDropdown = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restId, setRestId] = useState(null);
  const [userPlanData, setUserPlanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { updateTimbresCount } = useTimbres();
  const selectRef = useRef(null);

  const fetchRestaurants = async () => {
    try {
      const allRestaurants = await getAllRestaurants();
      setRestaurants(allRestaurants);
      setRestId(allRestaurants.length > 0 ? allRestaurants[0].id : null);
    } catch (error) {
      setRestaurants([]);
    } finally {
      setLoading(false);
    }
  };

  const updateTimbres = async (id) => {
    try {
      const timbresReponse = await getTimbresData(id);
      setUserPlanData(timbresReponse);
      const isPending = localStorage.getItem("pendingFacturapiSetup");

      updateTimbresCount(timbresReponse.timbres);

      //SUBSCRIPTION ACTIVATED BUT NEEDS TO COMPLETE FACTURAPI SETUP
      if (timbresReponse.pending && !isPending) {
        localStorage.setItem("pendingFacturapiSetup", "true");
      } else if (!timbresReponse.pending && isPending) {
        localStorage.removeItem("pendingFacturapiSetup");
      }
    } catch (error) {
      updateTimbresCount(0);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restId) updateTimbres(restId);
  }, [restId]);

  return {
    restaurants,
    restId,
    setRestId,
    loading,
    setLoading,
    selectRef,
    userPlanData,
  };
};

import React from "react";
import "./Tabs.scss";
import { useTranslation } from "react-i18next";

const Tabs = ({ setActiveTab, activeTab }) => {
  const { t } = useTranslation();
  const setTab = (e) => {
    setActiveTab(e.currentTarget.dataset.tab);
    if (window.history.replaceState) {
      window.history.replaceState(
        null,
        null,
        `?view=${e.currentTarget.dataset.tab}`
      );
    }
  };

  return (
    <div className="w-full flex text-gray-700 pt-2 tabs-resp">
      <div
        className={`tab hover:text-brand-700 ${
          activeTab === "general" ? "active" : ""
        }`}
        onClick={setTab}
        data-tab="general"
      >
        General
      </div>
      <div
        className={`tab ${activeTab === "tables" ? "active" : ""}`}
        onClick={setTab}
        data-tab="tables"
      >
        {t("restPage.acc")}
      </div>
      <div
        className={`tab ${activeTab === "top" ? "active" : ""}`}
        onClick={setTab}
        data-tab="top"
      >
        Top
      </div>
      <div
        className={`tab ${
          activeTab === "payments-and-movements" ? "active" : ""
        }`}
        onClick={setTab}
        data-tab="payments-and-movements"
      >
        {t("restPage.movementsTab")}
      </div>
      <div
        className={`tab ${
          activeTab === "discounts-and-cancellations" ? "active" : ""
        }`}
        onClick={setTab}
        data-tab="discounts-and-cancellations"
      >
        {" "}
        {t("restPage.cancelledProds")}{" "}
      </div>
      <div
        className={`tab ${activeTab === "goals" ? "active" : ""}`}
        onClick={setTab}
        data-tab="goals"
      >
        {t("restPage.goals")}
      </div>
    </div>
  );
};

export default Tabs;

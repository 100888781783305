import React from "react";
import { useTranslation } from "react-i18next";

import { BarLoader } from "react-spinners";

const LoadingIndicator = ({ fullScreen, white }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex justify-center items-center ${
        fullScreen ? "min-h-screen" : "p-16"
      }`}
    >
      <div>
        <div className="mx-auto">
          <BarLoader color={white ? "white" : "black"} width="100%"></BarLoader>
        </div>
        <p
          className={`text-lg text-center pt-4 ${white ? "text-white" : "text-gray-600"} `}
        >
          {t("loader")}
        </p>
      </div>
    </div>
  );
};

export default LoadingIndicator;

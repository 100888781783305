import React from "react";

const ErrorComponent = ({ error }) => {
  if (!error) return <></>;
  //aqui se imprimen los errores captados por la request al api
  let str = "";
  str = JSON.stringify(error.message);
  if (str.includes("422")) {
    return (
      <div className="error">
        La contraseña es demasiado corta (Mínimo 6 caracteres)
      </div>
    );
  }

  if (str.includes("444")) {
    return (
      <div className="error">Por favor utiliza un correo existente...</div>
    );
  }

  if (str.includes("402")) {
    return <div className="error">Contraseña o email incorrectos</div>;
  }

  if (str.includes("409")) {
    return <div className="error">Ese correo ya esta en uso</div>;
  }

  if (str.includes("408")) {
    return (
      <div className="error">
        Ya existe una sesión en el sitio web con este correo. <br></br> Por
        favor cierre la sesión o contacte con soporte para recibir ayuda
      </div>
    );
  }

  return <div className="error">{error.message}</div>;
};

export default ErrorComponent;

import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
  getDistributorData,
  updateAvatar,
  updateData,
} from "../../../api/Distributor";
import { FILES_PATH } from "../../../constants/api";
import ImageDropzone from "../../admin/distributor/ImageDropzone";

const DistributorForm = () => {
  // Variable for distributor logo
  const [currentImage, setCurrentImage] = useState(null);

  const [distributorData, setDistributorData] = useState({});

  //metodos del form, regsiter se usa para agarrar los valores del form con base en name y los manda a values
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: distributorData,
  });

  //gte de valores del api
  useEffect(() => {
    setCurrentImage(`${FILES_PATH}/${getDistributorData().avatar}`);
    setDistributorData(getDistributorData());
  }, []);

  //disables neutraliza el valor del name y lo pone como undefined
  //recibe valores del form
  const onSubmit = (values) => {
    updateData(values);

    if (typeof currentImage === "string" || !currentImage) return;
    updateAvatar(currentImage);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <div className="flex">
          <div className="input-wrapper mt-4 mr-8">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              Ícono
            </label>
            <ImageDropzone
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              name="icon"
            ></ImageDropzone>
          </div>
          <div className="flex-1">
            <div className="flex">
              <div className="input-wrapper mt-4 flex-1 mr-4">
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Nombre(s)
                </label>
                <input
                  type="text"
                  name="name"
                  ref={register}
                  className="input"
                  defaultValue={distributorData.name}
                ></input>
              </div>
              <div className="input-wrapper mt-4 flex-1">
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Apellido(s)
                </label>
                <input
                  type="text"
                  name="lastname"
                  ref={register}
                  className="input"
                  defaultValue={distributorData.lastName}
                ></input>
              </div>
            </div>
            <div className="input-wrapper mt-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Correo electrónico
              </label>
              <input
                type="email"
                disabled
                name="email"
                ref={register}
                className="input"
                defaultValue={distributorData.email}
              ></input>
            </div>

            <div className="flex">
              <div className="flex-1 input-wrapper mt-4 mr-4">
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  CLABE Interbancaria (18 Dígitos)
                </label>
                <input
                  type="text"
                  name="clabe"
                  ref={register}
                  className="input"
                  minLength="18"
                  maxLength="18"
                  pattern="[0-9]+"
                  defaultValue={distributorData.clabe}
                />
              </div>
            </div>
          </div>
        </div>
        <button className="button-main" type="submit">
          Guardar datos
        </button>
      </div>
    </form>
  );
};

export default DistributorForm;

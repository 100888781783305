import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import NotFoundPage from "./NotFoundPage";
import CreateNewFacturaForm from "./Components/CreateNewFacturaForm";
import { searchFolioLanding } from "../../api/Utils";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { HOME_PATH_FACTURACION } from "../../constants/paths";

const isInteger = (value) => {
  return /^\d+$/.test(value);
};

const FacturacionFormPage = ({ match }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { rest_id, folio_id, date } = match.params;
  const { key } = location.state;
  const [chequeData, setChequeData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [blockPmethod, setBlockPmethod] = useState(true);
  const [error, setError] = useState(null);

  const handleSuccess = (value) => {
    setSuccess(value);
  };

  useEffect(() => {
    searchFolioLanding({
      restId: rest_id,
      folio: folio_id,
      fecha: date,
    })
      .then((res) => {
        setBlockPmethod(!res.pmethodSelect);
        if (res.cheque[0].facturado === "1") {
          setError(true);
        }
        if (!res.cheque) {
          setError(true);
        } else {
          setChequeData(res.cheque);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  if (!isInteger(rest_id) || !isInteger(folio_id) || error) {
    return <NotFoundPage />;
  }

  return (
    <LayoutV2 title="Inicio">
      <div className="custom-factura-form-container">
        <div
          className={`card-search-container ${success ? "flex flex-col justify-center items-center" : ""}`}
        >
          <img
            className={`w-full ${success ? "md:w-1/2" : "md:w-1/3"} rounded mb-4`}
            src={key}
            alt="wmobil facturas"
          />
          {!success && <label className="step-indicator">Paso 2 de 2</label>}
          <h2 className={`search-text mb-4 ${success ? "text-center" : ""}`}>
            {success
              ? "Factura generada exitosamente"
              : "Ingresa tus datos fiscales"}
          </h2>
          {chequeData && !success ? (
            <CreateNewFacturaForm
              cheque={chequeData}
              restId={rest_id}
              sucess={success}
              setSuccess={handleSuccess}
              blockPmethod={blockPmethod}
            />
          ) : success ? (
            <div>
              <div className="wfacturas-table-text mb-16 text-2xl text-center font-normal">
                Tu factura se genero de manera exitosa y fue enviada a tu
                dirección de correo electrónico.
              </div>
              <button
                onClick={() =>
                  history.push(`${HOME_PATH_FACTURACION}/${rest_id}`)
                }
                className="wfacturas-button-landing w-full"
              >
                Ir a inicio
              </button>
            </div>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </LayoutV2>
  );
};

export default FacturacionFormPage;

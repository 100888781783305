import React from "react";
import Modal from "react-modal";
import { BarLoader } from "react-spinners";

const customStyles = {
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const WFacturasInvoiceTypeModal = ({
  partialPayment,
  onRequestClose,
  handleInvoiceType,
}) => {
  return (
    <Modal
      isOpen={partialPayment}
      onRequestClose={onRequestClose}
      style={customStyles}
      className="wfacturas-modal tab-rep-resp"
    >
      <div>
        <div className="flex items-center justify-between w-title-container">
          <h3>Tipo de factura</h3>
          <div className="cursor-pointer" onClick={onRequestClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 15L15 5M5 5L15 15"
                stroke="#3F2E04"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="w-buttons-container-partial">
          <button
            className="wfacturas-button-outline"
            onClick={() => handleInvoiceType("invoice")}
          >
            Factura
          </button>
          <button
            className="wfacturas-button-outline"
            onClick={() => handleInvoiceType("partial")}
          >
            Complemento de pago
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WFacturasInvoiceTypeModal;

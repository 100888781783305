import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RENEW_SUBSCRIPTION_PATH,
  MY_SUBSCRIPTIONS_PATH,
  RENEW_SUBSCRIPTION_PATH_NO_AUTH,
  PAYMENT_LINK_PATH,
} from "../../constants/paths";

const SubscriptionItemShareable = ({
  restaurant_title,
  final_date,
  activated,
  restaurant_id,
  user_id,
  conekta_id,
  user_email,
}) => {
  const dueDate = final_date
    ? Math.ceil((new Date(final_date) - new Date()) / (1000 * 60 * 60 * 24))
    : 0;
  return (
    <Link
      to={{
        pathname: `${PAYMENT_LINK_PATH}=${user_id}/rid=${restaurant_id}`,
        state: {
          data: {
            conektaid: conekta_id,
            restaurant: restaurant_title,
            user_email: user_email,
          },
        },
      }}
      className={`flex w-full p-4 bg-white shadow-md items-center justify-between rounded border mb-4`}
    >
      <div className="flex items-center">
        <div
          className={`w-2 h-2 rounded-full mr-2 ${
            activated === 1 ? "bg-green-300" : "bg-red-300"
          }`}
        ></div>
        <div className="flex items-center flex-resp">
          <p className="title banner-w">{restaurant_title}</p>
          {final_date ? (
            <div>
              <div>
                vence en: {dueDate === 1 ? `${dueDate} día` : `${dueDate} días`}
              </div>
              <div>({new Date(final_date).toLocaleDateString("es-ES")})</div>
            </div>
          ) : (
            <div>vencido</div>
          )}
        </div>
      </div>
      {final_date ? <p>Activo</p> : <p>Pagar</p>}
    </Link>
  );
};

export default SubscriptionItemShareable;
